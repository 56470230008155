import * as actionTypes from '../actions/types.js';

export default (state = { validationError: {} }, action) => {

    switch (action.type) {

        case actionTypes.ERROR_MSG:
            return {
                ...state,
                validationError: action.payload
            };

        case actionTypes.ERROR_MSG_CLEAR:
            return {
                ...state,
                validationError: { ...action.payload }
            };

        default:
            return state;
    }
};
