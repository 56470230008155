import * as actionTypes from './types';
import fetchData from '../services';

export const changeModal = (key) => {
    return {
        type: actionTypes.TOGGLE_NOTIFICATION_MODAL, payload: key ? key : ""
    }
}

export const getNotifications = (id, limit) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.CHANGE_NOTIFICATION_LIMIT, payload: limit })
        const res = await fetchData({ url: '/user/get/notification', method: 'POST', data: { userId: id, limit } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.NOTIFICATION_LIST, payload: res.response, unread: res.unread, total: res.total })
        }
        else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" } });
        }
    }
}

export const getOneNotification = (notificationId) => {
    return async (dispatch) => {
        let userId = sessionStorage.getItem('userId');
        const res = await fetchData({ url: '/user/get-one/notification', method: 'POST', data: { notificationId, userId } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.GET_ONE_NOTIFICATION, payload: res.response, unread: res.unread })
        }
        else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" } })
        }

    }
}

export const closeNotification = () => {
    return {
        type: actionTypes.OPEN_NOTIFICATION_MODAL
    }
}

export const getCount = (userId) => {
    return async (dispatch) => {
        const res = await fetchData({ url: `/user/get/notification/count?userId=${userId}`, method: 'GET' })
        if (res.status === 1) {
            dispatch({ type: actionTypes.GET_ONE_NOTIFICATION, unread: res.response })
        }
    }
}
