import {
    EMPLOYEE_POLICY_CLEAR_DATA, EMPLOYEE_POLICY_DOCUMENT_PREVIEW, EMPLOYEE_POLICY_PREVIEW_DATA, POLICY_DOCUMENT_ADD_DATA, POLICY_DOCUMENT_EDIT_DATA,
    POLICY_DOCUMENT_LIST, SEARCH_EMPLOYEE_POLICY, SELETED_POLICY_DOCUMENTS, SHOW_GROUP_POLICY_ASSIGN_MODAL, TABLE_DATA_FOR_POLICY
} from "../actions/types";
import * as actionTypes from '../actions/types.js';

export default (state = {
    skip: 0, itemPerPage: 25, activePage: 1, searchValue: '', count: 0, policyDocumentList: [], addData: {}, editData: {},
    viewPolicydetails: {}, showPolicyPreview: false, policyCotent: '', userGroupId: '', showAssignModal: false, selectedPolicies: [],
    assignData: {}, showUnreadPolicyNotify: false, readStatus: 0, assignType: ''
}, action) => {
    switch (action.type) {
        case POLICY_DOCUMENT_LIST:
            return {
                ...state,
                policyDocumentList: action.payload,
                count: action.total
            }
        case POLICY_DOCUMENT_EDIT_DATA:
            return {
                ...state,
                editData: { ...state.editData, ...action.payload },
            }
        case POLICY_DOCUMENT_ADD_DATA:
            return {
                ...state,
                addData: { ...state.addData, ...action.payload },
            }
        case SEARCH_EMPLOYEE_POLICY:
            return {
                ...state,
                searchValue: action.payload ? action.payload : ''
            }

        case TABLE_DATA_FOR_POLICY:
            let serachVal = action.payload && action.payload.search ? action.payload.search : '';
            let skip = action.payload && action.payload.skip ? action.payload.skip : 0;
            let limit = action.payload && action.payload.limit ? action.payload.limit : state.itemPerPage;
            let activePage = action.payload && action.payload.activePage ? action.payload.activePage : 1;
            return {
                ...state,
                searchValue: serachVal,
                skip: skip,
                itemPerPage: limit,
                activePage: activePage
            }
        case EMPLOYEE_POLICY_CLEAR_DATA:
            return {
                ...state,
                addData: {},
                editData: {}
            }
        case EMPLOYEE_POLICY_DOCUMENT_PREVIEW:
            return {
                ...state,
                viewPolicydetails: action.payload ? action.payload : '',
                showPolicyPreview: !state.showPolicyPreview
            }
        case EMPLOYEE_POLICY_PREVIEW_DATA:
            return {
                ...state,
                policyCotent: action.payload,
                readStatus: action.readStatus ? action.readStatus : 0
            }
        case SHOW_GROUP_POLICY_ASSIGN_MODAL:
            return {
                ...state,
                showAssignModal: !state.showAssignModal,
                userGroupId: action.payload && action.payload.id && action.payload.id !== undefined ? action.payload.id : '',
                assignType: action.payload && action.payload.userType && action.payload.userType !== undefined ? action.payload.userType : '',
            }
        // case SELETED_POLICY_DOCUMENTS:
        //     return {
        //         ...state,
        //         selectedPolicies: action.payload,
        //     }
        case SELETED_POLICY_DOCUMENTS:
            return {
                ...state,
                assignData: action.payload && Object.keys(action.payload).length > 0 ? { ...state.assignData, ...action.payload } : {},
            }
        case actionTypes.POLICY_UNREAD_NOTIFY_MODAL:
            return {
                ...state,
                showUnreadPolicyNotify: action.payload ? action.payload : false,
            }
        case actionTypes.UPDATED_POLICY_DOCUMENT_LIST:
            return {
                ...state,
                policyDocumentList: state.policyDocumentList.filter(e => e._id !== action.id),
            }
        default:
            return state
    }

}