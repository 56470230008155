import * as actionTypes from '../actions/types.js';
export default (state = {categorydata:{},categoryvideodata:{}}, action) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORY_KNOWLEDGE:
            return {
                ...state,
                categorydata: action.payload,
            }
        case actionTypes.GET_CATEGORY_KNOWLEDGE_VIDEOLIST:
            return {
                ...state,
                categoryvideodata: action.payload,
            }
        default:
            return state;
    }
}
