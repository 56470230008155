import * as actionTypes from './types';
import fetchData, { RootdomainURL,client } from '../services';



export const popupoff = () =>{
    return(dispatch) =>{
        dispatch({
            type: actionTypes.SIGN_IN_MODAL,
        });
    }
    }
export const OtpModal = () =>{
    return(dispatch) =>{
        dispatch({
            type: actionTypes.OTP_MODAL,
        });
    }
    }
export const popup = (message) =>{
    return(dispatch) =>{
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: message, alignment: "centre" }
        });
    }
}
export const saveSignUp = (myProfileData, props) => {
    let formData = {
        firstname: myProfileData.firstname,
        lastname: myProfileData.lastname,
        email: myProfileData.email,
        password: myProfileData.password,
        companyName: myProfileData.companyName,
        personal_phone: myProfileData.personal_phone,
        subdomain: myProfileData.subdomain,
        // accept_terms: String(myProfileData.accept_terms),
        cart_data: props.offline_cart && Array.isArray(props.offline_cart) && props.offline_cart.length > 0 ? props.offline_cart : []
    }
    return  (dispatch) => {
        let response = fetchData({ url: '/user/register', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                sessionStorage.setItem('isAuthenticated', true);
                sessionStorage.setItem('token', res.response.authToken);
                sessionStorage.setItem('userName', res.response.userName);
                sessionStorage.setItem('userId', res.response.userId);
                sessionStorage.setItem('userRole', res.response.role);
                const selected_addon = (localStorage.getItem('selected_addon') || '');
                client.defaults.headers.common['authorization'] = res.response.authToken
                const offCart = JSON.parse(localStorage.getItem('offline_cart_subscription_details'));
                const offdocumentCart = JSON.parse(localStorage.getItem('offline_cart_document_purchase'));
                const employeePlan = (localStorage.getItem('offline_cart_employeePlan') || '');
                let offCartDoc = 0;
                if (offCart && offCart.length > 0) {
                    for (let i = 0; i < offCart.length; i++) {
                        let response = fetchData({
                            url: '/user/add-to-cart',
                            method: 'POST',
                            data: {
                                'packageId': offCart[i].id,
                                'userId': res.response.userId,
                                'sendtype': offCart[i].sendtype,
                                'packages': offCart[i].selectedPackages,
                                'renewalPlan': JSON.stringify(offCart[i].renewalPlan),
                                'instantBuyUpdate': 'true',
                                'licencetype': JSON.stringify(offCart[i].licenceType)
                            }
                        });
                    }
                }
                if (offdocumentCart && offdocumentCart.fileId){
                    offCartDoc = 1;
  
                       let data = fetchData({
                            url: '/company/singledocument/addtocart',
                            method: 'POST',
                            data: {
                                fileId: offdocumentCart.fileId,
                                userId: res.response.userId,
                                fileData: offdocumentCart.fileData,
                                price: offdocumentCart.price,
                            },
                        })
                        data.then(res => {
                            if(res && res.status === 1){
                                localStorage.removeItem('offline_cart_document_purchase');
                                sessionStorage.setItem('offline_cart_document_purchase',JSON.stringify(offdocumentCart));
                            }else{
                                dispatch({
                                    type: actionTypes.BOTTOM_T_W_AL,
                                    payload: { show_hide: true, text: 'Document not added in cart', alignment: "centre" }
                                });
                            }
                           
                        })
                    }
                                                localStorage.removeItem('offline_cart_subscription_details');
                dispatch({ type: actionTypes.OFF_LINE_CART_DATA, payload: [] });
                let value = window.btoa(encodeURIComponent(JSON.stringify(res.response)));
                let user_url = '';
                if (res.response && res.response.subdomain && res.response.subdomain && res.response.subdomain !== '' && res.response.subdomain !== undefined) {
                    let URL = '';
                    if (RootdomainURL.includes('localhost')) {
                        URL = `http://${res.response.subdomain}.localhost:3001/company?user=` + value;
                        user_url = `http://${res.response.subdomain}.localhost:3001/company?user=` + value;
                    } else {
                        URL = `https://${res.response.subdomain}.${RootdomainURL}/company?user=` + value;
                        user_url = `https://${res.response.subdomain}.${RootdomainURL}/company?user=` + value;
                    }
                    window.location.href = URL;
                } else {
                    let URL = '';
                    if (RootdomainURL.includes('localhost')) {
                        URL = `http://localhost:3001/company?user=` + value;
                        user_url = `http://localhost:3001/company?user=` + value;
                    } else {
                        URL = `https://${RootdomainURL}/company?user=` + value;
                        user_url = `https://${RootdomainURL}/company?user=` + value;
                    }
                    window.location.href = URL;
                }
                window.location.href = user_url + "&employeePlan=" + window.btoa(encodeURIComponent(employeePlan))+ "&selected_addon=" + window.btoa(encodeURIComponent(selected_addon)+ "&document="+offCartDoc)
                dispatch({
                    type: 'AUTHENTICATION',
                    payload: true
                });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Registered Successfully.', alignment: "centre" }
                });
                dispatch({
                    type: actionTypes.AUTH_MODAL,
                });
                setTimeout(() => {
                    if (props.offline_cart && props.offline_cart.length > 0 && Array.isArray(props.offline_cart)) {
                        props.history.push('/checkout');
                    } else {
                        props.history.push('/');
                    }
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: false, text: '', alignment: "centre" }
                    });
                }, 1500);
            } else if (res.status === 0) {
                // dispatch({
                //     type: actionTypes.BOTTOM_T_W_AL,
                //     payload: { show_hide: true, text: res.response, alignment: "centre" }
                // });
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: res.response }
                });
            } else {
                // dispatch({
                //     type: actionTypes.BOTTOM_T_W_AL,
                //     payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                // });
                dispatch({
                    type: actionTypes.SHOW_ERROR_MESSAGE,
                    payload: { show: true, message: "Something went wrong" }
                });
            }
        });
    }
}

export const viewProfile = () => {
    let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
    return (dispatch) => {
        let response = fetchData({ url: '/users/getuserbyid', method: 'POST', data: { userId } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.ADD_SIGNUP,
                    payload: res.response
                });
                if (res.response && res.response.companyName && String(res.response.companyName).length > 0 && String(res.response.companyName) !== String(undefined) && String(res.response.companyName) !== String(null)) {
                    dispatch({ type: actionTypes.DISABLED_COMP_NAME, payload: true });
                }
                if (res.response && res.response.companyRegNo && String(res.response.companyRegNo).length > 0 && String(res.response.companyRegNo) !== String(undefined) && String(res.response.companyRegNo) !== String(null)) {
                    dispatch({ type: actionTypes.DISABLED_COMP_REGNO, payload: true });
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const uploadLogo = (data, key, type) => {
    var formData = new FormData();
    if (key === "signature") {
        formData.append("signature", data);
    } if (key === "companyLogo") {
        formData.append("companyLogo", data);
    }
    return (dispatch) => {
        let response = fetchData({ url: '/admin/users/image/upload', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                if (type === "Edit" || type === "Add") {
                    dispatch({
                        type: (type === "Edit") ? actionTypes.ADD_MYPROFILE : actionTypes.ADD_SIGNUP,
                        payload: res.message,
                    });
                }
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Successfully Uploaded.', alignment: "centre" }
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Not Uploaded.', alignment: "centre" }
                });
            }
        });
    }
}

export const addsignUpData = (mySignUpData) => {
    return {
        type: actionTypes.ADD_SIGNUP,
        payload: mySignUpData
    }
};

export const clearsignUpData = () => {
    return {
        type: actionTypes.CLEAR_SIGNUP_DATA,
        payload: {},

    }
};

export const signatureModal = () => {
    return {
        type: actionTypes.OPEN_SIGNATURE_MODAL
    }
};

export const showSignIn = () => {
    return {
        type: actionTypes.SHOW_SIGNIN,
        payload: true
    }
}
