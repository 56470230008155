import * as actionTypes from './types';
import fetchData from '../services';

export const getFeaturedDocuments = (type, skip, limit) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/get-document-lists', method: 'POST', data: { 'type': type, 'skip': skip, 'limit': limit } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.FEATURE_DOCUMENTS, payload: res.response });
            } else {
                dispatch({ type: actionTypes.FEATURE_DOCUMENTS, payload: [] });
            }
        });
    }
}

export const getDocumentsLists = (type, skip, limit, pageNumber, searchText) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.PACKAGE_LISTS_PAGINATION, 'page_no': pageNumber });
        dispatch({ type: actionTypes.PACKAGE_LISTS_SEARCHTEXT, 'searchText': searchText });
        let response = fetchData({ url: '/user/get-document-lists', method: 'POST', data: { 'type': type, 'skip': skip, 'limit': limit, 'value': searchText } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.SEARCH_PACKAGE_LISTS, payload: res.response, count: res.total });
            } else {
                dispatch({ type: actionTypes.SEARCH_PACKAGE_LISTS, payload: [], count: 0 });
            }
        });
    }
}

export const setSearchValue = (searchText) => {
    return { type: actionTypes.PACKAGE_LISTS_SEARCHTEXT, 'searchText': searchText }
}

export const getTestimonialLists = (skip, limit) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/get-testimonial-lists', method: 'POST', data: { 'skip': skip, 'limit': limit } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.TESTIMONIAL_LISTS, payload: res.response });
            } else {
                dispatch({ type: actionTypes.TESTIMONIAL_LISTS, payload: [] });
            }
        });
    }
}

export const getUpdatedDocuments = (skip, limit, pageNumber, value) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.UPDATED_DOCUMENTS_PAGINATION, 'page_no': pageNumber });
        dispatch({ type: actionTypes.UPDATED_DOCUMENTS_SEARCHTEXT, 'searchText': value });
        let response = fetchData({ url: '/user/get-updated-documents', method: 'POST', data: { 'skip': skip, 'limit': limit, 'value': value } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.NEW_UPDATED_DOCUMENTS, payload: res.response, count: res.total });
            } else {
                dispatch({ type: actionTypes.NEW_UPDATED_DOCUMENTS, payload: [], count: 0 });
            }
        });
    }
}

export const setDocValue = (searchText) => {
    return { type: actionTypes.UPDATED_DOCUMENTS_SEARCHTEXT, 'searchText': searchText }
}

export const getUserBlogs = () => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/user-blogs', method: 'POST', data: {} });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.USER_BLOG_LISTS, payload: res.response });
            } else {
                dispatch({ type: actionTypes.USER_BLOG_LISTS, payload: [] });
            }
        });
    }
}

export const getLatestUpdates = (skip, limit) => {
    return (dispatch) => {
        // dispatch({type: actionTypes.UPDATED_DOCUMENTS_PAGINATION, 'page_no': pageNumber});
        // dispatch({type: actionTypes.UPDATED_DOCUMENTS_SEARCHTEXT, 'searchText': value});
        let response = fetchData({ url: '/user/document/latest-update', method: 'POST', data: { 'skip': skip, 'limit': limit } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.LATEST_UPDATED_DOCUMENTS, payload: res.response });
            } else {
                dispatch({ type: actionTypes.LATEST_UPDATED_DOCUMENTS, payload: [] });
            }
        });
    }
}

export const featuresToggle = (tab) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FEATURE_ACTIVE_TAB,
            payload: tab
        });
    }
}
export const reqularityToggle = (tab) => {
    return {
        type: actionTypes.REQULARITY_ACTIVE_TAB,
        payload: tab
    }
}

export const getSampleDocument = () => {
    return dispatch => {
        let response = fetchData({ url: '/sample/filesList', method: 'GET' });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SAMPLE_FILES_LIST,
                    payload: res.response
                })
            } else {
                dispatch({
                    type: actionTypes.SAMPLE_FILES_LIST,
                    payload: []
                });
            }
        }).catch(error => console.error(error));
    }
}
