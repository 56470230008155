import * as actionTypes from '../actions/types.js';
export default (state = {
    fileDetails: {}, cartCount: 0, userDetails: {}, pdfDetails: {}, signature: "",
    sideBarExpanded: true, dropdownOpen: false, deleteOption: {}, restoreOption: {},
    signatureTab: '1', pencilColor: 'black', textColor: 'black', textFontFamily: '',
    previewImage: '', companyName: '', showbottomModal: false, pop_show_hide: false, text: '', showSignatureModal: false,
    alignment: 'centre', sampleData: {}, showError: false, errorMessage: "", documentManagement: {}, showUpdateModal: false,
    monthlymanagementCheck: 0, yearlymanagementCheck: 0, monthlyupdatesCheck: 0, yearlyupdatesCheck: 0, monthlycomboCheck: 0, yearlycomboCheck: 0, monthlyproCheck: 0, yearlyproCheck: 0,
    companyDetails: {}, showDocumentModal: false, showDocument: '', imageData: '', cropData: '', cropper: '', updatedocTab: '1',
    skip: 0, activePage: 1, itemPerPage: 20, searchValue: "", showDeleteModal: false, clicked_ID: '', btmModalShow: false, offerShowModal: false,
    offerSlugs: [], policydata: '',purchasemodel:false, mobileModal: false, notifyModal: false, pRestoreOption:{}, ShowLockedDocModal: false, lockedDocDetails:{} 
}, action) => {
    switch (action.type) {

        case actionTypes.TOGGLE_VIEW_STATE:
            return {
                ...state,
                toggleViewState: !state.toggleViewState
            };
        case actionTypes.PURCHASE_MODAL:
            return {
                ...state,
                purchasemodel: !state.purchasemodel
            };

        case actionTypes.MODAL_VIEW:
            return {
                ...state,
                showModal: !state.showModal
            };

        case actionTypes.PREVIEW_MODAL_VIEW:
            return {
                ...state,
                showPreviewModal: !state.showPreviewModal
            };

        case actionTypes.LOGOUT_MODAL_VIEW:
            return {
                ...state,
                showLogOutModal: !state.showLogOutModal
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                logOutData: !state.logOutData
            };

        case actionTypes.RENAME_MODAL_VIEW:
            return {
                ...state,
                showRenameModel: !state.showRenameModel
            };

        case actionTypes.FILE_EDIT_MODAL_VIEW:
            return {
                ...state,
                showFileEditModel: !state.showFileEditModel
            };

        case actionTypes.LIST_MODAL_VIEW:
            return {
                ...state,
                showListModal: !state.showListModal,
                templateList: action.payload
            };

        case actionTypes.SELECT_TEMPLATE:
            return {
                ...state,
                selectedTempateId: action.payload
            };

        case actionTypes.FILE_NAME_ADD:
            return {
                ...state,
                fileDetails: { ...state.fileDetails, ...action.payload }
            };
        case actionTypes.CLEAR_FILE_DOCS_ADD:
            return {
                ...state,
                fileDetails: { ...action.payload }
            };
        case actionTypes.SIGNATURE_MODAL_VIEW:
            return {
                ...state,
                showSignatureModal: !state.showSignatureModal,
                signature: action.payload,
            };
        case actionTypes.CART_COUNT:
            return {
                ...state,
                cartCount: action.payload
            }
        case actionTypes.VIEW_USER_DETAILS:
            return {
                ...state,
                userDetails: { ...action.payload },
            }
        case actionTypes.PDF_MODAL_VIEW:
            return {
                ...state,
                showPDFModal: !state.showPDFModal
            };
        case actionTypes.PDF_FORM_DATA:
            return {
                ...state,
                pdfDetails: { ...state.pdfDetails, ...action.payload }
            };
        case actionTypes.CLEAR_PDF_FORM_DATA:
            return {
                ...state,
                pdfDetails: { ...action.payload }
            };
        case actionTypes.PDF_EDITMODAL_VIEW:
            return {
                ...state,
                showPDFEditModal: !state.showPDFEditModal
            }
        case actionTypes.SET_SIDEBAR_EXPAND:
            return {
                ...state,
                sideBarExpanded: action.payload
            }
        case actionTypes.DELETE_MODAL_VIEW:
            return {
                ...state,
                deleteOption: action.payload,
                showDeleteModal: !state.showDeleteModal
            };
        case actionTypes.SET_HEADER_DROPDOWN:
            return {
                ...state,
                // dropdownOpen: !state.dropdownOpen
                dropdownOpen: action.payload,
            }
        case actionTypes.RESTORE_MODAL_VIEW:
            return {
                ...state,
                restoreOption: action.payload,
                showRestoreModal: !state.showRestoreModal
            }
        case actionTypes.COM_SET_SIGNATURE_ACTIVETAB:
            return {
                ...state,
                signatureTab: action.payload
            }
        case actionTypes.COM_SET_SIGNATURE_PENCIL_COLOR:
            return {
                ...state,
                pencilColor: action.payload
            }
        case actionTypes.COM_SET_SIGNATURE_TEXT_COLOR:
            return {
                ...state,
                textColor: action.payload
            }
        case actionTypes.COM_SET_SIGNATURE_FONT_FAMILY:
            return {
                ...state,
                textFontFamily: action.payload
            }
        case actionTypes.COM_SET_SIGNATURE_PREVIEW_IMAGE:
            return {
                ...state,
                previewImage: action.payload
            }
        case actionTypes.COM_SET_SIGNATURE_TEXT_VALUE:
            return {
                ...state,
                companyName: action.payload
            }
        case actionTypes.NEW_BOTTOM_MODAL:
            return {
                ...state,
                showbottomModal: action.payload
            }
        case actionTypes.BOTTOM_T_W_AL:
            return {
                ...state,
                pop_show_hide: action.payload.show_hide,
                text: action.payload.text,
                alignment: action.payload.alignment,
                // userDetails: action.payload,
            }
        case actionTypes.SHOW_ERROR_MESSAGE:
            return {
                ...state,
                showError: action.payload.show,
                errorMessage: action.payload.message,
            }
        case actionTypes.DOCUMENT_MANAGEMENT_STATUS:
            return {
                ...state,
                documentManagement: action.payload
            }
        case actionTypes.SHOW_UPDATE_RENEWAL_MODAL:
            return {
                ...state,
                showUpdateModal: !state.showUpdateModal
            }
        case actionTypes.PACKAGE_SELECT_CHECKBOX:
            let packages = action.payload, plantype = action.plan;
            return {
                ...state,
            }

        case actionTypes.COMPANY_DETAILS_FOR_FREE_POLICY:
            return {
                ...state,
                companyDetails: { ...state.companyDetails, ...action.payload }
            }
        case actionTypes.SAMPLE_DOCUMENT_MODAL:
            return {
                ...state,
                showDocumentModal: !state.showDocumentModal,
                showDocument: action.payload
            }
        case actionTypes.COMPANY_LOGO_UPLOAD:
            return {
                ...state,
                imageData: action.payload,
            }
        case actionTypes.COMPANY_LOGO_CROP:
            return {
                ...state,
                cropper: action.payload,
            }
        case actionTypes.COMPANY_LOGO_CROPDATA:
            return {
                ...state,
                cropData: action.payload,
            }
        case actionTypes.CHNAGE_UPDATE_DOCUMENT_TAB:
            return {
                ...state,
                updatedocTab: action.payload ? action.payload : '1',
            }
        case actionTypes.SET_TABLE_ATTRIBUTES:
            return {
                ...state,
                activePage: action.page_no,
                searchValue: action.serachVal,
                skip: action.skip,
                itemPerPage: action.itemPerPage && action.itemPerPage !== undefined ? Number(action.itemPerPage) : state.itemPerPage
            }
        case actionTypes.GET_CLICKED_ID:
            return {
                ...state,
                clicked_ID: action.payload ? action.payload : ''
            }
        case actionTypes.MANUAL_BOTTOM_MODAL:
            return {
                ...state,
                btmModalShow: action.payload.show_hide,
                text: action.payload.text,
                alignment: action.payload.alignment,
                url: action.payload.path
                // userDetails: action.payload,
            }
        case actionTypes.OFFER_MODAL:
            return {
                ...state,
                offerShowModal: action.payload
            }
        case actionTypes.OFFER_SLUG:
            return {
                ...state,
                offerSlugs: action.payload
            }
        case actionTypes.PUBLIC_POLICY_CONTENT:
            return {
                ...state,
                policydata: action.payload
            }
        case actionTypes.MOBILE_VIEW_MODAL:
            return {
                ...state,
                mobileModal: !state.mobileModal
            }
        case actionTypes.SUBSCRIPTION_EXPIRY_NOTIFY_MODAL:
            return {
                ...state,
                notifyModal: action.payload
            }
        case actionTypes.RESTORE_FILE_MODAL_VIEW:
            return {
                ...state,
                restoreFileOption: action.payload,
                showRestoreFileModal: !state.showRestoreFileModal
            }
        case actionTypes.PREVIEW_VERSION_MODAL_VIEW:
            return {
                ...state,
                showPreviousPreviewModal: !state.showPreviousPreviewModal
            }
        case actionTypes.PDELETE_MODAL_VIEW:
            return {
                ...state,
                deletePOption: action.payload,
                showPDeleteModal: !state.showPDeleteModal
            }
        case actionTypes.PRESTORE_MODAL_VIEW:
            return {
                ...state,
                pRestoreOption: action.payload,
                pShowRestoreModal: !state.pShowRestoreModal
            }
        case actionTypes.LOCK_DOC_USER_MODAL_VIEW:
            return {
                ...state,
                ShowLockedDocModal: !state.ShowLockedDocModal,
                lockedDocDetails: action.payload,
            }
        default:
            return state;
    }
};
