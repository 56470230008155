import * as actionTypes from './types';
import fetchData from '../services';

export const dashboard = () => {
    return (dispatch) => {
        let userId = sessionStorage.getItem('userId');
        let response = fetchData({ url: '/userdashboard', method: 'POST', data: { userId } });
        //dispatch({ type: actionTypes.BUTTON_PENDING });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            //dispatch({ type: actionTypes.BUTTON_SUCCESS });
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: 'GET_DASHBOARD',
                    payload: res && res.response ? res.response : null,
                    // payload: res && res.response ? res.response[0] : null,
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const userRecentUpdates = (skip, limit, pageNumber) => {
    return (dispatch) => {
        let userId = sessionStorage.getItem('userId');
        let response = fetchData({ url: '/user/recent-updates', method: 'POST', data: { 'limit': limit, 'skip': skip, userId } });
        dispatch({ type: 'RECENTUPDATES_TABLE_ATTRIBUTES', page_no: pageNumber });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: 'GET_RECENT_UPDATES', payload: res.response, count: res.total });
            } else {
                dispatch({ type: 'GET_RECENT_UPDATES', payload: [], count: 0 });
            }
        });
    }
}

export const userRecentFolders = () => {
    return (dispatch) => {
        let userId = sessionStorage.getItem('userId');
        let response = fetchData({ url: '/user/recent-folders', method: 'POST', data: { userId } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: 'GET_RECENT_FOLDERS',
                    payload: res.response,
                });
            } else {
                dispatch({
                    type: 'GET_RECENT_FOLDERS',
                    payload: [],
                });
            }
        });
    }
}

export const get_comp_details_keys = () => {
    return (dispatch) => {
        let response = fetchData({ url: '/users/get/compdetails', method: 'POST', data: {} });
        response.then(res => {
            if (res && +res.status === 1) {
                dispatch({
                    type: actionTypes.TOP_POP_ACTIVE_SUBS,
                    payload: res.response && res.response.activesubs && String(res.response.activesubs) === String(true) ? 'true' : String(res.response.activesubs) === String(false) ? 'false' : '',
                    docUpdateStatus: res.response && res.response.docUpdateStatus ? res.response.docUpdateStatus : '',
                    docManagementStatus: res.response && res.response.docManagementStatus ? res.response.docManagementStatus : '',
                    employeeSubscription: res.response && res.response.employeeSubscription ? res.response.employeeSubscription : '',
                    status: res.response.subscription_status,
                    licenseStatus: res.response.userLicenseStatus
                });
                dispatch({
                    type: actionTypes.TOP_POP_COMP_DETAILS,
                    payload: res.response && res.response.Comp_details ? res.response.Comp_details : 0
                });
            }
        })
    }
}

export const getLatestviewdocuments = (id) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        let res = await fetchData({ url: `/user/recent/documents?userId=${id}`, method: 'GET' });
        if (res && res.status === 1) {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.LATES_VIEWED_DOCUMENTS, payload: { documentData: res.response.documentData }
            })
        } else {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.LATES_VIEWED_DOCUMENTS, payload: { myfilesdata: [] }
            })
        }
    }
}
export const getLatestviewMyfiles = (id) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        let res = await fetchData({ url: `/user/recent/myfiles?userId=${id}`, method: 'GET' });
        if (res && res.status === 1) {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.LATES_VIEWED_MYFILES, payload: { myfilesdata: res.response.myfilesdata }
            })
        } else {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.LATES_VIEWED_MYFILES, payload: { myfilesdata: [] }
            })
        }
    }
};

export const getNeedreviewDocs = (skip = 0, limit, page = 1) => {
    return (dispatch) => {
        let userId = sessionStorage.getItem('userId');
        let response = fetchData({ url: `/user/needreview/documents?userId=${userId}&skip=${skip}&limit=${limit}`, method: 'GET' });
        dispatch({ type: 'ACTIVE_PAGE', page_no: page });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: 'NEED_REVIEW_DOCUMENT_LIST',
                    payload: res.response,
                    total: res.total
                });
            } else {
                dispatch({
                    type: 'NEED_REVIEW_DOCUMENT_LIST',
                    payload: [],
                    total: 0
                });
            }
        });
    }
}

export const getUptodateDocs = (skip = 0, limit) => {
    return (dispatch) => {
        let userId = sessionStorage.getItem('userId');
        let response = fetchData({ url: `/user/uptodate/documents?userId=${userId}&skip=${skip}&limit=${limit}`, method: 'GET' });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: 'UPTODATE_DOCUMENT_LIST',
                    payload: res.response,
                    total: res.total
                });
            } else {
                dispatch({
                    type: 'UPTODATE_DOCUMENT_LIST',
                    payload: [],
                    total: 0
                });
            }
        });
    }
}

export const userRecentUpdatesNew = (skip, limit, pageNumber) => {
    return (dispatch) => {
        let userId = sessionStorage.getItem('userId');
        let response = fetchData({ url: '/user/autoupdate/recent-updates', method: 'POST', data: { 'limit': limit, 'skip': skip, userId } });
         dispatch({ type: 'RECENTUPDATES_TABLE_ATTRIBUTES_NEW', page_no: pageNumber });
         response.then(res => {
            if (res.status === 1) {
                dispatch({ type: 'GET_RECENT_UPDATES_NEW', payload: res.response, count: res.total });
            } else {
                dispatch({ type: 'GET_RECENT_UPDATES_NEW', payload: [],count: 0 });
            }
         });
    }
}