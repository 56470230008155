import * as actionTypes from './types';
import fetchData, { checkValue } from '../services';

export const saveMyProfile = (myProfileData, key) => {
    delete myProfileData.subscription;
    delete myProfileData.currentSubscription;
    delete myProfileData.status;
    delete myProfileData.createdAt;
    delete myProfileData.updatedAt;
    delete myProfileData._id;
    delete myProfileData.userId;
    delete myProfileData.phone;

    const data1 = {
        organisation_type: myProfileData.organisation_type,
        companyName: myProfileData.companyName,
        trading_name: myProfileData.trading_name,
        company_email: myProfileData.company_email,
        company_phone: myProfileData.company_phone,
        company_mobile: myProfileData.company_mobile,
        fax: myProfileData.fax,
        website: myProfileData.website,
        companyLogo: myProfileData.companyLogo,
        companyRegNo: myProfileData.companyRegNo,
    };
    const data2 = {
        registered_line1: myProfileData.registered_line1,
        registered_line2: myProfileData.registered_line2,
        registered_city: myProfileData.registered_city,
        registered_state: myProfileData.registered_state,
        registered_country: myProfileData.registered_country,
        registered_postalcode: myProfileData.registered_postalcode,
        trading_line1: myProfileData.trading_line1,
        trading_line2: myProfileData.trading_line2,
        trading_city: myProfileData.trading_city,
        trading_state: myProfileData.trading_state,
        trading_country: myProfileData.trading_country,
        trading_postalcode: myProfileData.trading_postalcode,
    };
    const data3 = {
        director_title: myProfileData.director_title,
        directorName: myProfileData.directorName,
        director_middlename: myProfileData.director_middlename,
        director_lastname: myProfileData.director_lastname,
        director_email: myProfileData.director_email,
        director_phone: myProfileData.director_phone,
        signature: myProfileData.signature,
    };
    const data4 = {
        provider_name: myProfileData.provider_name,
        provider_cqc_no: myProfileData.provider_cqc_no,
        nominated_title: myProfileData.nominated_title,
        nominated_firstname: myProfileData.nominated_firstname,
        nominated_middlename: myProfileData.nominated_middlename,
        nominated_lastname: myProfileData.nominated_lastname,
        nominated_email: myProfileData.nominated_email,
        nominated_phone: myProfileData.nominated_phone,
        nominated_signature: myProfileData.nominated_signature,
        manager_title: myProfileData.manager_title,
        managerName: myProfileData.managerName,
        manager_middlename: myProfileData.manager_middlename,
        manager_lastname: myProfileData.manager_lastname,
        manager_email: myProfileData.manager_email,
        manager_phone: myProfileData.manager_phone,
        manager_signature: myProfileData.manager_signature,
        protection_officer_title: myProfileData.protection_officer_title,
        protection_officer_firstname: myProfileData.protection_officer_firstname,
        protection_officer_middlename: myProfileData.protection_officer_middlename,
        protection_officer_lastname: myProfileData.protection_officer_lastname,
        protection_officer_email: myProfileData.protection_officer_email,
        protection_officer_phone: myProfileData.protection_officer_phone,
        protection_officer_signature: myProfileData.protection_officer_signature,
        location_name: myProfileData.location_name,
        location_address_line1: myProfileData.location_address_line1,
        location_address_line2: myProfileData.location_address_line2,
        location_address_city: myProfileData.location_address_city,
        location_address_state: myProfileData.location_address_state,
        location_address_country: myProfileData.location_address_country,
        location_address_postalcode: myProfileData.location_address_postalcode,
        location_address_email: myProfileData.location_address_email,
        location_address_phone: myProfileData.location_address_phone,
        regulated_activity: myProfileData.regulated_activity,
        local_authority_name: myProfileData.local_authority_name,
        safeguarding_officer: myProfileData.safeguarding_officer,
        safeguarding_link: myProfileData.safeguarding_link,
        safeguarding_phone: myProfileData.safeguarding_phone,
        charge_per_hour: myProfileData.charge_per_hour,
        service_band: myProfileData.service_band,
    };

    const data5 = {
        card_holder_name: myProfileData.cardname,
        cardDetails_: myProfileData.token
    }
    const formData = key === "tab_1" ? data1 : key === "tab_2" ? data2 : key === "tab_3" ? data3 : key === "tab_4" ? data4 : key === "tab_5" ? data5 : "";
    sessionStorage.setItem('userName', myProfileData.companyName);
    return (dispatch) => {
        let response =
            key === "tab_1" ? fetchData({ url: '/users/details/update/tab_1', method: 'POST', data: formData })
                : key === "tab_2" ? fetchData({ url: '/users/details/update/tab_2', method: 'POST', data: formData })
                    : key === "tab_3" ? fetchData({ url: '/users/details/update/tab_3', method: 'POST', data: formData })
                        : key === "tab_4" ? fetchData({ url: '/users/details/update/tab_4', method: 'POST', data: formData })
                            : key === "tab_5" ? fetchData({ url: '/users/details/update/payment', method: 'POST', data: formData })
                                : ""
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'My profile Updated Successfully.', alignment: "centre" }
                });
                if (key === "tab_5") {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });

                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else {
                if (key === "tab_5") {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const saveUserProfile = (userData) => {
    let formData = userData;
    formData.userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
    sessionStorage.setItem('userName', userData.firstname);
    return (dispatch) => {
        let response = fetchData({ url: '/users/update/myaccount', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'My profile Updated Successfully.', alignment: "centre" }
                });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const viewProfile = () => {
    let userId = sessionStorage.getItem('userId');
    if (sessionStorage.getItem('userRole') === 'user') {
        userId = sessionStorage.getItem('companyUserId') ? sessionStorage.getItem('companyUserId') : sessionStorage.getItem('login_user_id') ? sessionStorage.getItem('login_user_id') : '';
    } else if (sessionStorage.getItem('userRole') === 'employee') {
        userId = sessionStorage.getItem('login_user_id') ? sessionStorage.getItem('login_user_id') : sessionStorage.getItem('login_user_id') ? sessionStorage.getItem('login_user_id') : '';
    }
    return (dispatch) => {
        let response = fetchData({ url: '/users/getuserbyid', method: 'POST', data: { userId } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.ADD_MYPROFILE,
                    payload: res.response
                });
                dispatch({
                    type: actionTypes.DOCUMENT_MANAGEMENT_STATUS,
                    payload: res.response.documentManagement
                });
                if (res.response && res.response.companyName && String(res.response.companyName).length > 0 && String(res.response.companyName) !== String(undefined) && String(res.response.companyName) !== String(null)) {
                    dispatch({ type: actionTypes.DISABLED_COMP_NAME, payload: true });
                }
                if (res.response && res.response.companyRegNo && String(res.response.companyRegNo).length > 0 && String(res.response.companyRegNo) !== String(undefined) && String(res.response.companyRegNo) !== String(null)) {
                    dispatch({ type: actionTypes.DISABLED_COMP_REGNO, payload: true });
                }
                if (res.response && res.response.trading_name && checkValue(res.response.trading_name)) {
                    dispatch({ type: actionTypes.DISABLED_COMP_TRADING, payload: true });
                }
                // if (res.response && res.response.companyDetails && res.response.companyDetails.subdomain && res.response.companyDetails.subdomain !== undefined && res.response.companyDetails.subdomain !== null && res.response.companyDetails.subdomain !== '') {
                //     dispatch({ type: actionTypes.SHOW_SUBDOMAIN_NOTIFY, payload: false });
                // } else {
                //     dispatch({ type: actionTypes.SHOW_SUBDOMAIN_NOTIFY, payload: true });
                // }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const uploadLogo = (data, key, type, activeTab) => {
    var formData = new FormData();
    if (key === "signature") {
        formData.append("signature", data);
    } if (key === "companyLogo") {
        formData.append("companyLogo", data);
    }
    if (key === "nominated_signature") {
        formData.append("nominated_signature", data);
    }
    if (key === "manager_signature") {
        formData.append("manager_signature", data);
    }
    if (key === "protection_officer_signature") {
        formData.append("protection_officer_signature", data);
    }
    return (dispatch) => {
        if (key === 'companyLogo' && activeTab === '1') {
            let response = fetchData({ url: '/admin/users/logo/upload', method: 'POST', data: formData });
            response.then(res => {
                if (res.status === 1) {
                    dispatch({ type: actionTypes.ADD_MYPROFILE, payload: res.message });
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Successfully Uploaded.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Not Uploaded.', alignment: "centre" }
                    });
                }
            });
        } else {
            let response = fetchData({ url: '/admin/users/image/upload', method: 'POST', data: formData });
            response.then(res => {
                if (res.status === 1) {
                    dispatch({ type: actionTypes.ADD_MYPROFILE, payload: res.message });
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Successfully Uploaded.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Not Uploaded.', alignment: "centre" }
                    });
                }
            });
        }
    }
}

export const addMyProfileData = (myProfilesData) => {
    return {
        type: actionTypes.ADD_MYPROFILE,
        payload: myProfilesData
    }
};

export const get_settings_data = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_SETTINGS, payload: {} });
        let response = fetchData({ url: '/users/users/settings', method: 'POST', data: {} });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.UPDATE_SETTINGS, payload: res.response });
                dispatch({ type: actionTypes.UPDATE_SETTINGS_PACKAGE, payload: res.subscriptionPackage });
                dispatch({ type: actionTypes.DOCUMENT_SETTINGS, payload: res.response.documentSettings });
            } else {
                dispatch({ type: actionTypes.UPDATE_SETTINGS, payload: {} });
                dispatch({ type: actionTypes.DOCUMENT_SETTINGS, payload: {} });
            }
        });
    }
}


export const set_settings_data = (settings_data) => {
    return {
        type: actionTypes.UPDATE_SETTINGS,
        payload: settings_data
    }
}

export const update_settings_data = (myProfileData) => {
    let formData = myProfileData;
    formData.userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
    formData.role = sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : "";
    formData.color = myProfileData.documentSettings ? JSON.stringify(myProfileData.documentSettings.color) : JSON.stringify(myProfileData.color);
    formData.template = myProfileData.template ? myProfileData.template : '';
    delete formData.searchValue;
    delete formData.$init;
    delete formData.documentSettings

    return (dispatch) => {
        let response = fetchData({ url: '/users/users/update/settings', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: actionTypes.UPDATE_SETTINGS, payload: myProfileData });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.message, alignment: "centre" }
                });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const subdomainRemindModalTogggle = (condition) => {
    return {
        type: actionTypes.SHOW_SUBDOMAIN_NOTIFY, payload: condition
    }
}

export const storageAlertModalToggle = (val) => ({ type: actionTypes.STORAGE_ALERT, payload: val })

export const getdocumentslist = (userId, searchValue = '') => async (dispatch) => {
    dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
    let result = await fetchData({ url: `/user/documents/all?userId=${userId}&searchValue=${searchValue}`, method: 'GET' });
    if (result && result.status === 1) {
        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
        dispatch({ type: actionTypes.ALL_DOCUMENT_LIST, payload: result.response.documentList });
        dispatch({ type: actionTypes.ALL_TEMPLATE_LIST, payload: result.response.template });
        return result.response.documentList;
    } else {
        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
        dispatch({
            type: actionTypes.ALL_DOCUMENT_LIST,
            payload: []
        });
    }
}

export const getPublicPolicies = (companyID, skip, limit, pageNumber, searchText = "") => async (dispatch) => {
    dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
    dispatch({ type: actionTypes.SET_TABLE_ATTRIBUTES, 'page_no': pageNumber, 'serachVal': (searchText && searchText !== '') ? searchText : "", 'skip': skip, 'itemPerPage': Number(limit) });
    if(!companyID){
        companyID = sessionStorage.getItem('userId')
    }
    if (companyID && checkValue(companyID)) {
        let result = await fetchData({ url: `/company/published/policies/list?companyId=${companyID}&skip=${skip}&limit=${limit}`, method: 'GET' });
        if (result && result.status === 1) {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.PUBLIC_POLICY_DOCUMENT,
                payload: result.response && result.response.length > 0 ? result.response : [],
                total: result.total ? result.total : 0,
            });
        } else {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.PUBLIC_POLICY_DOCUMENT,
                payload: []
            });
        }
    }
}
export const getAllPublicPolicies = (companyID) => async (dispatch) => {
    dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
    if (companyID && checkValue(companyID)) {
        let result = await fetchData({ url: `/company/public/policies/list?companyId=${companyID}`, method: 'GET' });
        if (result && result.status === 1) {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.PUBLIC_POLICY_DOCUMENT,
                payload: result.response && result.response.length > 0 ? result.response : [],
                total: result.total ? result.total : 0,
            });
        } else {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.PUBLIC_POLICY_DOCUMENT,
                payload: []
            });
        }
    }
}

export const deletePublishedPolicy = (id) => async (dispatch) => {
    let userId = sessionStorage.getItem('userId');
    if (id && String.length > 0) {
        let result = await fetchData({ url: `/company/publishedpolicy/delete?id=${id}&userId=${userId}`, method: 'DELETE' });
        if (result && result.status === 1) {
            dispatch({
                type: actionTypes.DELETE_MODAL_VIEW,
                payload: {}
            });
            dispatch({ type: actionTypes.UPDATE_PUBLIC_POLICY_DOCUMENT, id: id });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'SuccessFully Deleted.', alignment: "centre" }
            });
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Not Deleted.', alignment: "centre" }
            });
        }
    } else {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'OOPs! Somthing went wrong', alignment: "centre" }
        });
    }
}

export const setcolor = (color, key) => ({ type: actionTypes.SET_COLOR, payload: { color, key } });

export const selectTemplate = (id) => ({ type: actionTypes.CHOOSE_TEMPLATE, payload: id });

export const viewtemp = (id) => {
    return async (dispatch) => {
        if (id) {


            const res = await fetchData({ url: `/admin/user/template/view`, method: 'POST', data: { templateId: id, userId: sessionStorage.userId } })
            if (res.status === 1) {
                dispatch({ type: actionTypes.VIEWTEMP, payload: res.response })
            } else {
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'OOPs! Somthing went wrong', alignment: "centre" } });
            }
        } else {
            dispatch({ type: actionTypes.VIEWTEMP, payload: '' })
        }
    }
}

// export const correctionModalViewFun = () => ({ type: actionTypes.CORRECTION_MODAL_VIEW });

export const correctionModalViewFun = (value) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.CORRECTION_MODAL_VIEW });
        dispatch({ type: actionTypes.CORRECTION_MODAL_VIEW_TYPE, payload: value });
    }
}

export const ChangeCompanyName = (obj, changeRequest) => {
    let formData = new FormData();
    formData.append('reason', obj.reason);
    formData.append('file', obj.evidenceDoc);
    formData.append('detail_expalanation', obj.notes);
    formData.append('userId', sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "");
    formData.append('role', sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : "");
    formData.append('filename', obj.filename);
    formData.append('changeRequest', changeRequest);
    return async (dispatch) => {
        const res = await fetchData({ url: '/company/profile/edit/request', method: 'POST', data: formData })
        if (res.status === 1) {
            dispatch({ type: actionTypes.CORRECTION_MODAL_VIEW, payload: res.response })
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'Edit request sent successfully', alignment: "centre" } });
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'OOPs! Somthing went wrong', alignment: "centre" } });
        }
    }
}

export const openBussinessAddressModal = (value) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.BUSSINESSADDRESS_MODAL_VIEW, payload: value });
    }
}

export const addPublicPolicy = (id) => async (dispatch) => {
    if (id && String.length > 0) {
        let result = await fetchData({ url: `/company/publishedpolicy/add`, method: 'POST',data:{fileId:id} });
        if (result && result.status === 1) {
            dispatch(getPublicPolicies('', 0, 20));
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Something went Wrong', alignment: "centre" }
            });
        }
    } else {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'OOPs! Somthing went wrong', alignment: "centre" }
        });
    }
}