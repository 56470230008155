import * as actionTypes from '../actions/types.js';
export default (state = { docSignContent: '', signature: '', showSignatureModal: false, textFontFamily: '', cropper: '', previewImage: '', cropData: '', pdfData: '', recipientsID: '', canSharedEdit: false, canDownload: false,recipientDetails:'' }, action) => {


    switch (action.type) {
        case actionTypes.DOC_SIGN_CONTENT:
            return {
                ...state,
                docSignContent: action.payload,
            };
        case actionTypes.DOC_SIGN_MODEL:
            return {
                ...state,
                showSignatureModal: !state.showSignatureModal
            };
        // case actionTypes.DOC_SET_SIGN_MODEL_FONT_FAMILY:
        //     return {
        //         ...state,
        //         textFontFamily: action.payload
        //     };
        case actionTypes.DOC_SIGN_CROP:
            return {
                ...state,
                cropper: action.payload,
            };
        case actionTypes.DOC_SIGN_SIGNATURE_PREVIEW_IMAGE:
            return {
                ...state,
                previewImage: action.payload
            }
        case actionTypes.DOC_SIGN_CROPDATA:
            return {
                ...state,
                cropData: action.payload,
            }
        case actionTypes.DOC_SIGN_PDF:
            return {
                ...state,
                pdfData: action.payload,
            }
        case actionTypes.DOC_SIGN_RECIPIENTS:
            return {
                ...state,
                recipientsID: action.payload,
            }
        case actionTypes.CAN_SHARED_FILE_EDIT:
            return {
                ...state,
                canSharedEdit: action.payload,
            }
        case actionTypes.DOC_SIGN_DOWNLOAD:
            return {
                ...state,
                canDownload: action.payload,
            }
        case actionTypes.DOC_SIGN_RECIPIENT_DETAILS:
            return {
                ...state,
                recipientDetails: action.payload,
            }
        default:
            return state;
    }
}
