import * as actionTypes from '../actions/types.js';

export default (state = { addViewData: {} }, action) => {


    switch (action.type) {

        case actionTypes.ADD_SIGNUP:
            return {
                ...state,
                addViewData: { ...state.addViewData, ...action.payload }
            };

        case actionTypes.CLEAR_SIGNUP_DATA:
            return {
                ...state,
                addViewData: {}
            };
		case actionTypes.OPEN_SIGNATURE_MODAL:
            return {
                ...state,
                showSignatureModal: !state.showSignatureModal
            };
        case actionTypes.SHOW_SIGNIN:
            return {
                ...state,
                showSignup: !state.showSignup
            }
        default:
            return state;
    }
};
