import * as actionTypes from '../actions/types.js';

export default (state = {
    addViewData: {}, settings_data: {}, disabled_comp_name: false, disabled_comp_regno: false, subdomainRemindModal: false,
    storageAlertModal: false, allDocumentList: [], publicPolicies: [], total: 0, documentSettings: {}, templates: [], viewtemplate: false,
    templateContent: '', disabled_comp_trading: false, showCorrectionModal: false, showCorrectionModalValue: '',settings_data_package:{}, viewBussinessAddressModal :false, showBussinessAddressValue : {}
}, action) => {

    switch (action.type) {
        case actionTypes.ADD_MYPROFILE:
            return {
                ...state,
                addViewData: { ...state.addViewData, ...action.payload }
            };
        case actionTypes.UPDATE_SETTINGS:
            return {
                ...state,
                settings_data: { ...action.payload }
            };
        case actionTypes.UPDATE_SETTINGS_PACKAGE:
            return {
                ...state,
                settings_data_package: { ...action.payload }
            };
        case actionTypes.DISABLED_COMP_NAME:
            return {
                ...state,
                disabled_comp_name: action.payload
            };
        case actionTypes.DISABLED_COMP_REGNO:
            return {
                ...state,
                disabled_comp_regno: action.payload
            };
        case actionTypes.DISABLED_COMP_TRADING:
            return {
                ...state,
                disabled_comp_trading: action.payload
            };
        case actionTypes.SHOW_SUBDOMAIN_NOTIFY:
            return {
                ...state,
                subdomainRemindModal: action.payload
            };
        case actionTypes.STORAGE_ALERT:
            return {
                ...state,
                storageAlertModal: action.payload ? action.payload : !state.storageAlertModal
            };
        case actionTypes.ALL_DOCUMENT_LIST:
            return {
                ...state,
                allDocumentList: action.payload
            };
        case actionTypes.PUBLIC_POLICY_DOCUMENT:
            return {
                ...state,
                publicPolicies: action.payload,
                total: action.total
            };
        case actionTypes.UPDATE_PUBLIC_POLICY_DOCUMENT:
            return {
                ...state,
                publicPolicies: state.publicPolicies.filter(e => e._id !== action.id)
            };
        case actionTypes.DOCUMENT_SETTINGS:
            return {
                ...state,
                documentSettings: action.payload ? action.payload : {}
            };
        case actionTypes.SET_COLOR:
            let obj = action.payload;
            let color = state.documentSettings.color;
            if (obj.key === 'all') {
                color[obj.key] = obj.color;
                color['h1'] = obj.color;
                color['h2'] = obj.color;
                color['h3'] = obj.color;
                color['h4'] = obj.color;
                color['h5'] = obj.color;
                color['h6'] = obj.color;
            } else {
                color[obj.key] = obj.color
            }
            return {
                ...state,
                documentSettings: {
                    ...state.documentSettings,
                    color: color
                }
            };
        case actionTypes.ALL_TEMPLATE_LIST:
            return {
                ...state,
                templates: action.payload
            }
        case actionTypes.CHOOSE_TEMPLATE:
            return {
                ...state,
                documentSettings: {
                    ...state.documentSettings,
                    template: action.payload
                }
            }
        case actionTypes.VIEWTEMP:
            return {
                ...state,
                viewtemplate: !state.viewtemplate,
                templateContent: action.payload ? action.payload : ''
            }
        case actionTypes.CORRECTION_MODAL_VIEW:
            return {
                ...state,
                showCorrectionModal: !state.showCorrectionModal,
            }
        case actionTypes.CORRECTION_MODAL_VIEW_TYPE:
            return {
                ...state,
                showCorrectionModalValue: action.payload,
            }
        case actionTypes.BUSSINESSADDRESS_MODAL_VIEW:
            return {
                ...state,
                viewBussinessAddressModal: !state.viewBussinessAddressModal,
                showBussinessAddressValue: action.payload,
            }
        default:
            return state;
    }
};
