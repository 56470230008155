import { combineReducers } from 'redux'
import documentData from './documentReducer';
import UIReducer from './UIReducer';
import userLoginData from './userLoginReducer';
import authentication from './authenticationReducer';
import settingsData from './settingsReducer';
import templateData from './templateReducer';
import companyData from './companiesReducer';
import subscriptionData from './subscriptionReducer';
import validationData from './validationReducer';
import dashboardData from './dashboardReducer';
import myProfile from './myProfileReducer';
import editor from './editorReducer';
import signUp from './signUpReducer';
import button from './buttonReducer';
import sort from './sortReducer';
import userData from './userReducer';
import homePageData from './homeReducer';
import ProductDetailData from './productDetailReducer';
import cartData from './cartReducer';
import pageLoader from './pageLoaderReducer';
import notificationData from './notificationReducer';
import employeePolicyReducer from './employeePolicyReducer';
import employeesReducer from './employeesReducer';
import myFilesReducer from './myFilesReducer';
import knowledgeBaseReducer from './knowledgebasereducer';
import docSignReducer from './docSignReducer';
const AppReducer = combineReducers({
	documentData: documentData,
	UIReducer: UIReducer,
	userLoginData: userLoginData,
	authentication: authentication,
	settingsData: settingsData,
	templateData: templateData,
	companyData: companyData,
	subscriptionData: subscriptionData,
	validationData: validationData,
	dashboardData: dashboardData,
	myProfile: myProfile,
	editor: editor,
	signUp: signUp,
	button: button,
	sort: sort,
	userData: userData,
	homePageData: homePageData,
	ProductDetailData: ProductDetailData,
	cartData: cartData,
	pageLoader: pageLoader,
	notificationData: notificationData,
	employeePolicyData: employeePolicyReducer,
	employeeData: employeesReducer,
	myFilesData: myFilesReducer,
	knowledgeBase: knowledgeBaseReducer,
	docsign : docSignReducer
});

const rootReducer = (state, action) => {
	let data = sessionStorage.getItem('usersessiontoken');
	if (data) {
		data = JSON.parse(data);
		let now = new Date();
		let expiration = new Date(data.timestamp);
		expiration.setMinutes(expiration.getMinutes() + 240);
		if (now.getTime() > expiration.getTime()) {
			sessionStorage.removeItem('isAuthenticated');
			sessionStorage.removeItem('usersessiontoken');
			sessionStorage.removeItem('token');
			sessionStorage.removeItem('userName');
			sessionStorage.removeItem('userId');
			sessionStorage.removeItem('userRole');
			sessionStorage.removeItem('searchType');
			sessionStorage.removeItem('companyUserId');
			sessionStorage.removeItem('selectedFile');
			sessionStorage.removeItem('clickedFolderUid');
			sessionStorage.removeItem('clickedFolder');
			sessionStorage.removeItem('userClickedFileId');
			sessionStorage.removeItem('clickedFolderId');
			sessionStorage.removeItem('login_user_id');
			sessionStorage.removeItem('user_permission');
			sessionStorage.removeItem('myfilesSelectedFolderId');
			window.location.reload();
		}
	}
	if (window.location.pathname !== '/company/documents' && window.location.pathname !== '/company/documents/editor' && window.location.pathname !== '/company/documents/compare') {
		sessionStorage.removeItem('clickedFolderId');
		sessionStorage.removeItem('rightClickedFolderId');
		sessionStorage.removeItem('clickedFolder');
		// sessionStorage.removeItem('userClickedFileId');
		sessionStorage.removeItem('selectedFile');
	}
	if (window.location.pathname !== '/company/documents/editor' && window.location.pathname !== '/company/documents/compare') {
		// sessionStorage.removeItem('userClickedFileId');
		sessionStorage.removeItem('selectedFile');
	}
	let querySelector = document.querySelector('.right-sec-cnt');
	let expanded = sessionStorage.getItem('sideBarSiteExpanded');
	if (querySelector && querySelector !== '') {
		if (expanded && (expanded === false || expanded === 'false')) {
			querySelector.classList.add('right-collapsed');
		} else {
			querySelector.classList.remove('right-collapsed');
		}
	}
	if (action.type === 'LOGOUT') {
		state = undefined
	}
	return AppReducer(state, action)
}

export default rootReducer;