import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { changeModal, getOneNotification, closeNotification, getNotifications } from "../../../actions/notificationActions"

const NotificationModal = () => {
    const showNotification = useSelector(state => state.notificationData.showNotification),
        data = useSelector(state => state.notificationData.notificationList),
        limit = useSelector(state => state.notificationData.limit),
        totalDataCount = useSelector(state => state.notificationData.total),
        page = useSelector(state => state.notificationData.pageDesign),
        dispatch = useDispatch();

    const toggleModal = () => {
        dispatch(changeModal())
    }
    const toNotifications = () => {
        let userId = sessionStorage.getItem("userId");
        dispatch(getNotifications(userId, limit + 10))
    }
    const getNotification = (id) => {
        dispatch(changeModal())
        dispatch(closeNotification())
        dispatch(getOneNotification(id))
    }
    return (
        <div className="remove-bag">
            <div>
                <Modal isOpen={showNotification} toggle={(e) => toggleModal()} className={page === "home" ? "adding_folder_popup notificationpopupHome" : "adding_folder_popup notificationpopup"}>
                    <div className="head">New Updates</div>
                    <ModalBody>
                        <div>
                            <ul>
                                {data && data.length > 0 && data.map((item, i) => {
                                    return (
                                        <li className={item.status === 0 ? "read_only user-notify" : "user-notify"} key={item._id} onClick={() => getNotification(item._id)}>
                                            <div className="category"><p>{item.category}</p></div>
                                            
                                            <div className='not-detail'>
                                                <div className='not-detail-cont'>
                                                    <div className="Title"> {item.title}</div>
                                                    <div className="content">{item.content}</div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </ModalBody>
                    {totalDataCount && data && data.length && totalDataCount !== data.length ?
                        <ModalFooter>
                            <Button color="default" onClick={(e) => toNotifications()}>See More....</Button>
                        </ModalFooter> : null}
                </Modal>
            </div>
        </div>
    )
}

export default NotificationModal;