import * as actionTypes from '../actions/types.js';

export default (state = {
    listData: [], editViewData: {}, addViewData: {}, restoretData: {}, user_activity: [],
    totalcount: 0, activePage: 1, itemPerPage: 25, searchValue: '', admin_added_roles_data: [], subadminRoles: [], rolesData: [],
    historyData: [], historyTotalCount: 0, historyActivePage: 1, historyLimit: 25, historySkip: 0, historyDateFilter: "All", historyTypeFilter: "All",
    allUsersList: [], selectedUsers: [], addGroupData: {}, userGroups: [], skip: 0, editGroupData: {}, clicked_ID: '', mainParentFolders: [],subFolders:[]
}, action) => {


    switch (action.type) {

        case actionTypes.USERS_ACTIVITY_LIST:
            return {
                ...state, user_activity: [...action.payload]
            }

        case actionTypes.USERS_LIST:
            return {
                ...state,
                listData: [...action.payload],
                count: action.count
            };

        case actionTypes.CLEAR_USER_LIST:
            return {
                ...state,
                listData: [...action.payload],
                count: action.count
            };

        case actionTypes.EDIT_VIEW_USER:
            // let data = action.payload;
            // if (data && data.assignedpolicyDocuments && data.assignedpolicyDocuments.length > 0) {
            //     data['selectedPolicies'] = data.assignedpolicyDocuments.map(e => ({ value: String(e.documentID), label: e.documentName }));
            //     data['dueDate'] = data.assignedpolicyDocuments[0].dueDate ? new Date(data.assignedpolicyDocuments[0].dueDate) : null
            // }
            return {
                ...state,
                editViewData: { ...state.editViewData, ...action.payload },
                rolesData: action.payload && action.payload.permission && action.payload.permission.length > 0 ? action.payload.permission : [],
            };

        case actionTypes.ADD_VIEW_USER:
            return {
                ...state,
                addViewData: { ...state.addViewData, ...action.payload }
            };

        case actionTypes.CLEAR_ADD_VIEW_USER:
            return {
                ...state,
                addViewData: { ...action.payload },
                editViewData: { ...action.payload },
                rolesData: []
            };

        case actionTypes.USER_DELETE_MODAL:
            return {
                ...state,
                showUserDeleteModal: !state.showUserDeleteModal,
                deleteUserId: action.userId,
            };
        case actionTypes.UPADTE_USER_LIST:
            return {
                ...state,
                listData: state.listData.filter(e => e._id !== action.userId)
            }
        case actionTypes.ADMIN_ADDED_ROLES:
            return {
                ...state,
                admin_added_roles_data: [...action.payload]
            }
        case actionTypes.ADD_ROLES_DATA:
            let index = state.rolesData.map(e => e.name).indexOf(action.payload.name);
            if (index > -1) {
                state.rolesData.splice(index, 1);
            } else {
                state.rolesData.push({ 'name': action.payload.name });
            }
            return {
                ...state,
                rolesData: state.rolesData,
                addViewData: { ...state.addViewData, ...{ 'permission': state.rolesData } }
            };
        case actionTypes.EDIT_ROLES_DATA:
            let indexes = state.rolesData.map(e => e.name).indexOf(action.payload.name);
            if (indexes > -1) {
                state.rolesData.splice(indexes, 1);
            } else {
                state.rolesData.push({ 'name': action.payload.name });
            }
            return {
                ...state,
                rolesData: state.rolesData,
                editViewData: { ...state.editViewData, ...{ 'permission': state.rolesData } }
            };
        case actionTypes.SUBADMIN_ROLES_LIST:
            return {
                ...state,
                subadminRoles: [
                    { 'name': 'Dashboard', 'enable': false },
                    { 'name': 'Users', 'enable': false },
                    { 'name': 'Documents', 'enable': false },
                    { 'name': 'Can add new doc', 'enable': false },
                    { 'name': 'Can add folder', 'enable': false },
                    { 'name': 'Other files (Pdf, Power point, Word, Excel)', 'enable': false },
                    { 'name': 'Can delete any doc', 'enable': false },
                    { 'name': 'Can delete any folder', 'enable': false },
                    { 'name': 'Can delete any files', 'enable': false },
                    { 'name': 'Can delete only other files they create', 'enable': false },
                    { 'name': 'Can delete only doc they create', 'enable': false },
                    { 'name': 'Can delete folder created by other', 'enable': false },
                    { 'name': 'Can delete only folder they created by them', 'enable': false },
                    { 'name': 'Can edit all folder', 'enable': false },
                    { 'name': 'Can edit all docs', 'enable': false },
                    { 'name': 'Can edit all files', 'enable': false },
                    { 'name': 'Subscriptions', 'enable': false },
                    { 'name': 'Manage users', 'enable': false },
                    { 'name': 'Settings', 'enable': false }
                ]
            };
        case actionTypes.USER_HISTORY_LIST:
            return {
                ...state,
                historyData: action.payload,
                historyTotalCount: action.total
            };
        case actionTypes.USER_HISTORY_DATE:
            return {
                ...state,
                historyDateFilter: action.payload
            };
        case actionTypes.USER_HISTORY_TYPE:
            return {
                ...state,
                historyTypeFilter: action.payload,
            }
        case actionTypes.GET_ALL_USERS:
            return {
                ...state,
                allUsersList: action.payload ? action.payload : [],
            }
        case actionTypes.SELECTED_USERS:
            return {
                ...state,
                selectedUsers: action.payload,
            }
        case actionTypes.ADD_USERGROUP_DATA:
            return {
                ...state,
                addGroupData: { ...state.addGroupData, ...action.payload }
            }
        case actionTypes.USER_GROUPS_LIST:
            return {
                ...state,
                userGroups: action.payload,
                totalcount: action.total
            }
        case actionTypes.USER_TABLE_ATTRIBUTES:
            return {
                ...state,
                skip: action.payload && action.payload.skip ? action.payload.skip : 0,
                activePage: action.payload && action.payload.activePage ? action.payload.activePage : 1,
                searchValue: action.payload && action.payload.searchValue ? action.payload.searchValue : '',
            }
        case actionTypes.CLEAR_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload && action.payload.searchValue ? action.payload.searchValue : '',
            }
        case actionTypes.SET_SEARCHED_VALUE:
            return {
                ...state,
                searchValue: action.payload,
            }
        case actionTypes.EDIT_USERGROUP_DATA:
            return {
                ...state,
                editGroupData: { ...state.addGroupData, ...action.payload }
            }
        case actionTypes.SET_CLICKED_ID:
            return {
                ...state,
                clicked_ID: action.payload
            }
        case actionTypes.MAIN_PARENT_FOLDER_LIST:
            return {
                ...state,
                mainParentFolders: action.payload && action.payload && action.payload.length > 0 ? action.payload : [],
            }
        case actionTypes.SUB_FOLDER_LIST:
            return {
                ...state,
                subFolders: action.payload && action.payload && action.payload.length > 0 ? action.payload : [],
            }
        default:
            return state;
    }
};
