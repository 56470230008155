import * as actionTypes from '../actions/types.js';

export default (state = {
    featuredDocuments: [], testimonialLists: [], newUpdateDocuments: [], updatedDocuments: {}, packageLists: [],
    count: 0, activePage: 1, itemPerPage: 20, searchText: '', totalDocCount: 0, activeDocPage: 1, itemPerDocPage: 10, searchDocText: '',
    userBlogLists: [], activeTab: "1", activeTab2: "1", documentList: [], sampleDocList: []
}, action) => {

    switch (action.type) {
        case actionTypes.FEATURE_DOCUMENTS:
            return {
                ...state,
                featuredDocuments: [...action.payload]
            };
        case actionTypes.SEARCH_PACKAGE_LISTS:
            return {
                ...state,
                packageLists: [...action.payload],
                count: action.count
            }
        case actionTypes.PACKAGE_LISTS_PAGINATION:
            return {
                ...state,
                activePage: action.page_no
            }
        case actionTypes.PACKAGE_LISTS_SEARCHTEXT:
            return {
                ...state,
                searchText: action.searchText
            }
        case actionTypes.TESTIMONIAL_LISTS:
            return {
                ...state,
                testimonialLists: [...action.payload]
            }
        case actionTypes.NEW_UPDATED_DOCUMENTS:
            return {
                ...state,
                newUpdateDocuments: [...action.payload],
                totalDocCount: action.count
            }
        case actionTypes.UPDATED_DOCUMENTS_PAGINATION:
            return {
                ...state,
                activeDocPage: action.page_no
            }
        case actionTypes.UPDATED_DOCUMENTS_SEARCHTEXT:
            return {
                ...state,
                searchDocText: action.searchText
            }
        case actionTypes.USER_BLOG_LISTS:
            return {
                ...state,
                userBlogLists: [...action.payload]
            }
        case actionTypes.FEATURE_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload
            }
        case actionTypes.REQULARITY_ACTIVE_TAB:
            return {
                ...state,
                activeTab2: action.payload
            }
        case actionTypes.LATEST_UPDATED_DOCUMENTS:
            return {
                ...state,
                documentList: action.payload
            }
        case actionTypes.SAMPLE_FILES_LIST:
            return {
                ...state,
                sampleDocList: action.payload
            }
        default:
            return state;
    }
};
