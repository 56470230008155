import * as actionTypes from '../actions/types.js';


export default (state = { isAuthenticated: false }, action) => {

    switch (action.type) {

        case actionTypes.AUTHENTICATION:
            return {
                ...state,
                isAuthenticated: { ...state.isAuthenticated, ...action.payload }
            };

        default:
            return state;
    }
};
