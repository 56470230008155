import * as actionTypes from '../actions/types.js';


export default (state = {
    listData: [], editViewData: {}, addViewData: {}, restoretData: {}, activeTab: "1", subActiveTab: "1",
    showOptModal: false, showForgotModal: false, showLoginModal: false, showForgotModal: false
}, action) => {


    switch (action.type) {

        case actionTypes.COMPANY_LIST:
            return {
                ...state,
                listData: [...state.listData, ...action.payload]
            };

        case actionTypes.CLEAR_COMPANY_LIST:
            return {
                ...state,
                listData: [...action.payload]
            };

        case actionTypes.EDIT_VIEW_COMPANY:
            return {
                ...state,
                editViewData: { ...state.editViewData, ...action.payload }
            };

        case actionTypes.ADD_VIEW_COMPANY:            
            return {
                ...state,
                addViewData: { ...state.addViewData, ...action.payload }
            };

        // case actionTypes.IMAGE_ADD_VIEW_COMPANY:
        //     return {
        //         ...state,
        //         imagePath: action.payload,
        //         // list: state.list.map((item, i) => item._id === action.payload.folderId ?
        //         //     { ...item, name: action.payload.folderName } : item)
        // };

        case actionTypes.CLEAR_ADD_VIEW_COMPANY:
            return {
                ...state,
                addViewData: { ...action.payload }
            };

        case actionTypes.COMPANY_DELETE_MODAL:
            return {
                ...state,
                showCompanyDeleteModal: !state.showCompanyDeleteModal,
                deleteCompanyId: action.companyId,
                listData: state.listData.filter(e => e._id !== action.companyId)
            };

        case actionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload
            }
        case actionTypes.SET_SUB_ACTIVE_TAB:
            return {
                ...state,
                subActiveTab: action.payload
            }
        default:
            return state;
    }
};
