import React, { useEffect, useState } from 'react';
import WOW from '../../../js/wow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';

import './index.css';

import { Clear_subs_Data, getSettingsData, getSubscriptionLists, categoryList, countryList, subscriptionTypeList } from '../../../actions/subscriptionAction';
import { viewProfile } from '../../../actions/signUpAction';
import { checkValue } from '../../../services';

import list_icon from '../../../images/new-design/home-page/proce-icon.png';
import list_icon_2 from '../../../images/new-design/home-page/proce-icon-2.png';
import list_icon_hover from '../../../images/new-design/home-page/proce-icon-hover.png';
import list_icon_2_hover from '../../../images/new-design/home-page/proce-icon-2-hover.png';
import fixedphone from "../../../images/new-design/home-page/fixed-phone.svg";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Header from '../../Common/Header';


const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.black,
        color: 'rgba(256, 256, 256)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        top: -10,
    },
}))(Tooltip);

const SubscriptionList = (props) => {
    const pageLoader = useSelector(state => state.pageLoader.pending),
        subscriptionLists = useSelector(state => state.subscriptionData.subscriptionLists),
        userSubscription = useSelector(state => state.myProfile.addViewData.currentSubscription),
        itemPerPage = useSelector(state => state.subscriptionData.itemPerPage),
        skip = useSelector(state => state.subscriptionData.skip),
        searchText = useSelector(state => state.subscriptionData.searchText),
        settingsData = useSelector(state => state.subscriptionData.settingData),
        package_price_filter = useSelector(state => state.subscriptionData.package_price_filter),
        pricing_filter = useSelector(state => state.subscriptionData.pricing_filter),
        sub_categoryList = useSelector(state => state.subscriptionData.categoryList),
        sub_typeList = useSelector(state => state.subscriptionData.subscriptionTypeList),
        countriesList = useSelector(state => state.subscriptionData.countryList),
        showNoData = useSelector(state => state.subscriptionData.showNoData),
        // userDetails = useSelector(state => state.UIReducer.userDetails),
        dispatch = useDispatch();
    // const classes = useStyles();



    const [countryId, setCoutryId] = useState([]);
    const [subsCategoryId, setSubsCategoryId] = useState([]);
    const [subsTypeId, setSubsTypeId] = useState([]);
    const [listView, setListView] = useState(false);

    useEffect(() => {
        new WOW().init();
        window.scrollTo(0, 0);
        dispatch(Clear_subs_Data());
        dispatch(getSettingsData());
        dispatch(countryList());
        dispatch(categoryList());
        dispatch(subscriptionTypeList());
        if (sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole') === 'user') {
            props.history.push('/404');
            props.history.push('/');
        } else {
            if (props && props.location && props.location.state && props.location.state.country && props.location.state.country !== undefined && props.location.state.category && props.location.state.category !== undefined) {
                setCoutryId([...countryId, props.location.state.country]);
                setSubsCategoryId([...subsCategoryId, props.location.state.category])
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, searchText, 'default', [props.location.state.country], [props.location.state.category]));
            } else if (props && props.location && props.location.state && props.location.state.country && props.location.state.country !== undefined) {
                setCoutryId([...countryId, props.location.state.country]);
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, searchText, 'default', [props.location.state.country]));
            }else if (props && props.location && props.location.state && props.location.state.category){
                setSubsCategoryId([...subsCategoryId, props.location.state.category])
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, searchText, 'default', countryId, [props.location.state.category]));
            }else {
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, searchText, 'default', package_price_filter, pricing_filter));
            }
            if (sessionStorage.getItem("userId")) {
                dispatch(viewProfile());
            }
        
        }
    }, []);

    const handleChange = (e) => {
        dispatch(getSubscriptionLists(skip, itemPerPage, 1, e.target.value, 'default'));
    }
    const policyFilter = (e, type, key) => {
        const checked = e.target.checked, name = e.target.name, value = e.target.value;
        // searchUrl(name, value);
        if (type === 'type') {
            let typename = [...subsTypeId];
            if (checked) {
                subsTypeId.includes(key) ? setSubsTypeId([...subsTypeId]) : setSubsTypeId([...subsTypeId, key]);
                typename = typename.includes(key) ? [...typename] : [...typename, key];
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, '', 'default', countryId, subsCategoryId, typename));
            } else {
                subsTypeId.includes(key) ? setSubsTypeId([...subsTypeId.filter(e => e !== key)]) : setSubsTypeId([...subsTypeId]);
                typename = typename.includes(key) ? [...typename].filter(e => e !== key) : [...typename];
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, '', 'default', countryId, subsCategoryId, typename));
            }
            searchUrl(type, typename);
        } else if (type === 'category') {
            let categoryname = [...subsCategoryId];
            if (checked) {
                subsCategoryId.includes(key) ? setSubsCategoryId([...subsCategoryId]) : setSubsCategoryId([...subsCategoryId, key]);
                categoryname = categoryname.includes(key) ? [...categoryname] : [...categoryname, key];
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, '', 'default', countryId, categoryname, subsTypeId));
            } else {
                subsCategoryId.includes(key) ? setSubsCategoryId([...subsCategoryId.filter(e => e !== key)]) : setSubsCategoryId([...subsCategoryId]);
                categoryname = categoryname.includes(key) ? [...categoryname].filter(e => e !== key) : [...categoryname];
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, '', 'default', countryId, categoryname, subsTypeId));
            }
            searchUrl(type, categoryname);
        } else if (type === 'country') {
            let countryname = [...countryId];
            if (checked) {
                countryId.includes(key) ? setCoutryId([...countryId]) : setCoutryId([...countryId, key]);
                countryname = countryname.includes(key) ? [...countryname] : [...countryname, key];
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, '', 'default', countryname, subsCategoryId, subsTypeId));
            } else {
                countryId.includes(key) ? setCoutryId([...countryId.filter(e => e !== key)]) : setCoutryId([...countryId]);
                countryname = countryname.includes(key) ? [...countryname].filter(e => e !== key) : [...countryname];
                dispatch(getSubscriptionLists(skip, itemPerPage, 1, '', 'default', countryname, subsCategoryId, subsTypeId));
            }
            searchUrl(type, countryname);
        }
    }

    const searchUrl = (name, value) => {
        let data = String(value);
        let params = new URLSearchParams(props.location.search);
        params.set(name, data);
        props.history.push({
            pathname: props.location.pathname,
            search: params.toString()
        });
    }

    const userId = sessionStorage.getItem('userId');

    let userSubscriptionList = [];
    if (subscriptionLists && subscriptionLists.length > 0) {
        userSubscriptionList = subscriptionLists.filter((item) => {
            if (userSubscription && userSubscription.length > 0) {
                return userSubscription && userSubscription.length > 0 && !userSubscription.find(list => {
                    return String(list.planId) === String(item._id);
                })
            } else {
                return subscriptionLists;
            }
        })
    } else {
        userSubscriptionList = []
    }
    return (
        <div className="polic-proced">
            <Helmet>
                <title>Policies And Procedures For Supported Living | ClouDoc</title>
                <meta name='keyword' content='Policies And Procedures, Home Care, Supported Living, Semi Independent Living, Children Home' />
                <meta name='description' content='Download our Policies And Procedures For Supported Living readily, available & compliant with CQC, helping you get ready for registration & inspections' />
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className="container">
                <div className="left-function">
                    <div className='cat-head'>
                        <p>Filter</p>
                    </div>
                    <div className="select-group">
                        <h6>Country</h6>
                        <ul>
                            {countriesList && countriesList.length > 0 && countriesList.map((item, i) => (
                                <li key={i}>
                                    <input
                                        type="checkbox"
                                        name={`country${i}`}
                                        id={item.slug}
                                        value={item.slug}
                                        onChange={(e) => policyFilter(e, 'country', item.slug)}
                                        checked={[...countryId].includes(item.slug)}
                                    // onChange={(e) => countrySelect(e, item.slug)}
                                    // checked={countryId[item.slug]}
                                    />
                                    <span className="checkmark"></span> {item.name}
                                    <img src={item.country_flag} alt={'flag'} width="25px" height="15px" />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="select-group">
                        <h6>Category</h6>
                        <ul>
                            {sub_categoryList && sub_categoryList.length > 0 && sub_categoryList.map((item, i) => (
                                <li key={i}>
                                    <input
                                        type="checkbox"
                                        name={`types${i}`}
                                        id={item.slug}
                                        value={item.slug}
                                        onChange={(e) => policyFilter(e, 'category', item.slug)}
                                        checked={[...subsCategoryId].includes(item.slug)}
                                    // onChange={(e) => subsCategorySelect(e, item.slug)}
                                    // checked={subsCategoryId[item.slug]} 
                                    />
                                    <span className="checkmark"></span>
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="select-group">
                        <h6>Type</h6>
                        <ul>
                            {sub_typeList && sub_typeList.length > 0 && sub_typeList.map((item, i) => (
                                <li key={i}>
                                    <input
                                        type="checkbox"
                                        name={`categories${i}`}
                                        id={item.slug}
                                        value={item.slug}
                                        onChange={(e) => policyFilter(e, 'type', item.slug)}
                                        checked={[...subsTypeId].includes(item.slug)}
                                    // onChange={(e) => subsTypeSelectSelect(e, item.slug)} 
                                    // checked={subsTypeId[item.slug]}
                                    />
                                    <span className="checkmark"></span>
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="right-funtion">
                    <div className="policies-head">
                        <h1>{/* <span>Policies</span> And Procedures */}</h1>
                    </div>
                    <div className="policies-top">
                        <div className="srch-sec">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="text" placeholder="Search" onChange={handleChange} />
                        </div>
                        {/* {userDetails && userDetails.settings && userDetails.settings.showPoliciesonSite ?
                            <div className='new-site-link'>
                                <a href={userDetails.settings && userDetails.settings.policiesurl} target="_blank">Go to company website</a>
                            </div> : null} */}
                        <div className="list-grid">
                            <button onClick={() => setListView(false)} className={!listView ? 'active' : ''}><i className="fa fa-th" aria-hidden="true"></i></button>
                            <button onClick={() => setListView(true)} className={listView ? 'active' : ''}><i className="fa fa-list" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    {!listView ? (
                        <div className="right-part-policy">
                            <Row>
                                {pageLoader === false ? <div className="progress-ani fixed-bar"></div> : null}
                                {userId !== '' && userId !== null && userId !== undefined ?
                                    userSubscriptionList && userSubscriptionList.length > 0 && pageLoader === true ?
                                        userSubscriptionList.map((item, i) => {
                                            const regex = /(<([^>]+)>)/ig;
                                            let description = item.description && String(item.description).length > 0 && String(item.description) !== String(undefined) && String(item.description) !== String(null) ? item.description.replace(regex, '') : '';
                                            if (description) {
                                                let d = document.createElement('p');
                                                d.innerHTML = item.description;
                                                description = d.innerText;
                                            }
                                            return (
                                                <Col md='6' lg="4" key={i}>
                                                    <div className="policy-parts-block new-parts-block" onClick={() => props.history.push(`/subscription-detail/${item.slug}`)} style={{ cursor: 'pointer' }}>
                                                        <span className="sub-img"><img src={item.subscription_image} alt={item.name} /><span className="country-flag"><img src={item && item.country && item.country.flag ? item.country.flag : 'https://www.cloudoc.co.uk/uploads/images/flags/1638789034009.png'} alt={item && item.country && item.country.name} /></span></span>
                                                        <div className="policy-level">
                                                            <LightTooltip TransitionComponent={Zoom} title={item.name} placement="bottom">
                                                                <h2>{item.name}</h2>
                                                            </LightTooltip>
                                                            <p><span className="pol-img"><img src={list_icon} alt='icon' /><img src={list_icon_hover} alt='icon' className="hover" /></span>{item.files ? item.files : 0} Documents included</p>
                                                            <p><span className="pol-img"><img src={list_icon_2} alt='icon' /><img src={list_icon_2_hover} alt='icon' className="hover" /></span> Updates Available</p>
                                                        </div>
                                                        {/* <span className="offers">Offer Ends at 01/01/2022</span> */}
                                                        <div className="policy-parts-bottom">
                                                            <span className='price-sec'>
                                                                <p className="text-from">From</p>
                                                                {item.hasDiscountOffer === 1
                                                                    ? (
                                                                        <>
                                                                            <span className="offers">{`Offer Ends at ${settingsData && settingsData.offerExpiry && checkValue(settingsData.offerExpiry) ? moment(settingsData.offerExpiry).format('DD/MM/YYYY') : null}`}</span>
                                                                            <b className='discount-style'>£</b>
                                                                            <span className='main-amount discount-style'>{(Number(item.licenseFee) - Number(item.licenseFee * ((settingsData.packageDiscountPercent) / 100))).toFixed(2).split('.')[0]}</span>
                                                                            <b className='discount-style'>.</b>
                                                                            <b className='discount-style'>{(Number(item.licenseFee) - Number(item.licenseFee * ((settingsData.packageDiscountPercent) / 100))).toFixed(2).split('.')[1]}</b>
                                                                            <span className='original-price'>£{item.licenseFee ? Number(item.licenseFee).toFixed(2) : null}</span>
                                                                        </>
                                                                    )
                                                                    : <span><b>£</b>{parseFloat(item.licenseFee).toFixed(2).includes('.') ? parseFloat(item.licenseFee).toFixed(2).split('.')[0] : parseFloat(item.licenseFee)}<b>{parseFloat(item.licenseFee).toFixed(2).includes('.') ? '.' + parseFloat(item.licenseFee).toFixed(2).split('.')[1] : '.00'}</b></span>}
                                                            </span>
                                                            <div className="view-details">View Details</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                        : showNoData && <p style={{ textAlign: 'center', width: '100%' }}>No Subscription Found</p>
                                    : subscriptionLists && subscriptionLists.length > 0 && pageLoader === true ?
                                        subscriptionLists.map((item, i) => {
                                            const regex = /(<([^>]+)>)/ig;
                                            let description = item.description && String(item.description).length > 0 && String(item.description) !== String(undefined) && String(item.description) !== String(null) ? item.description.replace(regex, '') : '';
                                            if (description) {
                                                let d = document.createElement('p');
                                                d.innerHTML = item.description;
                                                description = d.innerText;
                                            }
                                            return (
                                                <Col md='6' lg="4" key={i}>
                                                    <div className="policy-parts-block new-parts-block" key={i} onClick={() => props.history.push(`/subscription-detail/${item.slug}`)} style={{ cursor: 'pointer' }}>
                                                        <span className="sub-img"><img src={item.subscription_image} alt={item.name} /><span className="country-flag"><img src={item && item.country && item.country.flag ? item.country.flag : 'https://www.cloudoc.co.uk/uploads/images/flags/1638789034009.png'} alt={item && item.country && item.country.name} /></span></span>
                                                        <div className="policy-level">
                                                            {/* <Tooltip title={item.name} placement="top-start">{item.name}</Tooltip></h2> */}
                                                            <LightTooltip TransitionComponent={Zoom} title={item.name} placement="bottom">
                                                                <h2>{item.name}</h2>
                                                            </LightTooltip>
                                                            <p><span className="pol-img"><img src={list_icon} alt='icon' /><img src={list_icon_hover} alt='icon' className="hover" /></span>{item.files ? item.files : 0} Documents included</p>
                                                            <p><span className="pol-img"><img src={list_icon_2} alt='icon' /><img src={list_icon_2_hover} alt='icon' className="hover" /></span> Updates Available</p>
                                                        </div>
                                                        <div className="policy-parts-bottom">
                                                            <span className='price-sec'>
                                                                <p className="text-from">From</p>
                                                                {item.hasDiscountOffer === 1
                                                                    ? (
                                                                        <>
                                                                            <span className="offers">{`Offer Ends at ${settingsData && settingsData.offerExpiry && checkValue(settingsData.offerExpiry) ? moment(settingsData.offerExpiry).format('DD/MM/YYYY') : null}`}</span>

                                                                            <b className='discount-style'>£</b>
                                                                            <span className='main-amount discount-style'>{(Number(item.licenseFee) - Number(item.licenseFee * ((settingsData.packageDiscountPercent) / 100))).toFixed(2).split('.')[0]}</span>
                                                                            <b className='discount-style'>.</b>
                                                                            <b className='discount-style'>{(Number(item.licenseFee) - Number(item.licenseFee * ((settingsData.packageDiscountPercent) / 100))).toFixed(2).split('.')[1]}</b>
                                                                            <span className='original-price'>£{item.licenseFee ? Number(item.licenseFee).toFixed(2) : null}</span>

                                                                        </>
                                                                    )
                                                                    : <span><b>£</b>{parseFloat(item.licenseFee).toFixed(2).includes('.') ? parseFloat(item.licenseFee).toFixed(2).split('.')[0] : parseFloat(item.licenseFee)}<b>{parseFloat(item.licenseFee).toFixed(2).includes('.') ? '.' + parseFloat(item.licenseFee).toFixed(2).split('.')[1] : '.00'}</b></span>}
                                                            </span>

                                                            <div className="view-details">View Details</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                        : showNoData && <p style={{ textAlign: 'center', width: '100%' }}>No Subscription Found</p>}
                            </Row>
                        </div>
                    ) : (
                        <div className="polici-list-viwe">
                            {pageLoader === false ? <div className="progress-ani fixed-bar"></div> : null}
                            {userId !== '' && userId !== null && userId !== undefined ?
                                userSubscriptionList && userSubscriptionList.length > 0 && pageLoader === true ?
                                    userSubscriptionList.map((item, i) => (
                                        <div className="polici-list-part" key={i} >
                                            <span className="list-img"><img src={item.subscription_image} alt={item.name} /></span>
                                            <div className="list-flag">
                                                <img src={item && item.country && item.country.flag ? item.country.flag : 'https://www.cloudoc.co.uk/uploads/images/flags/1638789034009.png'} alt={item && item.country && item.country.name} />
                                                <h3>{item.name}</h3>
                                            </div>
                                            <span className="pol-doc">
                                                <label>Documents</label>
                                                <p>{item.files ? item.files : 0}</p>
                                            </span>
                                            <span className="pol-doc">
                                                <label>Updates</label>
                                                <p>Available</p>
                                            </span>
                                            <span className="pol-doc">
                                                <label>From</label>
                                                <p className="price-tag">£{parseFloat(item.licenseFee).toFixed(2)}</p>
                                            </span>
                                            <Link onClick={() => props.history.push(`/subscription-detail/${item.slug}`)}>View Details</Link>
                                        </div>
                                    )) : showNoData && <p style={{ textAlign: 'center', width: '100%' }}>No Subscription Found</p>
                                : subscriptionLists && subscriptionLists.length > 0 && pageLoader === true ?
                                    subscriptionLists.map((item, i) => (
                                        <div className="polici-list-part" key={i} >
                                            <span className="list-img"><img src={item.subscription_image} alt={item.name} /></span>
                                            <div className="list-flag">
                                                <img src={item && item.country && item.country.flag ? item.country.flag : 'https://www.cloudoc.co.uk/uploads/images/flags/1638789034009.png'} alt={item && item.country && item.country.name} />
                                                <h3>{item.name}</h3>
                                            </div>
                                            <span className="pol-doc">
                                                <label>Documents</label>
                                                <p>{item.files ? item.files : 0}</p>
                                            </span>
                                            <span className="pol-doc">
                                                <label>Updates</label>
                                                <p>Available</p>
                                            </span>
                                            <span className="pol-doc">
                                                <label>From</label>
                                                <p className="price-tag">£{parseFloat(item.licenseFee).toFixed(2)}</p>
                                            </span>
                                            <Link onClick={() => props.history.push(`/subscription-detail/${item.slug}`)}>View Details</Link>
                                        </div>
                                    ))
                                    : showNoData && <p style={{ textAlign: 'center', width: '100%' }}>No Subscription Found</p>}


                        </div>
                    )}


                </div>
                <div className="fixed-phone">
                    <div className="fixed-phone-sec">
                        <a href="tel:0330 808 0050"><img src={fixedphone} alt='phone' /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionList;