import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { restoreModalView } from '../../../actions/uiAction';
import './index.css';
import { useDispatch, useSelector } from 'react-redux'
import BottomModal from "../bottommodal";

const RestoreModal = (props) => {
    let dispatch = useDispatch(),
        showRestoreModal = useSelector(state => state.UIReducer.showRestoreModal),
        pop_show_hide = useSelector(state => state.UIReducer.pop_show_hide)

    return (
        <div>
            <Modal isOpen={showRestoreModal} centered={true} toggle={(e) => dispatch(restoreModalView())} backdrop="static" className="restore-mod">
                <ModalHeader toggle={(e) => dispatch(restoreModalView())}>Confirm Restore</ModalHeader>
                <ModalBody>
                    <p className="delete_chg"><span>Warning:</span> By clicking the restore button, you acknowledge and agree that the document will be updated to the version we have on the admin side of the system. Any changes or modifications made before this point in time will be permanently lost. Please review and make a copy of any recent changes or updates before proceeding. ClouDoc and its affiliates cannot be held responsible for any loss of data. Regularly backing up your documents is strongly recommended.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={(e) => dispatch(restoreModalView())}>Cancel</Button>{' '}
                    <Button type="submit" color="success" onClick={(e) => props.restoreFunction()}>Restore</Button>
                </ModalFooter>
            </Modal>
            {pop_show_hide ? <BottomModal history={props.history} /> : null}
        </div>
    )

}

export default RestoreModal;