import * as actionTypes from '../actions/types.js';
// import CONFIG from '../config.js';
import { NodeURL } from '../services/index.js';

export default (state = { listData: [], editViewData: {}, addViewData: {}, validationError: {} }, action) => {


    switch (action.type) {

        case actionTypes.TEMPLATE_LIST:
            return {
                ...state,
                listData: [...state.listData, ...action.payload]
            };

        case actionTypes.CLEAR_TEMPLATE_LIST:
            return {
                ...state,
                listData: [...action.payload]
            };

        case actionTypes.EDIT_VIEW:
            return {
                ...state,
                editViewData: { ...state.editViewData, ...action.payload }
            };

        case actionTypes.ADD_VIEW:
            return {
                ...state,
                addViewData: { ...state.addViewData, ...action.payload }
            };

        case actionTypes.CLEAR_ADD_VIEW:
            return {
                ...state,
                addViewData: { ...action.payload }
            };

        case actionTypes.TEMPLATE_EDIT:
            return {
                ...state,
                editViewById: { ...state.editViewById, ...action.payload }
            };

        case actionTypes.TEMPLATE_DELETE_MODAL:
            return {
                ...state,
                showTemplateDeleteModal: !state.showTemplateDeleteModal,
                deleteTemplateId: action.templateId,
                listData: state.listData.filter(e => e._id !== action.templateId)
            };

        case actionTypes.ERROR_MSG:
            return {
                ...state,
                validationError: action.payload
            };
        case actionTypes.TEMPLATE_VIEW:
            if (action.payload.templateImage) {
                action.payload.imagePath = NodeURL + '/' + action.payload.templateImage;
            }
            return {
                ...state,
                viewData: action.payload
            };
        case actionTypes.TEMPLATE_PREVIEW:
            return {
                ...state,
                showPreviewModal: !state.showPreviewModal,
                templateId: action.payload,
            }
        default:
            return state;
    }
};
