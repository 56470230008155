import * as actionTypes from './types';
import fetchData from '../services';


export const addedToCart = (packageId, userId, props, sendtype, method, packages = [], renewal = {}, instantBuyUpdate = false, licencetype) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/add-to-cart', method: 'POST', data: { 'packageId': packageId, 'userId': userId, sendtype, packages: packages, renewalPlan: JSON.stringify(renewal), instantBuyUpdate: String(instantBuyUpdate), licencetype } });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        dispatch({ type: actionTypes.SETDETAILSMETHOD, payload: method });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            dispatch({ type: actionTypes.SETDETAILSMETHOD, payload: '' });
            let name = (String(sendtype) === String('subscription')) ? 'Subscription' : 'Package';
            if (res.status === 1) {
                props.history.push("/checkout");
                let addonPackage = {};
                addonPackage['selected_addon'] = packages;
                addonPackage['plantype'] = renewal;
                sessionStorage.setItem('selected_addon', JSON.stringify(addonPackage))
                let response = fetchData({ url: '/user/cart-count', method: 'POST', data: { 'userId': userId } });
                response.then(res => {
                    if (res.status === 1) {
                        dispatch({ type: actionTypes.CART_COUNT, payload: res.response });
                    }
                });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: name + " successfully added in cart", alignment: "centre" }
                });
            } else if (res.status === 2) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: name + " already added in cart", alignment: "centre" }
                })
            } else if (res.status === 3) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Already subscribed to this package', alignment: "centre" }
                })
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const OFFLINE_CART = (subscriptionDetails, selected_package, renewalType, settingsData, licenceType) => {
    return (dispatch) => {
        if (subscriptionDetails && subscriptionDetails.folders) {
            delete subscriptionDetails.folders;
        }
        if (subscriptionDetails && subscriptionDetails.createdAt) {
            delete subscriptionDetails.createdAt;
        }
        if (subscriptionDetails && subscriptionDetails.updatedAt) {
            delete subscriptionDetails.updatedAt;
        }
        if (subscriptionDetails && subscriptionDetails.file) {
            delete subscriptionDetails.file;
        }
        if (subscriptionDetails && subscriptionDetails.images) {
            delete subscriptionDetails.images;
        }

        let local_cart = [], obj = {};
        obj['id'] = subscriptionDetails && subscriptionDetails._id && subscriptionDetails._id !== undefined ? subscriptionDetails._id : '';
        obj['name'] = subscriptionDetails && subscriptionDetails.name && subscriptionDetails.name !== undefined ? subscriptionDetails.name : '';
        obj['price'] = (subscriptionDetails && subscriptionDetails.hasDiscountOffer && settingsData && settingsData && settingsData.packagediscountStatus) ? (+subscriptionDetails.licenseFee - (+subscriptionDetails.licenseFee * +settingsData.packageDiscountPercent) / 100) : +subscriptionDetails.licenseFee;
        obj['slug'] = subscriptionDetails && subscriptionDetails.slug && subscriptionDetails.slug !== undefined ? subscriptionDetails.slug : '';
        obj['images'] = subscriptionDetails && subscriptionDetails.subscription_image && subscriptionDetails.subscription_image !== undefined ? subscriptionDetails.subscription_image : '';
        obj['description'] = subscriptionDetails && subscriptionDetails.description && subscriptionDetails.description !== undefined ? subscriptionDetails.description : '';
        obj['selectedPackages'] = selected_package;
        obj['updatePlanPurchase'] = selected_package && selected_package.length > 0 && selected_package.includes('updates') ? 'instant' : '-';
        obj['sendtype'] = 'subscription';
        obj['quantity'] = 1;
        obj['planId'] = subscriptionDetails && subscriptionDetails._id && subscriptionDetails._id !== undefined ? subscriptionDetails._id : '';
        obj['renewalPlan'] = renewalType ? renewalType : '';
        obj['licenceType'] = licenceType ? licenceType : '';
        if (selected_package && selected_package.length > 0 && renewalType) {
            let addonPackage = {};
            addonPackage['selected_addon'] = selected_package;
            addonPackage['plantype'] = renewalType;
            sessionStorage.setItem('selected_addon', JSON.stringify(addonPackage));
            localStorage.setItem('selected_addon', JSON.stringify(addonPackage));

            obj['packagePlanType'] = renewalType;
            if (selected_package.includes('updates') && renewalType && (renewalType === 'monthly' || renewalType === 'trial')) {
                obj['monthlyUpdates'] = 1;
            } else if (selected_package.includes('updates') && renewalType && renewalType === 'yearly') {
                obj['yearlyUpdates'] = 1;
            } else {
                obj['yearlyUpdates'] = 0;
                obj['monthlyUpdates'] = 0;
            }
            if (selected_package.includes('management') && renewalType && (renewalType === 'monthly' || renewalType === 'trial')) {
                obj['monthlyManagement'] = 1
            } else if (selected_package.includes('management') && renewalType && renewalType === 'yearly') {
                obj['yearlyManagement'] = 1
            } else {
                obj['yearlyManagement'] = 0;
                obj['monthlyManagement'] = 0;
            }
            if (selected_package.includes('combo') && renewalType && (renewalType === 'monthly' || renewalType === 'trial')) {
                obj['monthlyCombo'] = 1
            } else if (selected_package.includes('combo') && renewalType && renewalType === 'yearly') {
                obj['yearlyCombo'] = 1
            } else {
                obj['yearlyCombo'] = 0;
                obj['monthlyCombo'] = 0;
            }
            if (selected_package.includes('pro') && renewalType && (renewalType === 'monthly' || renewalType === 'trial')) {
                obj['monthlyPro'] = 1
            } else if (selected_package.includes('pro') && renewalType && renewalType === 'yearly') {
                obj['yearlyPro'] = 1
            } else {
                obj['yearlyPro'] = 0;
                obj['monthlyPro'] = 0;
            }
            if (selected_package.includes('printing')) {
                obj['printing'] = 1;
            } else {
                obj['printing'] = 0;
            }
        }

        let managementTotal = 0, updatesTotal = 0, comboTotal = 0,proTotal=0, printingTotal = 0;
        updatesTotal = selected_package.includes('updates') && renewalType === 'monthly' ? +settingsData.monthlyRenewalFee : selected_package.includes('updates') && renewalType === 'yearly' ? +settingsData.yearlyRenewalFee : 0;
        managementTotal = selected_package.includes('management') && renewalType === 'monthly' ? +settingsData.monthlyManagementFee : selected_package.includes('management') && renewalType === 'yearly' ? +settingsData.yearlyManagementFee : 0;
        comboTotal = selected_package.includes('combo') && renewalType === 'monthly' ? +settingsData.monthlyComboFee : selected_package.includes('combo') && renewalType === 'yearly' ? +settingsData.yearlyComboFee : 0;
        proTotal = selected_package.includes('pro') && renewalType === 'monthly' ? +settingsData.monthlyProFee : selected_package.includes('pro') && renewalType === 'yearly' ? +settingsData.yearlyProFee : 0;
        printingTotal = selected_package.includes('printing') ? +settingsData.printingFee : 0;

        obj['total'] = Number(obj.price) + updatesTotal + managementTotal + comboTotal +proTotal + printingTotal;
        local_cart = [...local_cart, obj];

        let get_offline_cart = localStorage.getItem('offline_cart_subscription_details');
        if (get_offline_cart && String(get_offline_cart) !== String(null) && String(get_offline_cart) !== String(undefined) && String(get_offline_cart).length > 0) {
            let parsed_cart = JSON.parse(get_offline_cart),
                index = [...parsed_cart].findIndex(e => String(e._id) === String(subscriptionDetails._id));
            if (+index === -1) {
                local_cart = [...local_cart, ...parsed_cart];
                localStorage.setItem('offline_cart_subscription_details', JSON.stringify(local_cart))
                if (sessionStorage.employeePlan) {
                    let employeePlan = sessionStorage.getItem('employeePlan')
                    localStorage.setItem('offline_cart_employeePlan', employeePlan)
                }
                dispatch({ type: actionTypes.OFF_LINE_CART_DATA, payload: local_cart })
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: subscriptionDetails.name + " successfully added in cart", alignment: "centre" }
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: subscriptionDetails.name + " already added in cart", alignment: "centre" }
                });
            }
        } else {
            localStorage.setItem('offline_cart_subscription_details', JSON.stringify(local_cart))
            dispatch({ type: actionTypes.OFF_LINE_CART_DATA, payload: local_cart })
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: subscriptionDetails.name + " successfully added in cart", alignment: "centre" }
            });
        }
    }
}

export const buyDocuments = (packageId, userId, props, sendtype, method) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/add-to-cart', method: 'POST', data: { 'packageId': packageId, 'userId': userId, sendtype } });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        dispatch({ type: actionTypes.SETDETAILSMETHOD, payload: method });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            dispatch({ type: actionTypes.SETDETAILSMETHOD, payload: '' });
            let name = (String(sendtype) === String('subscription')) ? 'Subscription' : 'Package';
            if (res.status === 1) {
                let cartCount = parseInt(props.cartCount) + parseInt(1);
                dispatch({ type: actionTypes.CART_COUNT, payload: cartCount });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: name + " successfully added in cart", alignment: "centre" }
                });
                cartCount === 1 && method === "buy" && props.history.push("/checkout")
            } else if (res.status === 2) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: name + " already added in cart", alignment: "centre" }
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const getCartDetails = (userId) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
        let response = fetchData({ url: '/user/get-cart-details', method: 'POST', data: { 'userId': userId } });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
            if (res.status === 1) {
                dispatch({ type: actionTypes.VIEW_CART_DETAILS, payload: res.response });
            }
        });
    }
}

export const removeItemFromCart = (index, packageId, userId, props, sendtype) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/remove-cart-item', method: 'POST', data: { 'userId': userId, 'packageId': packageId, sendtype } });
        response.then(res => {
            if (res.status === 1) {
                let cartCount = parseInt(props.cartCount) - parseInt(1);
                dispatch({ type: actionTypes.REMOVE_CART_ITEM, payload: index });
                dispatch({ type: actionTypes.CART_COUNT, payload: cartCount });
                dispatch({ type: actionTypes.DELETE_MODAL_VIEW, payload: {} });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Package successfully removed from cart', alignment: "centre" }
                });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const paymentFormData = (paymentData) => {
    return {
        type: actionTypes.CHECTOUT_DETAILS,
        payload: paymentData
    }
}
export const deleteModal = () => {
    return {
        type: actionTypes.DELETE_MODAL_VIEW,
        payload: {}
    }
}

export const deleteSavedCard = (id) => {
    let formData = {
        card_id: id
    }
    return (dispatch) => {
        let response = fetchData({ url: '/users/details/delete/payment', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
            }
            else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
            }
        })
    }
}

export const afterSuccessPayment = (data) => {
    return (dispatch) => {
        let userId = String(sessionStorage.getItem('userId'));
        let paymentDetails = JSON.stringify(data.paymentData);
        let selectedPackages = JSON.stringify(sessionStorage.getItem('selected_addon'));
        let subscription_id = data.subscription_id && String(data.subscription_id).length > 0 ? data.subscription_id : '';
        let document_licence_type = data.document_licence_type ? data.document_licence_type : '';
        let employeePlan =  data.employeePlan?  data.employeePlan : '';
        let request = fetchData({ url: `/users/details-update/payment/success?userId=${(userId)}&paymentDetails=${paymentDetails}&selectedPackages=${selectedPackages}&orderId=${String(data.orderId)}&subscription_id=${subscription_id}&document_licence_type=${document_licence_type}&employeePlan=${employeePlan}`, method: 'GET' });
        request.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: actionTypes.CART_COUNT, payload: 0 });
                dispatch({ type: actionTypes.PAYMENT_SUCCESS_POPUP, payload: {} });
                sessionStorage.setItem('profileType', 'companydetail');
                sessionStorage.removeItem('employeePlan');
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Payment Successfully Completed.', alignment: "centre" }
                });
                dispatch({
                    type: actionTypes.SHOW_TOP_POP_MODAL,
                    payload: { pop_show: true, message: 'Thank you for purchasing your documents, your documents are now available, you can access them by clicking the Documents menu, in order to customise them, please click here to complete you business information as you would like it to appear on the documents.' }
                });
                dispatch({ type: actionTypes.SHOW_REVIEW_MODAL, payload: data.Comp_details });
                // data.Comp_details && data.Comp_details < 12 ? data.history.push('/profile') : data.history.push("/documents");
                sessionStorage.removeItem('selected_addon');
            }

        }).catch(err => console.log('error', err))

    }

}

export const afterFailurePayment = (data) => {
    return (dispatch) => {
        let userId = String(sessionStorage.getItem('userId'));
        let response = fetchData({ url: `/users/details-update/payment/failure?userId=${(userId)}&orderId=${String(data.orderId)}`, method: 'GET' });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Payment Failed.', alignment: "centre" }
                });
            }
        }).catch(err => console.log(err))

        data.history.push('/cart');
    }
}

export const checkoutPay = (props) => {
    //     let payment = props && props.paymentData ? props.paymentData : {};  

    //     let formData = {
    //         userId: sessionStorage.getItem('userId'),
    //         token: sessionStorage.getItem('token'),
    //         payment: JSON.stringify(payment),
    //         total: props.total,
    //         selectedPackages: JSON.stringify(sessionStorage.getItem('selected_addon'))       
    //     }

    //     return (dispatch) => {
    //         let response = fetchData('/user/checkout-pay', 'post', formData);
    //         dispatch({ type: actionTypes.BUTTON_PENDING });
    //         response.then(res => {
    //             dispatch({ type: actionTypes.BUTTON_SUCCESS });
    //             if (res.status === 1) {
    //                 dispatch({ type: actionTypes.CART_COUNT, payload: 0 });
    //                 dispatch({ type: actionTypes.PAYMENT_SUCCESS_POPUP, payload: {} });
    //                 sessionStorage.setItem('profileType', 'companydetail');
    //                 dispatch({
    //                     type: actionTypes.BOTTOM_T_W_AL,
    //                     payload: { show_hide: true, text: 'Payment Successfully Completed.', alignment: "centre" }
    //                 });
    //                 dispatch({
    //                     type: actionTypes.SHOW_TOP_POP_MODAL,
    //                     payload: { pop_show: true, message: 'Thank you for purchasing your documents, your documents are now available, you can access them by clicking the Documents menu, in order to customise them, please click here to complete you business information as you would like it to appear on the documents.' }
    //                 });
    //                 props.Comp_details && props.Comp_details < 12 ? props.history.push('/profile') : props.history.push("/documents");
    //                 sessionStorage.removeItem('selected_addon');
    //             } else if (res.status === 2) {
    //                 if (typeof res.response === 'object') {
    //                     dispatch({
    //                         type: actionTypes.BOTTOM_T_W_AL,
    //                         payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
    //                     });
    //                 } else {
    //                     dispatch({
    //                         type: actionTypes.BOTTOM_T_W_AL,
    //                         payload: { show_hide: true, text: res.response, alignment: "centre" }
    //                     });
    //                 }
    //                 props.history.push('/cart');
    //             } else {
    //                 if (typeof res.response === 'object') {
    //                     dispatch({
    //                         type: actionTypes.BOTTOM_T_W_AL,
    //                         payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
    //                     });
    //                 } else {
    //                     dispatch({
    //                         type: actionTypes.BOTTOM_T_W_AL,
    //                         payload: { show_hide: true, text: res.response, alignment: "centre" }
    //                     });
    //                 }
    //                 props.history.push("/cart")
    //             }
    //         });
    //     }
}

export const paymentSuccessModal = () => {
    return {
        type: actionTypes.PAYMENT_SUCCESS_POPUP,
        payload: {}
    }
}

export const getOrderData = (skip, limit, pageNumber, userId, type, package_duration_filter = '', searchText = '', sort_by = '') => {
    return (dispatch) => {
        dispatch({ type: actionTypes.ORDER_PAGINATION, page_no: pageNumber, statusType: type, duration_filter: package_duration_filter });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        let response = fetchData({ url: '/user/order-lists', method: 'POST', data: { 'userId': userId, 'skip': skip, 'limit': limit, 'type': type, "order_created": package_duration_filter, search: searchText, field: sort_by } });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.ORDER_VIEW_DATA,
                    payload: res.response,
                    total: res.total,
                    success: res.success,
                    failed: res.failed,
                    list_type: type,
                });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const clear_filtered_data = () => {
    return {
        type: actionTypes.ORDER_PAGINATION, page_no: 0, statusType: 'all', duration_filter: ''
    }
}

export const getOrderDetails = (userId, orderId) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/order-details', method: 'POST', data: { 'userId': userId, orderId: orderId } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.ORDER_DETAILS_DATA,
                    payload: res.response,
                });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const getPageLists = () => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/page-lists', method: 'POST', data: {} });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.PAGES_LIST_FOOTER,
                    payload: res.response,
                });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const viewPage = (pageSlug) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/page-details', method: 'POST', data: { 'slug': pageSlug } });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.PAGE_DETAILS_DATA,
                    payload: res.response,
                });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const CLEAR_CART_DETAILS = () => {
    return {
        type: actionTypes.CLEAR_CART_DETAILS,
        payload: []
    }
}

export const IMPORT_FROM_LOCAL = arr => {
    return (dispatch) => {
        dispatch({ type: actionTypes.OFF_LINE_CART_DATA, payload: arr });
    };
}

export const remove_off_line_cart = (new_arr) => {
    return (dispatch) => {
        localStorage.setItem('offline_cart_subscription_details', JSON.stringify(new_arr))
        dispatch({ type: actionTypes.OFF_LINE_CART_DATA, payload: new_arr });
        dispatch({ type: actionTypes.DELETE_MODAL_VIEW, payload: {} });
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'Package successfully removed from cart', alignment: "centre" }
        });
    };
}

export const show_bottom_modal = (msg) => {
    return {
        type: actionTypes.BOTTOM_T_W_AL,
        payload: { show_hide: true, text: msg, alignment: "centre" }
    }
}
export const purchaseSuccess = (userId,history) => {
    return async (dispatch)  => {
    let response = await fetchData({ url: '/user/removeCart', method: 'POST', data: { "userId": userId} });
    dispatch(show_bottom_modal('Payment successful'))
    dispatch(purchaseModal())
    }
}
export const purchaseModal = () => {
    return {
        type: actionTypes.PURCHASE_MODAL
    }
}

export const show_top_modal = () => {
    return {
        type: actionTypes.SHOW_TOP_POP_MODAL,
        payload: { pop_show: true }
    }
}
export const dismiss_msg_pop = () => {
    return {
        type: actionTypes.DISMISS_MSG_POP,
        payload: true
    }
}

export const payViasavedCard = (id, cond) => {
    return {
        type: actionTypes.USE_SAVED_CARD,
        card_id: id,
        payload: cond
    }
}

export const setDefaultCard = (cardId, customerId) => {
    return (dispatch) => {
        let response = fetchData({ url: `/user/set/defaultcard?cardid=${cardId}&customerid=${customerId}`, method: 'GET' });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
            if (res.status === 1) {
                dispatch({
                    type: 'VIEW_USER_DETAILS',
                    payload: res.userData
                });
                dispatch({
                    type: actionTypes.ADD_MYPROFILE,
                    payload: res.userData
                });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}
export const updateCart = (productId, type, packageValue, subscriptionId) => {
    return (dispatch) => {
        let addonPackage = {};
        addonPackage['selected_addon'] = [packageValue];
        addonPackage['plantype'] = type;
        sessionStorage.setItem('selected_addon', JSON.stringify(addonPackage))
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
        let response = fetchData({ url: '/user/update-cart', method: 'POST', data: { "id": productId, "package": packageValue, "plantype": type, "packageId": subscriptionId } });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
            if (res.status === 1) {
                dispatch({ type: actionTypes.VIEW_CART_DETAILS, payload: res.response });
            }
            else {
                console.log("Something went wrong")
            }
        })
    }
}
export const reviewDateModalToggle = () => {
    return {
        type: actionTypes.SHOW_REVIEW_MODAL
    }
}
export const changeReviewDate = (date) => {
    return {
        type: actionTypes.SET_REVIEW_DATE,
        payload: date ? date : null
    }
}
export const updateReview = (comDetails, history, date) => {
    return async (dispatch) => {
        const userId = sessionStorage.getItem('userId')
        let reviewDate = null
        if (date) {
            reviewDate = date
        }
        let formatData = { date: reviewDate, userId }
        const res = await fetchData({ url: '/user/document/set/reviewDate', method: 'POST', data: formatData })
        if (res.status === 1) {
            comDetails && comDetails < 12 ? history.push('/profile') : history.push("/documents")
        }
        else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

