import * as actionTypes from '../actions/types.js';

export default (state = {
    useTemplateModal: false, myfilesData: [], myfilessharedData: [], createFolderModal: false, folderOptions: [], myfilesAddButton: true,
    createMyfilseFolderModal: false, subFolderOneOption: [], subFolderTwoOption: [], subFolderThreeOption: [], subFolderFourOption: [],
    subFolderFiveOption: [], subFolderSixOption: [], myFilesrootPath: [], myFilesRootName: [], myFilesrootName: [], myfilesRenameModal: false,
    myfilesRenameFolder: {}, myfileCreateModal: false, myFilesOptions: [], myFilesMoveModal: false, fileDetails: {}, sendModal: false,
    editorContent: '', veiwModal: false,SignveiwModal:false, viewContent: '', folderStrct: [], deleteModal: false, optionType: '', folderMoveModal: false, optionKey: '',
    selectedOptionId: '', modalRootName: [], buttonChange: true, blankDoc: false, draggingDetails: {}, dropDetails: {}, confirmModal: false, sentList: [], sentCount: 0,
    limit: 25, skip: 0, activePage: 1, uploadFileModal: false, selectedUrl: '', pdfLink: '', pdfViewModal: false, myfileswaitingData: [], myfilesviewedData: [], myfilesfinalizedData: [],myfilesTotalData:[],myfilesDeletedFolderData:[],myfilesDeletedFileData:[],myfilesTimelineData:[],recipientsModal:false
}, action) => {
    switch (action.type) {
        case actionTypes.USE_TEMPLATE_MODAL:
            return {
                ...state,
                useTemplateModal: !state.useTemplateModal,
            }
        case actionTypes.MYFILES_ALL_DATA:
            return {
                ...state,
                myfilesData: action.payload,
                myFilesRootName: action.root
            }
        case actionTypes.CREATE_FOLDER_MODAL:
            return {
                ...state,
                createFolderModal: !state.createFolderModal,
                selectedOptionId: action.payload ? action.payload : ''
            }
        case actionTypes.MYFILES_FOLDERS_LIST:
            return {
                ...state,
                folderOptions: action.payload
            }
        case actionTypes.ADD_MYFILES_FOLDER:
            return {
                ...state,
                myfilesAddButton: action.payload
            }
        case actionTypes.CREATE_MYFILES_FOLDER_MODAL:
            return {
                ...state,
                createMyfilseFolderModal: !state.createMyfilseFolderModal
            }
        case actionTypes.MAIN_SUBFOLDERS:
            return {
                ...state,
                subFolderOneOption: action.payload
            }
        case actionTypes.SUB_ONE_SUBFOLDERS:
            return {
                ...state,
                subFolderTwoOption: action.payload
            }
        case actionTypes.SUB_TWO_SUBFOLDERS:
            return {
                ...state,
                subFolderThreeOption: action.payload
            }
        case actionTypes.SUB_THREE_SUBFOLDERS:
            return {
                ...state,
                subFolderFourOption: action.payload
            }
        case actionTypes.SUB_FOUR_SUBFOLDERS:
            return {
                ...state,
                subFolderFiveOption: action.payload
            }
        case actionTypes.SUB_FIVE_SUBFOLDERS:
            return {
                ...state,
                subFolderSixOption: action.payload
            }
        case actionTypes.MYFILES_ROOT_ARRAY:
            return {
                ...state,
                myFilesrootPath: action.payload ? action.payload : []
            }
        case actionTypes.MYFILES_RENAME_MODAL:
            return {
                ...state,
                myfilesRenameModal: !state.myfilesRenameModal
            }
        case actionTypes.MYFILES_RENAME_FOLDERNAME:
            return {
                ...state,
                myfilesRenameFolder: action.payload
            }
        case actionTypes.MYFILES_FILE_CREATE_MODAL:
            return {
                ...state,
                myfileCreateModal: !state.myfileCreateModal,
                selectedOptionId: action.payload ? action.payload : '',
                blankDoc: action.payloadTwo
            }
        case actionTypes.MYFILE_FILE_OPTIONS:
            return {
                ...state,
                myFilesOptions: action.payload
            }
        case actionTypes.MYFILE_MOVE_MODAL:
            return {
                ...state,
                myFilesMoveModal: !state.myFilesMoveModal
            }
        case actionTypes.MYFILES_EDITOR_FILE:
            return {
                ...state,
                fileDetails: action.payload
            }
        case actionTypes.TOGGLE_MYFILES_SEND_MODAL:
            return {
                ...state,
                sendModal: !state.sendModal,
                selectedUrl: action.payload
            }
        case actionTypes.MYFILES_RECIPIENTS_MODAL:
            return {
                ...state,
                recipientsModal: !state.recipientsModal,
            }
        case actionTypes.MYFILES_EDITOR_CONTENT:
            return {
                ...state,
                editorContent: action.payload ? action.payload : sessionStorage.myfilesContent ? sessionStorage.myfilesContent : ''
            }
        case actionTypes.TOGGLE_MYFILES_VIEW_MODAL:
            return {
                ...state,
                veiwModal: !state.veiwModal,
                viewContent: action.payload ? action.payload : ''
            }
        case actionTypes.TOGGLE_MYFILES_SIGN_VIEW_MODAL:
            return {
                ...state,
                SignveiwModal: !state.SignveiwModal,
                viewContent: action.payload ? action.payload : ''
            }
        case actionTypes.MYFILE_FOLDER_STRCTURE:
            return {
                ...state,
                folderStrct: action.payload,
                modalRootName: action.root
            }
        case actionTypes.TOGGLE_MYFILE_DELETE_MODAL:
            return {
                ...state,
                deleteModal: !state.deleteModal,
                optionType: action.payload,
                optionKey: action.key
            }
        case actionTypes.MYFILE_FOLDER_MOVE:
            return {
                ...state,
                folderMoveModal: !state.folderMoveModal,
            }
        case actionTypes.BUTTON_CHANGE_MODAL:
            return {
                ...state,
                buttonChange: !state.buttonChange,
            }
        case actionTypes.DRAGGING_ID:
            return {
                ...state,
                draggingDetails: action.payload,
            }
        case actionTypes.DROP_ID:
            return {
                ...state,
                dropDetails: action.payload,
            }
        case actionTypes.CONFIRMATION_MODAL:
            return {
                ...state,
                confirmModal: !state.confirmModal,
            }
        case actionTypes.SENT_LIST:
            return {
                ...state,
                sentList: action.payload,
                sentCount: action.count
            }
        case actionTypes.MYFILES_ACTIVE_PAGE:
            return {
                ...state,
                activePage: action.payload,
                skip: action.skip
            }
        case actionTypes.UPLOAD_FILES:
            return {
                ...state,
                uploadFileModal: !state.uploadFileModal
            }
        case actionTypes.PDF_VIEWER:
            return {
                ...state,
                pdfViewModal: !state.pdfViewModal,
                pdfLink: action.payload
            }


        case actionTypes.MYFILES_SHARED_WITHME_DATA:
            return {
                ...state,
                myfilessharedData: action.payload,
            }
        case actionTypes.MYFILES_WAITING_DATA:
            return {
                ...state,
                myfileswaitingData: action.payload,
            }
        case actionTypes.MYFILES_VIEWED_DATA:
            return {
                ...state,
                myfilesviewedData: action.payload,
            }
        case actionTypes.MYFILES_FINALIZED_DATA:
            return {
                ...state,
                myfilesfinalizedData: action.payload,
            }
        case actionTypes.MYFILES_TOTALSENT_DATA:
            return {
                ...state,
                myfilesTotalData: action.payload,
            }
        case actionTypes.MYFILES_DELETED_FOLDER:
            return {
                ...state,
                myfilesDeletedFolderData: action.payload,
            }
        case actionTypes.MYFILES_DELETED_FILE:
            return {
                ...state,
                myfilesDeletedFileData: action.payload,
            }
        case actionTypes.MYFILES_TIMELINE_FILE:
            return {
                ...state,
                myfilesTimelineData: action.payload,
            }



        default:
            return state;
    }
}