import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { open_iframe_modal } from "../../../actions/documentAction";
import './index.css'
function IframeModal() {
    const dispatch = useDispatch();
    const viewIframeModal = useSelector(state => state.documentData.viewIframeModal),
          iframe_modal_src = useSelector(state => state.documentData.iframe_modal_src) ;
    const toggle = () =>{ 
         dispatch(open_iframe_modal())
     };
 
  return (
    <div>
        <Modal isOpen={viewIframeModal} toggle={viewIframeModal}  className='compare-sub-notify1 video-iframe' centered >
                <ModalBody>
                <div className='cm-sec1'>
                <span className='close-btn1' onClick={() => toggle()}>&times;</span>
                <div className='cm-center1'>
                <iframe id="iframe" src={iframe_modal_src} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture ; autoplay;" fullscreen allowFullScreen="true" webkitallowFullScreen="true" mozallowFullScreen="true"></iframe>
                </div>
                </div>
                </ModalBody>
            </Modal>
    </div>
  )
}

export default IframeModal

