import * as actionTypes from '../actions/types.js';

export default (state = { pending: true }, action) => {

    switch (action.type) {
        case actionTypes.BUTTON_PENDING:
            return {
                ...state,
                pending: false
            };

        case actionTypes.BUTTON_SUCCESS:
            return {
                ...state,
                pending: true,
            };

        default:
		return state;
    }
};
