import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane, Label, Tooltip } from 'reactstrap';
import Fade from '@material-ui/core/Fade';
import classnames from 'classnames';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { useDispatch, useSelector } from 'react-redux';
import { authModal, clearFormData, createNewPassword, forgotModal, forgotPassword, otpSubmit, signInModal, userLogin } from '../../../actions/userLoginAction';
import { getErrorMsg } from '../../../actions/uiAction';
import { OtpModal, popup, popupoff, saveSignUp } from '../../../actions/signUpAction';
import Backdrop from '@material-ui/core/Backdrop';
import userIcon from '../../../images/new-design/account/mail.svg';
import name from '../../../images/new-design/account/name.svg';
import passwordIcon from '../../../images/new-design/account/password.svg';
import logo from '../../../images/new-design/site-logo.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import fetchData, { checkValue, NodeURL, RootdomainURL } from '../../../services';
import company from '../../../images/new-design/account/company.svg';
import CONFIG from '../../../config';
import '../Header/index.css'
import '../Header/header.scss'
import './Login.scss'


const ResendCounter = ({ initialCount = 0, render = () => {}, start = false, interval = 60000, onStop = () => {} }) => {
   const [count, setCount] = useState(initialCount);
   let intervalRef;

   useEffect(() => {
      if (!start) return;
      intervalRef = setInterval(() => {
         if (count <= 0) {
            setCount(initialCount);
            onStop(false);
         }
         setCount((c) => c - 1);
      }, interval);

      return () => {
         clearInterval(intervalRef);
      };
   }, [start, count]);

   return render ? render({ count }) : count;
};

function LoginSignup( props) {
   const dispatch = useDispatch();
   const reRef = useRef(null);
   const [activeTab, setActiveTab] = useState('1');
   const [formData, setFormData] = useState({});
   const [otpnum, setotp] = useState('');
   const [showButton, setShowButton] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [state, setState] = useState({
      showError: false,
      tooltipOpen: false,
      showInfo: false,
   });
   const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
   const subdoaminToggle = () => setState({ ...state, showInfo: !state.showInfo });
   const submitButton = useSelector((state) => state.button.pending),
      showLoginModal = useSelector((state) => state.userLoginData.showLoginModal),
      showForgotModal = useSelector((state) => state.userLoginData.showForgotModal),
      showOptModal = useSelector((state) => state.userLoginData.showOptModal),
      showNewPwdModal = useSelector((state) => state.userLoginData.showNewPwdModal),
      showMainModal = useSelector((state) => state.userLoginData.showMainModal),
      path = useSelector((state) => state.userLoginData.path),
      showOTPModal = useSelector((state) => state.userLoginData.showOTPModal),
      forgotUser = useSelector((state) => state.userLoginData.forgotUser),
      errorMsg = useSelector((state) => state.UIReducer.errorMessage),
      showError = useSelector((state) => state.UIReducer.showError);

   // Tab toggle function
   const toggleTab = (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
   };
   const getCodeBoxElement = (index) => document.getElementById('otp' + index);
   const jumpto = (event, index) => {
      const eventCode = event.which || event.keyCode;
      if (getCodeBoxElement(index).value.length === 1) {
         if (index !== 4) {
            getCodeBoxElement(index + 1).focus();
         } else {
            // getCodeBoxElement(index).blur();
         }
      }
      if (eventCode === 8 && index !== 1) {
         getCodeBoxElement(index - 1).focus();
      }
   };
   const forgotToggle = () => dispatch(forgotModal());
   const submitOtp = () => {
      if (
         formData &&
         formData.otp1 &&
         formData.otp1 !== '' &&
         formData.otp2 &&
         formData.otp2 !== '' &&
         formData.otp3 &&
         formData.otp3 !== '' &&
         formData.otp4 &&
         formData.otp4 !== ''
      ) {
         dispatch(otpSubmit(formData, forgotUser));
      } else {
         dispatch(getErrorMsg('Enter correct otp'));
      }
   };
   let validateEmail = (email) => {
      const re =
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
   };
   // Form data handling
   const handleChange = (e, field) => {
      setFormData({ ...formData, [field]: e.target.value });
   };

   const loginSubmitForm = () => {
      if (
         formData &&
         formData.email &&
         String(formData.email).length > 0 &&
         String(formData.email) !== String(undefined) &&
         String(formData.email) !== String(null)
      ) {
         if (
            formData &&
            formData.password &&
            String(formData.password).length > 0 &&
            String(formData.password) !== String(undefined) &&
            String(formData.password) !== String(null)
         ) {
            dispatch(userLogin(formData, props.history, props, path));
         } else {
            dispatch(getErrorMsg('Password is required'));
         }
      } else {
         dispatch(getErrorMsg('Email is required'));
      }
   };

   if (!showMainModal) return null;
   const mainToggle = () => {
      dispatch(authModal('home'));
   };
   const otpToggle = () => {
      dispatch(authModal('home'));
      dispatch(OtpModal());
   };
   const submitNewPassword = () => dispatch(createNewPassword(formData, forgotUser));
   const submitemailOtp = () => {
      if (
         formData &&
         formData.otp1 &&
         formData.otp1 !== '' &&
         formData.otp2 &&
         formData.otp2 !== '' &&
         formData.otp3 &&
         formData.otp3 !== '' &&
         formData.otp4 &&
         formData.otp4 !== ''
      ) {
         let response = fetchData({
            url: '/otp/time/verification',
            method: 'POST',
            data: { data: otpnum, votp: formData.otp1 + formData.otp2 + formData.otp3 + formData.otp4 },
         });
         response.then((res) => {
            if (res.status === 1) {
               dispatch(saveSignUp(formData, props));
            } else {
               dispatch(getErrorMsg(res.response));
            }
         });
      } else {
         dispatch(getErrorMsg('Enter correct otp'));
      }
   };
   const resendotp = () => {
      let response = fetchData({
         url: '/email/otp/verification',
         method: 'POST',
         data: { email: formData.email, name: formData.firstname + formData.lastname },
      });
      response.then((res) => {
         if (res.status === 1) {
            dispatch(popup('OTP has been successfully resent to your registered email'));
            setShowButton(true);
            setotp(res.data);
         }
      });
   };
   const handleSubmit = (e) => {
      if (formData.email && String(formData.email).length > 0) {
         if (validateEmail(formData.email)) {
            dispatch(forgotPassword(formData.email, props.history, props));
         } else {
            dispatch(getErrorMsg('Invalid email'));
         }
      } else {
         dispatch(getErrorMsg('Email is required'));
      }
   };
   const signupSubmitForm = async () => {
      if (
         formData &&
         formData.firstname &&
         formData.lastname &&
         formData.email &&
         formData.password &&
         formData.companyName &&
         formData.confirmPassword &&
         formData.personal_phone &&
         formData.subdomain
      ) {
         if (!validateEmail(formData.email)) {
            dispatch(getErrorMsg('Valid Email is required'));
         } else if (String(formData.password) !== String(formData.confirmPassword)) {
            dispatch(getErrorMsg('Password and Confirm password should be same'));
         } else {
            let response = fetchData({
               url: '/user/validate',
               method: 'POST',
               data: formData,
            });
            response.then(async (res) => {
               if (res.status === 1) {
                  const token = await reRef.current.executeAsync();
                  reRef.current.reset();
                  let response = fetchData({
                     url: '/recaptcha/api/siteverify',
                     method: 'POST',
                     data: { response: token },
                  });
                  response.then((res) => {
                     if (res.success) {
                        let response = fetchData({
                           url: '/email/otp/verification',
                           method: 'POST',
                           data: { email: formData.email, name: formData.firstname + formData.lastname },
                        });
                        response.then((res) => {
                           if (res.status === 1) {
                              dispatch(popupoff());
                              dispatch(OtpModal(true));
                              dispatch(popup('OTP has been sent to your registered mail successfully.'));
                              setotp(res.data);
                              setShowButton(true);
                           } else {
                              dispatch(getErrorMsg('Something Went Wrong'));
                           }
                        });
                     } else {
                        dispatch(getErrorMsg(res.response));
                     }
                  });
               } else {
                  dispatch(getErrorMsg(res.response));
               }
            });
         }
      } else {
         dispatch(getErrorMsg('All fields are required'));
      }
   };
   const loginToggle = () => {
      dispatch(signInModal());
      dispatch(clearFormData());
   };
   return (
      <div className='LoginSignup-container'>
      <Modal
         aria-labelledby="transition-modal-title"
         aria-describedby="transition-modal-description"
         className="login-modal"
         isOpen={showMainModal}
         onClose={!showOTPModal ? mainToggle : () => {}}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 500,
         }}
         backdropClassName='login-modal'
         toggle={!showOTPModal ? mainToggle : () => {}}
      >
         <Fade in={showMainModal} className='loginSingup-fadeshow'>
            <div className="auth-content">
               {showLoginModal ? (
                  <>
                     <div className="modal-header">
                        <Nav tabs className="login_form_header">
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab === '1' })}
                                 onClick={() => {
                                    toggleTab('1');
                                 }}
                              >
                                 Sign In
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={classnames({ active: activeTab === '2' })}
                                 onClick={() => {
                                    toggleTab('2');
                                 }}
                              >
                                 Sign Up
                              </NavLink>
                           </NavItem>
                        </Nav>
                     </div>
                     <div className="modal-body">
                        <TabContent activeTab={activeTab}>
                           <TabPane tabId="1">
                              <Row>
                                 <Col sm="12">
                                    <AvForm onSubmit={loginSubmitForm}>
                                       <div className="form_input">
                                          <AvField
                                             name="email"
                                             placeholder="Email Address"
                                             value={formData && formData.email ? formData.email : ''}
                                             id="login-email"
                                             onChange={(e) => handleChange(e, 'email')}
                                             type="text"
                                             autoFocus
                                          />
                                          <span className="field_icon">
                                             <img src={userIcon} alt="user-email" />
                                          </span>
                                       </div>
                                       <div className="form_input">
                                          <AvField
                                             name="password"
                                             type="password"
                                             autoComplete="off"
                                             placeholder="Password"
                                             id="login-password"
                                             value={formData && formData.password ? formData.password : ''}
                                             onChange={(e) => handleChange(e, 'password')}
                                          />
                                          <span className="field_icon">
                                             <img src={passwordIcon} alt="user-password" />
                                          </span>
                                       </div>
                                       <div>
                                          <Row className="rem-forget">
                                             <Col md="6">
                                                <div>
                                                   <AvGroup check>
                                                      <AvInput
                                                         type="checkbox"
                                                         name="remember"
                                                         className="agree-check"
                                                         onChange={(e) => handleChange(e, 'remember')}
                                                      />
                                                      <span className="checkmarker"></span>
                                                      <Label check for="checkItOut">
                                                         Remember Me
                                                      </Label>
                                                   </AvGroup>
                                                </div>
                                             </Col>
                                             <Col md="6">
                                                <div id="forgot_pwd" onClick={() => forgotToggle()}>
                                                   Forgot Password?
                                                </div>
                                             </Col>
                                          </Row>
                                       </div>
                                       <div className="submit-anc">
                                          {submitButton ? (
                                             <Button>Sign in</Button>
                                          ) : (
                                             <Button style={{ cursor: 'wait' }} className="btn-rounded" disabled={true} color="warning">
                                                Processing...
                                             </Button>
                                          )}
                                       </div>
                                       <div className="already-acc">
                                          <div>
                                             Don't have an account?
                                             <span className="movetosignup" onClick={() => toggleTab('2')}>
                                                Sign Up
                                             </span>
                                          </div>
                                       </div>
                                    </AvForm>
                                 </Col>
                              </Row>
                           </TabPane>
                           <TabPane tabId="2" className="sign-up-page">
                              <Row>
                                 <Col sm="12">
                                    <AvForm
                                       onSubmit={(e, errors, values) => {
                                          e.preventDefault();
                                          if (errors.length === 0) {
                                             signupSubmitForm();
                                          }
                                       }}
                                    >
                                       <div>
                                          <AvField
                                             name="firstname"
                                             id="first_name"
                                             placeholder="First Name"
                                             value={formData && formData.firstname ? formData.firstname : ''}
                                             onChange={(e) => handleChange(e, 'firstname')}
                                             type="text"
                                             autoFocus
                                          />
                                          <span className="s-icons">
                                             <img src={name} alt="firstname" />
                                          </span>
                                       </div>
                                       <div>
                                          <AvField
                                             name="lastname"
                                             placeholder="Last Name"
                                             value={formData && formData.lastname ? formData.lastname : ''}
                                             onChange={(e) => handleChange(e, 'lastname')}
                                             type="text"
                                          />
                                          <span className="s-icons">
                                             <img src={name} alt="lastname" />
                                          </span>
                                       </div>
                                       <div>
                                          <AvField
                                             name="email"
                                             placeholder="Email Address"
                                             value={formData && formData.email ? formData.email : ''}
                                             id="signup-email"
                                             onChange={(e) => handleChange(e, 'email')}
                                             type="email"
                                          />
                                          <span className="s-icons">
                                             <img src={userIcon} alt="email" />
                                          </span>
                                       </div>
                                       <div>
                                          <AvField
                                             name="personal_phone"
                                             placeholder="Phone Number"
                                             value={formData && formData.personal_phone ? formData.personal_phone : ''}
                                             onChange={(e) => handleChange(e, 'personal_phone')}
                                             type="number"
                                             onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                             validate={{ required: { value: true }, minLength: { value: 10 }, maxLength: { value: 12 } }}
                                          />
                                          <span className="s-icons">
                                             <img src={company} alt="phonenumber" />
                                          </span>
                                       </div>
                                       <div>
                                          <AvField
                                             name="companyName"
                                             placeholder="Company Name"
                                             value={formData && formData.companyName ? formData.companyName : ''}
                                             onChange={(e) => handleChange(e, 'companyName')}
                                             type="text"
                                          />
                                          <span className="s-icons">
                                             <img src={company} alt="companyname" />
                                          </span>
                                       </div>
                                       <div>
                                          <AvField
                                             name="subdomain"
                                             placeholder="Company domain name"
                                             value={formData && formData.subdomain ? formData.subdomain : ''}
                                             id="signup-subdomain"
                                             onChange={(e) => handleChange(e, 'subdomain')}
                                             type="text"
                                             validate={{
                                                required: { value: false },
                                                pattern: { value: '^[a-zA-Z0-9]+$' },
                                             }}
                                          />
                                          <span className="info-sec" id="subdomain-info">
                                             <span className="info-icon">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/info-g.svg'} alt="info" />
                                             </span>
                                             <Tooltip placement="top" isOpen={state.showInfo} target="subdomain-info" toggle={subdoaminToggle}>
                                                Choose a subdomain for your company/organisation, this could be the full name or a shorter memorable version of
                                                your company/organisation name
                                             </Tooltip>
                                          </span>
                                          <span className="domain-ph">{RootdomainURL}</span>
                                       </div>

                                       <div>
                                          <AvField
                                             name="password"
                                             type="password"
                                             autoComplete="off"
                                             placeholder="Password"
                                             id="signup-password"
                                             value={formData && formData.password ? formData.password : ''}
                                             onChange={(e) => handleChange(e, 'password')}
                                          />
                                          <span className="s-icons">
                                             <img src={passwordIcon} alt="password" />
                                          </span>
                                       </div>
                                       <div>
                                          <AvField
                                             name="confirmpassword"
                                             type="password"
                                             autoComplete="off"
                                             placeholder="Confirm Password"
                                             value={formData && formData.confirmPassword ? formData.confirmPassword : ''}
                                             onChange={(e) => handleChange(e, 'confirmPassword')}
                                          />
                                          <span className="s-icons">
                                             <img src={passwordIcon} alt="confirmpassword" />
                                          </span>
                                       </div>
                                       <ReCAPTCHA sitekey={CONFIG.RECAPTCHA_SITE_KEY} size="invisible" ref={reRef}>
                                          {' '}
                                       </ReCAPTCHA>
                                       <div className="submit-anc">
                                          {submitButton ? (
                                             <Button color="primary">Sign up</Button>
                                          ) : (
                                             <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                                Processing...
                                             </Button>
                                          )}
                                       </div>
                                       <div className="already-acc">
                                          <div>
                                             Already have an account?{' '}
                                             <span
                                                onClick={() => {
                                                   toggleTab('1');
                                                }}
                                                className="sign-in-sec"
                                             >
                                                Sign In
                                             </span>{' '}
                                          </div>
                                       </div>
                                    </AvForm>
                                 </Col>
                              </Row>
                           </TabPane>
                        </TabContent>
                     </div>
                  </>
               ) : showForgotModal ? (
                  <>
                     <div className="modal-body forgot_modal">
                        <AvForm onSubmit={handleSubmit}>
                           <h4>Forgot Password</h4>
                           <div className="form_input">
                              <AvField
                                 type="text"
                                 name="email"
                                 id="forgot-email"
                                 placeholder="Enter Email Address"
                                 onChange={(e) => handleChange(e, 'email')}
                                 value={formData && formData.email ? formData.email : ''}
                                 autoFocus
                              />
                              <span className="field_icon">
                                 <img src={userIcon} alt="user-email" />
                              </span>
                           </div>
                           <p>An OTP will be sent to your registered email address</p>
                           <div className="for-submit-btn">
                              {submitButton ? (
                                 <Button color="primary">Next</Button>
                              ) : (
                                 <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                    Processing...
                                 </Button>
                              )}
                           </div>
                        </AvForm>
                        <div onClick={loginToggle} className="just-rem">
                           Just remembered?<span>Sign In</span>
                        </div>
                     </div>
                  </>
               ) : showOptModal ? (
                  <>
                     <div className="modal-header otp-header">Enter OTP</div>
                     <div className="modal-body enter-otp">
                        <AvForm onSubmit={submitOtp}>
                           <AvField
                              type="text"
                              name="otp1"
                              id="otp1"
                              maxLength="1"
                              placeholder="*"
                              onKeyUp={(e) => jumpto(e, 1)}
                              onChange={(e) => handleChange(e, 'otp1')}
                              autoFocus
                           />
                           <AvField
                              type="text"
                              name="otp2"
                              id="otp2"
                              maxLength="1"
                              placeholder="*"
                              onKeyUp={(e) => jumpto(e, 2)}
                              onChange={(e) => handleChange(e, 'otp2')}
                           />
                           <AvField
                              type="text"
                              name="otp3"
                              id="otp3"
                              maxLength="1"
                              placeholder="*"
                              onKeyUp={(e) => jumpto(e, 3)}
                              onChange={(e) => handleChange(e, 'otp3')}
                           />
                           <AvField
                              type="text"
                              name="otp4"
                              id="otp4"
                              maxLength="1"
                              placeholder="*"
                              onKeyUp={(e) => jumpto(e, 4)}
                              onChange={(e) => handleChange(e, 'otp4')}
                           />
                           <div className="otp-but">
                              {submitButton ? (
                                 <Button color="primary">Next</Button>
                              ) : (
                                 <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                    Processing...
                                 </Button>
                              )}
                           </div>
                        </AvForm>
                     </div>
                  </>
               ) : showNewPwdModal ? (
                  <>
                     <div className="modal-header new-password-header">
                        <span>Create New Password</span>
                     </div>
                     <div className="modal-body">
                        <div className="new-password">
                           <AvForm onSubmit={submitNewPassword}>
                              <div className="new-password-field">
                                 <AvField
                                    type="password"
                                    name="new_password"
                                    placeholder="New Password"
                                    value={formData && formData.new_password ? formData.new_password : ''}
                                    onChange={(e) => handleChange(e, 'new_password')}
                                    autoFocus
                                 />
                                 <span className="field_icon">
                                    <img src={passwordIcon} alt="user-password" />
                                 </span>
                              </div>
                              <div className="new-password-field">
                                 <AvField
                                    type="password"
                                    name="new_con_password"
                                    placeholder="Confirm Password"
                                    value={formData && formData.new_con_password ? formData.new_con_password : ''}
                                    onChange={(e) => handleChange(e, 'new_con_password')}
                                 />
                                 <span className="field_icon">
                                    <img src={passwordIcon} alt="user-password" />
                                 </span>
                              </div>
                              <div className="new-but">
                                 {submitButton ? (
                                    <Button color="primary">Submit</Button>
                                 ) : (
                                    <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                       Processing...
                                    </Button>
                                 )}
                              </div>
                           </AvForm>
                        </div>
                     </div>
                  </>
               ) : null}
               {showError ? <span className="logsign-error">{errorMsg}</span> : ''}
               { showOTPModal ? (
                  <>
                     <div className="otp_modal_dialog">
                        <div className="modal-header otp-header">
                           <span>Email Verification</span>
                           <span onClick={otpToggle} className="clsbut">
                              X
                           </span>
                        </div>
                        <p className="paraalign">We have sent you a message with a verification code. Check your inbox and enter the verification code below</p>
                        <div className="modal-body enter-otp">
                           <AvForm onSubmit={submitemailOtp}>
                              <AvField
                                 type="text"
                                 name="otp1"
                                 id="otp1"
                                 maxLength="1"
                                 placeholder="*"
                                 onKeyUp={(e) => jumpto(e, 1)}
                                 onChange={(e) => handleChange(e, 'otp1')}
                                 autoFocus
                              />
                              <AvField
                                 type="text"
                                 name="otp2"
                                 id="otp2"
                                 maxLength="1"
                                 placeholder="*"
                                 onKeyUp={(e) => jumpto(e, 2)}
                                 onChange={(e) => handleChange(e, 'otp2')}
                              />
                              <AvField
                                 type="text"
                                 name="otp3"
                                 id="otp3"
                                 maxLength="1"
                                 placeholder="*"
                                 onKeyUp={(e) => jumpto(e, 3)}
                                 onChange={(e) => handleChange(e, 'otp3')}
                              />
                              <AvField
                                 type="text"
                                 name="otp4"
                                 id="otp4"
                                 maxLength="1"
                                 placeholder="*"
                                 onKeyUp={(e) => jumpto(e, 4)}
                                 onChange={(e) => handleChange(e, 'otp4')}
                              />
                              <div className="Verification-but">
                                 {submitButton ? (
                                    <Button color="primary">Verify</Button>
                                 ) : (
                                    <Button color="warning" style={{ cursor: 'wait' }} className="btn-rounded" disabled={true}>
                                       Processing...
                                    </Button>
                                 )}
                                 {
                                    <Button color="secondary" disabled={showButton} onClick={resendotp}>
                                       {!showButton ? (
                                          'Resend Verification code'
                                       ) : (
                                          <ResendCounter
                                             render={({ count }) => `Resend Verification code in - ${String(count).padStart(1, '0')} min`}
                                             start={showButton}
                                             onStop={setShowButton}
                                             initialCount={5}
                                          />
                                       )}
                                    </Button>
                                 }
                              </div>
                           </AvForm>
                        </div>
                     </div>
                  </>
               ) : null}
            </div>
         </Fade>
      </Modal>
      </div>
   );
}

export default LoginSignup;
