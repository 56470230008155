import * as actionTypes from "../actions/types.js";

export default (
	state = {
		cartDetails: [],
		paymentData: {},
		orderDetails: [],
		orderDetailsData: {},
		footerPageLists: [],
		pageDetailsData: {},
		totalCount: 0,
		successCount: 0,
		failedCount: 0,
		type: "",
		orderActivePage: 1,
		orderItemPerPage: 10,
		orderStatusType: "all",
		method_type: '',
		duration_filter: '',
		offline_cart_dat: [],
		offline_cart_data_count: 0,
		pop_show: false,
		dismiss_msg: false,
		paymentStatus: '',
		clientSecret: '',
		saved_card_id: '',
		isSavedCard: false,
		showReviewModal: false,
		reviewDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
		company_details: null
	},
	action
) => {
	switch (action.type) {
		case actionTypes.SETDETAILSMETHOD:
			return {
				...state,
				method_type: action.payload
			};
		case actionTypes.CLEAR_CART_DETAILS:
			return {
				...state,
				cartDetails: [...action.payload],
			};
		case actionTypes.OFF_LINE_CART_DATA:
			return {
				...state,
				offline_cart_dat: [...action.payload],
				offline_cart_data_count: [...action.payload].length,
			};
		case actionTypes.VIEW_CART_DETAILS:
			return {
				...state,
				cartDetails: [...action.payload]
			};
		case actionTypes.REMOVE_CART_ITEM:
			state.cartDetails.splice(action.payload, 1);
			return {
				...state,
				cartDetails: [...state.cartDetails],
			};
		case actionTypes.CHECTOUT_DETAILS:
			return {
				...state,
				paymentData: { ...action.payload },
			};
		case actionTypes.PAYMENT_SUCCESS_POPUP:
			return {
				...state,
				showSuccessPopupModal: !state.showSuccessPopupModal,
			};
		case actionTypes.ORDER_VIEW_DATA:
			return {
				...state,
				orderDetails: [...action.payload],
				totalCount: action.total,
				successCount: action.success,
				failedCount: action.failed,
				type: action.list_type,
			};
		case actionTypes.ORDER_PAGINATION:
			return {
				...state,
				orderActivePage: action.page_no,
				orderStatusType: action.statusType,
				duration_filter: action.duration_filter
			};
		case actionTypes.ORDER_DETAILS_DATA:
			return {
				...state,
				orderDetailsData: { ...action.payload },
			};
		case actionTypes.PAGES_LIST_FOOTER:
			return {
				...state,
				footerPageLists: [...action.payload],
			};
		case actionTypes.PAGE_DETAILS_DATA:
			return {
				...state,
				pageDetailsData: { ...action.payload },
			};
		case actionTypes.SHOW_TOP_POP_MODAL:
			return {
				...state,
				pop_show: action.payload && action.payload.pop_show,
			};
		case actionTypes.DISMISS_MSG_POP:
			return {
				...state,
				dismiss_msg: action.payload,
			};
		case actionTypes.PAYMENT_INTENT_CONFIRM:
			return {
				...state,
				paymentStatus: action.payload.status,
				clientSecret: action.payload.client_secret,
			};
		case actionTypes.USE_SAVED_CARD:
			return {
				...state,
				saved_card_id: action.card_id,
				isSavedCard: action.payload,
			};
		case actionTypes.SHOW_REVIEW_MODAL:
			return {
				...state,
				showReviewModal: !state.showReviewModal,
				company_details: action.payload
			}
		case actionTypes.SET_REVIEW_DATE:
			return {
				...state,
				reviewDate: action.payload
			}
		default:
			return state;
	}
};
