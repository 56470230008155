import * as actionTypes from '../actions/types.js';
export default (state = {
    skip: 0, itemPerPage: 25, activePage: 1, totalCount: 0, listData: [], employeeDetails: {}, searchValue: '',
    rolesData: [], checked: [], expanded: [], inviteModal: false, invitedList: [], changeDate: {},editDate: false, userGroupId: '',TotalAssignedPolicy:0
},
    action) => {
    switch (action.type) {
        case actionTypes.EMPLOYEES_LIST:
            return {
                ...state,
                listData: action.payload,
                totalCount: action.total
            }
        case actionTypes.EMPLOYEE_TOTAL_DATA:
            return {
                ...state,
                TotalAssignedPolicy: action.payload,
            }
        case actionTypes.EMPLOYEE_DATA:
            return {
                ...state,
                employeeDetails: { ...state.employeeDetails, ...action.payload },
                rolesData: action.payload && action.payload.permission && action.payload.permission.length > 0 ? action.payload.permission : []
            }
        case actionTypes.EMPLOYEE_TABLE_ATTRIBUTES:
            return {
                ...state,
                skip: action.payload.skip,
                activePage: action.payload.activePage,
                searchValue: action.payload.searchValue
            }
        case actionTypes.CLEAR_EMPLOYEE_DETAILS:
            return {
                ...state,
                employeeDetails: {},
                checked: [],
                expanded: []
            }
        case actionTypes.ADD_ROLES_DATA:
            let index = state.rolesData.map(e => e.name).indexOf(action.payload.name);
            if (index > -1) {
                state.rolesData.splice(index, 1);
            } else {
                state.rolesData.push({ 'name': action.payload.name });
            }
            return {
                ...state,
                rolesData: state.rolesData,
                employeeDetails: { ...state.employeeDetails, ...{ 'permission': state.rolesData } }
            };
        case actionTypes.EDIT_ROLES_DATA:
            let indexes = state.rolesData.map(e => e.name).indexOf(action.payload.name);
            if (indexes > -1) {
                state.rolesData.splice(indexes, 1);
            } else {
                state.rolesData.push({ 'name': action.payload.name });
            }
            return {
                ...state,
                rolesData: state.rolesData,
                employeeDetails: { ...state.employeeDetails, ...{ 'permission': state.rolesData } }
            };
        case actionTypes.UPADTE_EMPLOYEE_LIST:
            return {
                ...state,
                listData: state.listData.filter(e => e._id !== action.id)
            }
        case actionTypes.SELECTED_FOLDERS:
            return {
                ...state,
                checked: [...action.payload]
            }
        case actionTypes.EXPANDED_FOLDER:
            return {
                ...state,
                expanded: [...action.payload]
            };
        case actionTypes.INVITE_MODAL:
            return {
                ...state,
                inviteModal: !state.inviteModal
            };
        case actionTypes.INVITED_LIST:
            return {
                ...state,
                invitedList: action.payload
            };
        case  actionTypes.SHOW_GROUP_POLICY_EDIT_MODAL:
            return {
                ...state,
                editDate: !state.editDate,
                userGroupId: action.payload && action.payload.id && action.payload.id !== undefined ? action.payload.id : '',
            }
        case  actionTypes.EDIT_DUE_DATE:
            return {
                ...state,
                changeDate: action.payload && Object.keys(action.payload).length > 0 ? { ...state.changeDate, ...action.payload } : {},
            }
        default:
            return state
    }
}