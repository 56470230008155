import * as actionTypes from '../actions/types.js';

export default (state = { status: true }, action) => {

    switch (action.type) {
        case actionTypes.SORT_TRUE:
            return {
                ...state,
                status: true
            };

        case actionTypes.SORT_FALSE:
            return {
                ...state,
                status: false,
            };

        default:
		return state;
    }
};
