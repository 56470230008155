import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { changeModal, getOneNotification, closeNotification } from "../../../actions/notificationActions"


const OneNotificationModal = () => {
    const dispatch = useDispatch(),
        showNotification = useSelector(state => state.notificationData.openNotification),
        notification = useSelector(state => state.notificationData.oneNotification);

    const toggleModal = () => {
        dispatch(closeNotification())
    }
    return (
        <div>
            <Modal isOpen={showNotification} toggle={(e) => toggleModal()} backdrop="static" centered={true} className="notifi-detail-mod">
                <ModalHeader toggle={(e) => toggleModal()}>{notification.title}</ModalHeader>
                <ModalBody>
                    <div>{notification.content}</div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default OneNotificationModal