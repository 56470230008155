import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import './index.css';
import { subdomainRemindModalTogggle } from '../../../actions/myProfileAction';

const SubdomainRemind = (props) => {
    let dispatch = useDispatch(),
        subdomainRemindModal = useSelector(state => state.myProfile.subdomainRemindModal),
        goToProfile = (type) => {
            props.history.push("/profile")
            sessionStorage.setItem("profileType", type);
        }
    return (
        <Modal isOpen={subdomainRemindModal} toggle={() => dispatch(subdomainRemindModalTogggle(false))} className='sub-remind'>
            <ModalBody>You must enter your subdomain name for your company. Please click below and fill your subdomin field.</ModalBody>
            <ModalFooter>
                <Button onClick={() => goToProfile('companydetail')}>Go to Profile</Button>
            </ModalFooter>
        </Modal>
    );
};

export default SubdomainRemind;