import * as actionTypes from '../actions/types.js';

export default (state = {
    showNotification: false,
    notificationList: [],
    unread: 0,
    openNotification: false,
    oneNotification: {},
    limit: 10,
    total: 0,
    pageDesign: ""
}, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_NOTIFICATION_MODAL:
            return {
                ...state,
                showNotification: !state.showNotification,
                pageDesign: action.payload ? action.payload : ""
            }
        case actionTypes.NOTIFICATION_LIST:
            return {
                ...state,
                notificationList: action.payload,
                unread: action.unread,
                total: action.total
            }
        case actionTypes.OPEN_NOTIFICATION_MODAL:
            return {
                ...state,
                openNotification: !state.openNotification
            }
        case actionTypes.GET_ONE_NOTIFICATION:
            return {
                ...state,
                oneNotification: action.payload ? action.payload : {},
                unread: action.unread
            }
        case actionTypes.CHANGE_NOTIFICATION_LIMIT:
            return {
                ...state,
                limit: action.payload
            }
        default:
            return state;
    }
}