import * as actionTypes from '../actions/types.js';

export default (state = { formData: {} }, action) => {
 

    switch (action.type) {

        case actionTypes.SETTING_ADD:
            return {
                ...state,
                formData: { ...action.payload }
            };


        default:
            return state;
    }
};
