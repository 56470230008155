import React, { useState, useEffect } from "react";
import "./Countdown.css";

const Countdown = ({ expiryTimestamp ,color}) => {
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(expiryTimestamp));

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft(expiryTimestamp);
      setTimeLeft(updatedTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [expiryTimestamp]);

  function calculateTimeLeft(expiry) {
    const now = new Date().getTime(); // Current time in milliseconds
    const difference = expiry - now;

    if (difference <= 0) {
      return null; // Countdown has expired
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0');
    const minutes = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, '0');
    const seconds = String(Math.floor((difference / 1000) % 60)).padStart(2, '0');  

    return { days, hours, minutes, seconds };
  }

  if (!timeLeft) {
    return <div className="expired">Offer expired!</div>;
  }

  return (
    <div className="countdown-container">
      <div className="countdown-box">
        <div className="countdown-value">{timeLeft.days}</div>
        <div className="countdown-label" style={{color:`${color}`}}>Days</div>
      </div>
      <div className="countdown-box">
        <div className="countdown-value">{timeLeft.hours}</div>
        <div className="countdown-label"  style={{color:`${color}`}}>Hours</div>
      </div>
      <div className="countdown-box">
        <div className="countdown-value">{timeLeft.minutes}</div>
        <div className="countdown-label"  style={{color:`${color}`}}>Minutes</div>
      </div>
      <div className="countdown-box">
        <div className="countdown-value">{timeLeft.seconds}</div>
        <div className="countdown-label"  style={{color:`${color}`}}>Seconds</div>
      </div>
    </div>
  );
};

export default Countdown;
