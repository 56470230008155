var CONFIG = {};

//  CONFIG.URL = 'http://localhost:3007';
// CONFIG.URL = "https://caredocument.casperon.co";
CONFIG.URL = 'https://www.cloudoc.co.uk';


// //Recaptcha FOR LOCAL
// CONFIG.RECAPTCHA_SITE_KEY = '6LfW8DApAAAAABNvJzwShCq2JY_6AemJOXE4UklO';
// //
//Recaptcha FOR LIVE & STAGING
CONFIG.RECAPTCHA_SITE_KEY = '6Ld0CDYpAAAAAHHgZBxq5i0mtGGf9-Wyj6TZoRTu';
//
export default CONFIG;