import * as actionTypes from './types';
import fetchData, { checkValue, NodeURL, visitSiteURL } from '../services';
import FileSaver, { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';


export const addFolderName = (documentName, folderId) => {
    return {
        type: actionTypes.ADD_FOLDER_NAME,
        payload: documentName,
        folderId: folderId
    }
};

export const Clear_folder_name = () => {
    return {
        type: actionTypes.CLEAR_FOLDER_NAME,
        payload: ''
    }
};

export const Clear_docs_EDIT_data = () => {
    return {
        type: actionTypes.CLEAR_EDIT_DOCS_DATA,
        payload: {}
    }
};

export const addFileName = (file, folderId) => {
    return {
        type: actionTypes.ADD_FILE_NAME,
        payload: file,
        folderId: folderId
    }
};

export const fileEditName = (file) => {
    return {
        type: actionTypes.ADD_FILE_NAME,
        payload: file,
        // file: file
    }
};

export const compareModel = (file) => {
    return {
        type: actionTypes.COMPARE_FILE,
        payload: file
    }
};

export const open_lik_modal_pop = (value) => {
    return {
        type: actionTypes.OPEN_CLOSE_LINK_POP,
        payload: String(value) === String(true) ? true : false
    }
};


export const createFolder = (document) => {
    let obj = {
        folderName: document,
        userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ""
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/createfolder', method: 'POST', data: obj });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: 'UPDATE_LIST', payload: res.response.data });
                dispatch({ type: 'MODAL_VIEW', payload: "" });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Folder has been created', alignment: "centre" }
                });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
};

export const Get_Template_for_company = () => {
    return (dispatch) => {
        let response = fetchData({ url: '/users/for_company/gettemplateList', method: 'POST', data: {} });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.GET_COMP_TEMPLATE_LIST, payload: res.response });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
};

export const renameFolder = (id, name) => {
    return (dispatch) => {
        let formData = {
            folderName: name,
            folderId: id,
            userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ""
        }
        let response = fetchData({ url: '/user/editFolder', method: 'POST', data: formData });
        response.then(res => {
            dispatch({
                type: 'RENAME_MODAL_VIEW',
                payload: ""
            });
            dispatch({
                type: 'UPDATE_RENAME_MODAL',
                payload: {
                    folderName: name,
                    folderId: id,
                    action_by: res && res.action_by ? res.action_by : ''
                }
            });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Folder has been updated successfully.', alignment: "centre" }
            });
        });
    }
};

export const createFile = (file, templateId, history) => {
    let formData = {
        "userId": sessionStorage.getItem("userId") ? sessionStorage.getItem("userId") : "",
        "folderId": sessionStorage.getItem("rightClickedFolderId") ? sessionStorage.getItem("rightClickedFolderId") : sessionStorage.getItem("clickedFolderId"),
        "fileName": file && file.fileName ? file.fileName : "",
        "policyNumber": file && file.policyNumber ? JSON.stringify(file.policyNumber) : "",
        "templateId": file.template ? file.template : "",
        "format": file && file.format ? file.format : "potrait",
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/createFile', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.response.status === 1) {
                sessionStorage.setItem('userClickedFileId_CREATE_FILE', res.response && res.response.uId ? res.response.uId : '');
                sessionStorage.setItem('clickedFileAdminFROM_CREATE_FILE', JSON.stringify(res.response));
                if (!sessionStorage.getItem('rightClickedFolderId')) {
                    dispatch({ type: 'UPDATE_LIST', payload: res.response });
                }
                dispatch({ type: 'LIST_MODAL_VIEW', payload: "" });
                dispatch({ type: 'UPDATE_DOCUMENT_FILE', payload: res.result });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Document has been created', alignment: "centre" }
                });
                sessionStorage.removeItem("rightClickedFolderId")
                history.push('/documents/editor');
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
};

export const recentFile = (file) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/create/RecentFiles', method: 'POST', data: { file } });
        response.then(res => {
            if (res.response.status === 1) {
                dispatch({
                    type: 'UPDATE_RECENT_FILES',
                    payload: res.response
                });
            }
        });
    }
};
export const getAdminFile = (uId) => {
    let formData = {
        userId: sessionStorage.getItem('userId'),
        fileId: uId
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/comparefile', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: 'GET_COMPARE_FILE',
                    payload: res.response
                });
            }
        });
    }
};

export const listRecentFile = () => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/getRecentFiles', method: 'POST', data: { userId: sessionStorage.getItem('userId') } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: 'RECENT_FILES',
                    payload: res.response
                });
            }
        });
    }
};

export const editFile = (file) => {
    let formData = {
        "fileId": sessionStorage.getItem("userClickedFileId") ? sessionStorage.getItem("userClickedFileId") : file._id,
        "fileName": file && file.name ? file.name : "",
        "policyNumber": file && file.policyNumber ? file.policyNumber : "",
        "format": file && file.format ? file.format : "potrait",
        "userId": sessionStorage.getItem('userId')
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/editFile', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                sessionStorage.removeItem("rightClickedFolderId")
                dispatch({ type: 'FILE_EDIT_MODAL_VIEW', payload: "" });
                dispatch({ type: 'UPDATE_FILE_EDIT_MODAL', payload: formData });
                dispatch({ type: 'UPDATE_DOCUMENT_FILE', payload: res.result });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Document setup changes has been saved', alignment: "centre" }
                });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
};

export const createSubFolder = (folderName, parentId) => {
    let obj = {
        folderName: folderName,
        parentId: parentId,
        userId: sessionStorage.getItem('userId')
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/createsubfolder', method: 'POST', data: obj });
        response.then(res => {
            if (res.status === 1) {
                if (!sessionStorage.getItem('rightClickedFolderId')) {
                    dispatch({
                        type: 'UPDATE_LIST',
                        payload: res.response.data
                    });
                }
                dispatch({
                    type: 'MODAL_VIEW',
                    payload: ""
                });
                sessionStorage.removeItem("rightClickedFolderId")
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Folder has been created', alignment: "centre" }
                });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
};

export const clearDocument = () => {
    return {
        type: actionTypes.CLEAR_DOCUMENT,
        payload: []
    }
}


export const listDocument = (sortby, sortStatus, sortclicked) => {
    let userId = sessionStorage.getItem('userId');
    let role = sessionStorage.getItem('userRole');
    let data = {};
    if (typeof sortby !== 'undefined' && sortby !== '') {
        data.sort = sortby;
        data.status = sortStatus;
        data.userId = userId;
        data.role = role;
    }
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        if (sortclicked !== undefined && sortclicked === true) {
            if (sortStatus === true) {
                dispatch({ type: actionTypes.SORT_FALSE });
                data.status = false;
            } else {
                dispatch({ type: actionTypes.SORT_TRUE });
                data.status = true;
            }
        }
        let response = fetchData({ url: '/user/getFoldersList', method: 'POST', data: data });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: 'GET_ALL_DOCUMENT',
                payload: res.response
            });
        });
    }
}

export const Set_searched_val_in_docs_list = (searchData) => {
    return {
        type: actionTypes.SET_SEARCHED_VALUE_FOR_INPUT, payload: searchData
    }
}

export const documentSearch = (searchData) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/document/search', method: 'POST', data: { 'search': searchData, 'userId': sessionStorage.getItem('userId') } });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (searchData && String(searchData).length > 0) {
                dispatch({
                    type: 'GET_ALL_DOCUMENT',
                    payload: res.response
                });
            }
            dispatch({ type: actionTypes.SET_SEARCHED_VALUE, payload: searchData });
            dispatch({ type: actionTypes.SET_SEARCHED_VALUE_FOR_INPUT, payload: searchData });
        });
    }
}
export const documentSearchfolder = (searchData) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/document/search', method: 'POST', data: { 'search': searchData, 'userId': sessionStorage.getItem('userId'), 'clickedFolderId': sessionStorage.getItem('clickedFolderId') ? sessionStorage.getItem('clickedFolderId') : '' } });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (searchData && String(searchData).length > 0) {
                dispatch({
                    type: 'GET_ALL_DOCUMENT',
                    payload: res.response
                });
            }
            dispatch({ type: actionTypes.SET_SEARCHED_VALUE, payload: searchData });
            dispatch({ type: actionTypes.SET_SEARCHED_VALUE_FOR_INPUT, payload: searchData });
        });
    }
}
export const empPolicySearchfolder = () => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/document/search', method: 'POST', data: {  'userId': sessionStorage.getItem('userId'), 'clickedFolderId': sessionStorage.getItem('clickedFolderId') ? sessionStorage.getItem('clickedFolderId') : '' ,employeepolicy:true} });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: 'GET_ALL_DOCUMENT',
                payload: res.response
            });
        });
    }
}

export const clearSearchData = (value = '') => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_SEARCH_VALUE, payload: value ? value : '' });
    }
}

export const listDocumentById = (folderId, folderName, clickedFolder, Uid, sortby, sortStatus, sortclicked) => {
    sessionStorage.setItem('clickedFolderId', folderId);
    sessionStorage.setItem('clickedFolderUid', Uid);
    let obj = {
        userId: sessionStorage.getItem('userId'),
        folderId: Uid,
        role: sessionStorage.userRole ?  sessionStorage.userRole : ''
    }
    if (typeof sortby !== 'undefined' && sortby !== '') {
        obj.sort = sortby;
        obj.status = sortStatus;
    }
    return (dispatch) => {
        if (sortclicked !== undefined && sortclicked === true) {
            if (sortStatus === true) {
                dispatch({ type: actionTypes.SORT_FALSE });
                obj.status = false;
            } else {
                dispatch({ type: actionTypes.SORT_TRUE });
                obj.status = true;
            }
        }
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        let response = fetchData({ url: '/user/getSubFoldersList', method: 'POST', data: obj });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (folderName !== undefined) {
                if (JSON.parse(sessionStorage.getItem('clickedFolder')) !== null) {
                    let a;
                    a = (JSON.parse(sessionStorage.getItem('clickedFolder')));
                    a.push({
                        folderName: folderName,
                        folderId: folderId
                    });
                    sessionStorage.setItem('clickedFolder', JSON.stringify(a));
                } else {
                    let a = [];
                    a.push({
                        folderName: folderName,
                        folderId: folderId
                    });
                    sessionStorage.setItem('clickedFolder', JSON.stringify(a));
                }
                dispatch({ type: 'CLICKED_DOCUMENT', payload: { folderName: folderName, folderId: folderId, uId: Uid } });
            }
            dispatch({
                type: 'GET_DOCUMENT_BY_ID',
                payload: [...res.response.subFolders, ...res.response.files],
                folderId: folderId
            });
        });
    }
}

export const clearDownloadFile = () => {
    return {
        type: actionTypes.CLEAR_DOWNLOAD_DOC,
        payload: {}
    }
}

export const selectedFile = (file) => {
    if (file && file._id) {
        return (dispatch) => {
            dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
            dispatch({ type: actionTypes.SET_EDITOR_CONTENT, payload: '' });
            let response = fetchData({ url: '/user/downloadfile', method: 'POST', data: { fileId: file._id, 'userId': file.userId } });
            response.then(res => {
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
                if (res.status === 1) {
                    let fileContent = res.response;
                    let variables = (fileContent && fileContent.variables) ? fileContent.variables : [];
                    dispatch({ type: actionTypes.SELECTED_FILE, payload: file });
                    dispatch({ type: actionTypes.SET_EDITOR_CONTENT, payload: (fileContent && fileContent.content) ? fileContent.content : null });
                    dispatch({ type: actionTypes.CLIENT_VARIABLES_LIST, payload: variables });
                    file.variables = variables;
                    // sessionStorage.setItem('selectedFile', JSON.stringify(file));
                }
            })
        }
    } else {
        return (dispatch) => {
            dispatch({ type: actionTypes.SELECTED_FILE, payload: file });
            dispatch({ type: actionTypes.SET_EDITOR_CONTENT, payload: '' });
        }
    }
};

export const clearClickedFolder = () => {
    return {
        type: 'CLEAR_CLICKED_DOCUMENT',
        payload: [],
    }
}
export const addClickedFolder = (obj) => {
    return {
        type: 'ADD_CLICKED_DOCUMENT',
        payload: obj,
    }
}
export const pathChanging = (id, i) => {
    if (JSON.parse(sessionStorage.getItem('clickedFolder')) !== null) {
        let a;
        a = (JSON.parse(sessionStorage.getItem('clickedFolder')).splice(0, i + 1));
        sessionStorage.setItem('clickedFolder', JSON.stringify(a));
    }
    return { type: 'PATH_CHANGING', payload: id, index: i }
}

export const documentDeleteModel = (id) => {
    return {
        type: actionTypes.DOCUMENT_DELETE_MODEL,
        documentId: id ? id : ""
    }
}

export const deleteDocumentRemove = (id) => {
    return {
        type: actionTypes.DELETE_DOCUMENT__REMOVE,
        documentId: id ? id : ""
    }
}
export const deleteFileRemove = (id) => {
    return {
        type: actionTypes.DELETE_FILE__REMOVE,
        documentId: id ? id : ""
    }
}

export const fileDeleteModel = (id) => {
    return {
        type: actionTypes.FILE_DELETE_MODEL,
        documentId: id ? id : ""
    }
}


export const deleteDocument = (documentId) => {
    let obj = {
        userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "",
        folderId: documentId
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/deleteFolder', method: 'POST', data: obj });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.DELETE_MODAL_VIEW,
                    payload: {}
                });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Folder has been deleted', alignment: "centre" }
                });
                sessionStorage.removeItem("rightClickedFolderId")
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const deleteFile = (fileId, history, location) => {
    return (dispatch) => {
        let obj = {
            fileId: fileId,
            userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "",
        }
        let response = fetchData({ url: '/user/deleteFile', method: 'POST', data: { obj } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.DELETE_MODAL_VIEW, payload: {} });
                dispatch({ type: actionTypes.SET_EDITOR_CONTENT, payload: null });
                sessionStorage.removeItem("rightClickedFolderId");
                sessionStorage.removeItem("userClickedFileId");
                sessionStorage.removeItem("selectedFile");
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Document has been deleted successfully', alignment: "centre" }
                });
                if (location.pathname === '/documents/editor' && history) {
                    history.push('/documents')
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}
export const updateFile = (fileId, content, history, variables) => {
    let obj = {
        fileId: fileId,
        userId: sessionStorage.getItem('userId'),
        content: content,
        variables: (variables && variables.length > 0) ? variables : []
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/updatecontent', method: 'POST', data: obj });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.COMPARE_FILE, payload: "" });
                dispatch({ type: actionTypes.UPDATE_FILE_LIST, payload: res.content });
                sessionStorage.removeItem('comapreDocumentItem');
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'File Updated Successfully.', alignment: "centre" }
                });
                sessionStorage.setItem('userClickedFileId', res.selectedfile && res.selectedfile.uId ? res.selectedfile.uId : '')
                sessionStorage.setItem('selectedFile', JSON.stringify(res.selectedfile));
                setTimeout(() => {
                    history.push({ pathname: "/documents/editor", fileId: fileId });
                }, 1500);
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const addExportFilesOptions = (value, type, downloadtype) => {
    return {
        type: (type === 'single') ? actionTypes.ADD_EXPORT_LIST : (type === 'selectall') ? actionTypes.ADD_EXPORT_ALLLIST : (type === 'export' && downloadtype === 'exportas') ? actionTypes.ADD_EXPORT_FILE_FORMAT : (type === 'export' && downloadtype === 'exporttype') ? actionTypes.ADD_EXPORT_FILE_TYPE : actionTypes.ADD_EXPORT_LIST,
        payload: value
    }
}

export const exportAsdownload = (values, type, format) => {
    let dataObj = {
        'download_format': format,
        'download_type': type,
        'values': JSON.stringify(values),
        'userId': sessionStorage.getItem('userId'),
    };
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        if (type === 'word') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating Word file', alignment: "bottom" }
            });
            let response = fetchData({ url: '/user/bulk-export-word', method: 'POST', data: dataObj });
            response.then(res => {
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: false, text: '', alignment: "centre" }
                });
                if (res.status === 1) {
                    let fileUrls = res.response;
                    if (fileUrls && fileUrls.length > 0) {
                        fileUrls = fileUrls.map(url => window.location.origin + new URL(url).pathname)
                        let zip = new JSZip();
                        let count = 0;
                        let zipFilename = "Documents.zip";
                        fileUrls.forEach(function (url, i) {
                            let filename = url.replace(/.*\//g, "");
                            filename = decodeURI(filename);
                            JSZipUtils.getBinaryContent(url, function (err, data) {
                                if (err) {
                                    throw err;
                                }
                                zip.file(filename, data, {
                                    binary: true,
                                    createFolders: true
                                });
                                count++;
                                if (+count === fileUrls.length) {
                                    zip.generateAsync({
                                        type: "blob"
                                    })
                                        .then(function (blob) {
                                            saveAs(blob, zipFilename);
                                            dispatch({
                                                type: actionTypes.BOTTOM_T_W_AL,
                                                payload: { show_hide: true, text: 'Document downloaded successfully', alignment: "centre" }
                                            });
                                            dispatch({ type: actionTypes.BUTTON_SUCCESS });
                                            dispatch({ type: actionTypes.ADD_EXPORT_ALLLIST, payload: [] })
                                            dispatch({ type: actionTypes.GET_CLICKED_MULTIPLE_FILE, payload: [] })
                                        });
                                }
                            });
                        });
                        dispatch({ type: actionTypes.BUTTON_SUCCESS })
                    }

                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            });
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
            });
            let response = fetchData({ url: '/user/bulk-export-pdf', method: 'POST', data: dataObj });
            response.then(res => {
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: false, text: '', alignment: "centre" }
                });
                if (res.status === 1) {
                    // window.open(res.response, '_blank');
                    let fileUrls = res.response;
                    if (fileUrls && fileUrls.length > 0) {
                        fileUrls = fileUrls.map(url => window.location.origin + new URL(url).pathname)
                        let zip = new JSZip();
                        let count = 0;
                        let zipFilename = "Documents.zip";
                        fileUrls.forEach(function (url, i) {
                            let filename = url.replace(/.*\//g, "");
                            filename = decodeURI(filename);
                            JSZipUtils.getBinaryContent(url, function (err, data) {
                                if (err) {
                                    throw err;
                                }
                                zip.file(filename, data, {
                                    binary: true,
                                    createFolders: true
                                });
                                count++;
                                if (+count === fileUrls.length) {
                                    zip.generateAsync({
                                        type: "blob"
                                    })
                                        .then(function (blob) {
                                            saveAs(blob, zipFilename);
                                            dispatch({
                                                type: actionTypes.BOTTOM_T_W_AL,
                                                payload: { show_hide: true, text: 'Document downloaded successfully', alignment: "centre" }
                                            });
                                            dispatch({ type: actionTypes.BUTTON_SUCCESS });
                                            dispatch({ type: actionTypes.ADD_EXPORT_ALLLIST, payload: [] })
                                            dispatch({ type: actionTypes.GET_CLICKED_MULTIPLE_FILE, payload: [] })
                                        });
                                }
                            });
                        });
                        dispatch({ type: actionTypes.BUTTON_SUCCESS })
                    }
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                }
            });
        }
    }
}

export const uploadPdf = (data, key, type) => {
    var formData = new FormData();
    formData.append("pdfImg", data);
    return (dispatch) => {
        let response = fetchData({ url: '/user/upload-pdf', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.PDF_FORM_DATA,
                    payload: { 'content': res.url },
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Not Uploaded.', alignment: "centre" }
                });
            }
        });
    }
}

export const createPdf = (formData) => {
    let dataObj = {
        "userId": sessionStorage.getItem("userId") ? sessionStorage.getItem("userId") : "",
        "folderId": sessionStorage.getItem("rightClickedFolderId") ? sessionStorage.getItem("rightClickedFolderId") : sessionStorage.getItem("clickedFolderId"),
        "fileName": formData && formData.name ? formData.name : "",
        "policyNumber": formData && formData.policyNumber ? formData.policyNumber : "",
        "content": formData && formData.content ? formData.content : ""
    }
    if (formData._id) {
        dataObj._id = formData._id;
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/create-pdf', method: 'POST', data: dataObj });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                if (formData._id) {
                    dispatch({ type: 'PDF_EDITMODAL_VIEW', payload: {} });
                    dispatch({ type: 'UPDATE_DOCUMENT_FILE', payload: res.result });
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Other files has been updated successfully', alignment: "centre" }
                    });
                    sessionStorage.removeItem("rightClickedFolderId");
                } else {
                    if (!sessionStorage.getItem('rightClickedFolderId')) {
                        dispatch({ type: 'UPDATE_LIST', payload: res.response });
                    }
                    dispatch({ type: 'PDF_MODAL_VIEW', payload: {} });
                    dispatch({ type: 'CLEAR_EDIT_DATA', payload: {} });
                    dispatch({ type: 'UPDATE_DOCUMENT_FILE', payload: res.result });
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Other files has been uploaded', alignment: "centre" }
                    });
                    sessionStorage.removeItem("rightClickedFolderId");
                }
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const EditPDF = (file) => {
    return {
        type: actionTypes.PDF_FORM_DATA,
        payload: file,
    }
};

export const downloadWordorFile = (item, filetype, sendtype = '') => {
    let formData = {
        fileId: item._id ? item._id : item && checkValue(item) ? item : '',
        content: item.content ? item.content : '',
        userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '',
        sendtype: sendtype
    }, url = '/user/download-pdf';
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        if (filetype === 'open') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
            });
        }
        if (filetype === 'filepdf') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
            });
        }
        if (filetype === 'word') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating Word file', alignment: "bottom" }
            });
            url = '/user/document/download';
            // url = '/user/download-word';
            // response = fetchData('/user/download-word', 'post', formData);
        } else if (filetype === 'pdf') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
            });
            url = '/user/download-pdf-url';
            // response = fetchData('/user/download-pdf-url', 'post', formData);
        }
        let response = fetchData({ url: url, method: 'POST', data: formData });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: false, text: '', alignment: "centre" }
            });
            if (res.status === 1) {
                if (filetype === 'filepdf' || filetype === 'file') {
                    let oReq = new XMLHttpRequest();
                    let URLToPDF = res.response;
                    let url = window.location.origin + new URL(URLToPDF).pathname;
                    let filename = res.filename && checkValue(res.filename) ? res.filename : (item.name && item.name !== '') ? item.name : item.filename;
                    oReq.open("GET", url, true);
                    oReq.setRequestHeader('Access-Control-Allow-Origin', '*');
                    oReq.responseType = "blob";
                    oReq.onload = function () {
                        var file = new Blob([oReq.response], {
                            type: 'application/pdf'
                        });
                        saveAs(file, filename + ".pdf");
                    };
                    oReq.send();
                } else if (filetype === 'pdf') {
                    let oReq = new XMLHttpRequest();
                    let URLToPDF = res.response.content;
                    let url = window.location.origin + new URL(URLToPDF).pathname;
                    // let filename = (res.response.name && res.response.name !== '') ? res.response.name : res.response.filename;                  
                    let filename = res.filename && checkValue(res.filename) ? res.filename : (item.name && item.name !== '') ? item.name : item.filename;
                    oReq.open("GET", url, true);
                    oReq.responseType = "blob";
                    oReq.onload = function () {
                        var file = new Blob([oReq.response], {
                            type: 'application/pdf'
                        });
                        saveAs(file, filename + ".pdf");
                    };
                    oReq.send();


                } else {
                    if (String(sendtype) === String('direct')) {
                        window.open(res.response, '_blank');
                    } else {
                        // const tabOpen = window.open("about:blank", "newtab");
                        // tabOpen.location = res.response;
                        window.open(res.response, 'newtab');
                    }
                }
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}
export const downloadBkpWordorFile = (item, filetype, sendtype = '') => {
    let formData = {
        fileId: item._id ? item._id : item && checkValue(item) ? item : '',
        content: item.content ? item.content : '',
        userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '',
        sendtype: sendtype,
        item: item,
        filetype: filetype,
    }, url = '/user/documentbkp/download-pdf';
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        if (filetype === 'filepdf') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
            });
        } else if (filetype === 'word') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating Word file', alignment: "bottom" }
            });
        }

        let response = fetchData({ url: url, method: 'POST', data: formData });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: false, text: '', alignment: "centre" }
            });
            if (res.status === 1) {
                if (filetype === 'filepdf') {
                    let oReq = new XMLHttpRequest();
                    let URLToPDF = res.response;
                    let url = window.location.origin + new URL(URLToPDF).pathname;
                    let filename = res.filename && checkValue(res.filename) ? res.filename : (item.name && item.name !== '') ? item.name : item.filename;
                    oReq.open("GET", url, true);
                    oReq.setRequestHeader('Access-Control-Allow-Origin', '*');
                    oReq.responseType = "blob";
                    oReq.onload = function () {
                        var file = new Blob([oReq.response], {
                            type: 'application/pdf'
                        });
                        saveAs(file, filename + ".pdf");
                    };
                    oReq.send();
                } else if (filetype === 'pdf') {
                    let oReq = new XMLHttpRequest();
                    let URLToPDF = res.response.content;
                    let url = window.location.origin + new URL(URLToPDF).pathname;
                    // let filename = (res.response.name && res.response.name !== '') ? res.response.name : res.response.filename;                  
                    let filename = res.filename && checkValue(res.filename) ? res.filename : (item.name && item.name !== '') ? item.name : item.filename;
                    oReq.open("GET", url, true);
                    oReq.responseType = "blob";
                    oReq.onload = function () {
                        var file = new Blob([oReq.response], {
                            type: 'application/pdf'
                        });
                        saveAs(file, filename + ".pdf");
                    };
                    oReq.send();


                } else {
                    if (String(sendtype) === String('direct')) {
                        window.open(res.response, '_blank');
                    } else {
                        // const tabOpen = window.open("about:blank", "newtab");
                        // tabOpen.location = res.response;
                        window.open(res.response, 'newtab');
                    }
                }
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}
export const getViewContent = (id, content) => {
    let formData = {
        id: id ? id : '',
        content: content ? content : '',
        userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ''
    }
    return (dispatch) => {
        dispatch({ type: 'UPDATE_DOCUMENT_CONTENT', payload: { 'fileContentNew': '' } });
        let response = fetchData({ url: '/user/get-view-content', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                let content
                if (res.response.indexpageContent) {
                    content = res.response.headerContent + res.response.indexpageContent + res.response.innerContent + res.response.footerContent;
                } else {
                    content = res.response.headerContent + res.response.innerContent + res.response.footerContent;
                }
                dispatch({ type: 'UPDATE_DOCUMENT_CONTENT', payload: { 'fileContentNew': content } });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const getAdminViewContent = (id, content) => {
    let formData = {
        id: id ? id : '',
        userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ''
    }
    return (dispatch) => {
        dispatch({ type: 'UPDATE_DOCUMENT_CONTENT', payload: { 'fileContentNew': '' } });
        let response = fetchData({ url: '/user/admin-view-content', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                let content = res.response.headerContent + res.response.innerContent + res.response.footerContent;
                dispatch({ type: 'UPDATE_ADMIN_DOCUMENT_CONTENT', payload: { 'adminFileContentNew': content, 'variables': res.response.variables } });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const setCompareFile = (file) => {
    return {
        type: actionTypes.COMPARE_FILE,
        payload: file
    }
};

export const rejectDocumentAPI = (id, name) => {
    return (dispatch) => {
        let formData = {
            fileId: id,
            userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ""
        }
        let response = fetchData({ url: '/user/reject-document', method: 'POST', data: formData });
        response.then(res => {
            dispatch({ type: 'COMPARE_FILE', payload: {} });
        });
    }
};

export const downloadIndex = (id, parent, label) => {
    let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ""
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
        });
        let obj = {
            userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "",
            folderId: id && String(id) !== String(undefined) && String(id) !== String(null) ? id : '',
            parent: parent && String(parent) !== String(undefined) && String(parent) !== String(null) ? parent : '',
            label: label && String(label) !== String(undefined) && String(label) !== String(null) ? label : ''
        };
        let response = fetchData({ url: '/user/download/index', method: 'POST', data: obj });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: false, text: '', alignment: "centre" }
            });
            if (res.status === 1) {
                window.open(res.response, '_blank');
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const markAsReviewed = (formData, location) => {
    let data = {};
    data['userId'] = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ""
    data['content'] = formData && formData.content ? formData.content : '';
    data['fileId'] = formData && formData.review_data && formData.review_data.fileId && formData.review_data.fileId !== null && formData.review_data.fileId !== undefined ? formData.review_data.fileId : '';
    data['notes'] = formData && formData.review_data && formData.review_data.notes && formData.review_data.notes !== null && formData.review_data.notes !== undefined ? formData.review_data.notes : '';
    data['description'] = formData && formData.review_data && formData.review_data.description && formData.review_data.description !== undefined && formData.review_data.description !== null ? formData.review_data.description : '';
    data['review_date'] = formData && formData.review_data && formData.review_data.review_date && formData.review_data.review_date !== undefined && formData.review_data.review_date !== null ? formData.review_data.review_date : null;
    return (dispatch) => {
        let response = fetchData({ url: '/user/documents/review', method: 'POST', data: data });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (location.pathname === '/documents') {
                dispatch({ type: actionTypes.UPDATE_DOCUMENT_LIST, payload: formData.fileId });
            }
            if (res.status === 1) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
                dispatch({ type: actionTypes.REVIEW_MODAL_POPOP, payload: {} })
                dispatch({ type: actionTypes.DOCUMENT_REVIEW_DATA, payload: {}, });
                dispatch({ type: actionTypes.GET_TOUCHED_FILE, payload: {} });
                let response1 = fetchData({ url: `/user/selected/file?fileId=${data['fileId']}&userId=${data['userId']}`, method: 'GET' });
                response1.then(res => {
                    if (res.status === 1) {
                        dispatch({
                            type: actionTypes.GET_CLICKED_FILE,
                            payload: res.response && res.response.length > 0 ? res.response[0] : []
                        });
                    } else {
                        dispatch({
                            type: actionTypes.GET_CLICKED_FILE,
                            payload: {}
                        });
                    }
                })
                let response2 = fetchData({ url: `/user/document/update_notes/list?fileId=${data['fileId']}&userId=${data['userId']}`, method: 'GET' });
                response2.then(res => {
                    if (res.status === 1) {
                        dispatch({
                            type: actionTypes.DOCUMENT_UPDATE_NOTES_LIST,
                            payload: res.response && res.response.length > 0 ? res.response : []
                        });
                    } else {
                        dispatch({
                            type: actionTypes.DOCUMENT_UPDATE_NOTES_LIST,
                            payload: []
                        });
                    }
                });
                let response3 = fetchData({ url: '/user/document-recent-activities', method: 'POST', data: { 'id': data['fileId'], 'userId': data['userId'] } });
                response3.then(res => {
                    if (res.status === 1) {
                        dispatch({ type: actionTypes.DOCUMENT_RECENT_ACTIVITIES, payload: res.response && res.response.length > 0 ? res.response : [] })
                    } else if (res.status === 0) {
                        if (typeof res.response === 'object') {
                            dispatch({
                                type: actionTypes.BOTTOM_T_W_AL,
                                payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                            });
                        } else {
                            dispatch({
                                type: actionTypes.BOTTOM_T_W_AL,
                                payload: { show_hide: true, text: res.response, alignment: "centre" }
                            });
                        }
                    }
                });

            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
                dispatch({ type: actionTypes.REVIEW_MODAL_POPOP, payload: {} })
                dispatch({ type: actionTypes.DOCUMENT_REVIEW_DATA, payload: {}, })
            }
        });
    }
}

export const setEditorContent = (value) => {
    return {
        type: actionTypes.SET_EDITOR_CONTENT,
        payload: (value) ? value : null
    }
}

export const saveContent = (formData, type, key) => {
    let data = {};
    data['fileId'] = formData && formData.fileId ? formData.fileId : '';
    data['content'] = formData && formData.content ? formData.content : '';
    data['userId'] = formData && formData.userId ? formData.userId : '';
    data['type'] = type ? type : '';
    data['isSkip'] = key ? key : '';
    data['editorReferenceLink'] = sessionStorage.referenceDocIds ? sessionStorage.referenceDocIds : '';
    data['version'] = formData && formData.review_data.version && formData.review_data.version ? formData.review_data.version : '';
    if (formData && formData.review_data && formData.review_data.notes && formData.review_data.notes !== null && formData.review_data.notes !== undefined) data['notes'] = formData.review_data.notes;
    if (formData && formData.review_data && formData.review_data.description) (data['description'] = formData.review_data.description);
    if (formData && formData.review_data && formData.review_data.review_date) (data['review_date'] = formData.review_data.review_date);
    if (formData && formData.review_data && formData.review_data.doc_review) (data['doc_review'] = formData.review_data.doc_review);
    if (formData && formData.review_data && formData.review_data.needIndexForDocs) (data['needIndexForDocs'] = formData.review_data.needIndexForDocs);
    data['closeButton'] = formData && formData.closebutton ? formData.closebutton : '';
    return (dispatch) => {
        let response = fetchData({ url: '/user/savecontent', method: 'POST', data: data });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                if (sessionStorage.getItem('selectedFile')) {
                    let file = JSON.parse(sessionStorage.getItem('selectedFile'));
                    file.content = formData.content;
                    sessionStorage.setItem('selectedFile', JSON.stringify(file));
                    dispatch(getBackUps())
                    dispatch({ type: actionTypes.PREVIOUS_FILE_CONTENT, payload: '', })
                    // dispatch({ type: actionTypes.BRACKET_MODAL_POPOP, payload: '', })
                }
                dispatch({ type: 'UPDATE_DOCUMENT_CONTENT', payload: { 'fileContentNew': formData.content } });
                if (type && type !== 'close') {
                    dispatch(getAllRefDocs())
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Document has been saved successfully', alignment: "centre" }
                    });
                    dispatch({
                        type: actionTypes.SET_EDITOR_CONTENT,
                        payload: (formData.content) ? formData.content : null
                    })
                    dispatch({ type: actionTypes.REVIEW_MODAL_POPOP, payload: {} });
                    let response1 = fetchData({ url: `/user/selected/file?fileId=${data['fileId']}&userId=${data['userId']}`, method: 'GET' });
                    response1.then(res => {
                        if (res.status === 1) {
                            dispatch({
                                type: actionTypes.GET_CLICKED_FILE,
                                payload: res.response && res.response.length > 0 ? res.response[0] : []
                            });
                        } else {
                            dispatch({
                                type: actionTypes.GET_CLICKED_FILE,
                                payload: {}
                            });
                        }
                    })
                    let response2 = fetchData({ url: `/user/document/update_notes/list?fileId=${data['fileId']}&userId=${data['userId']}`, method: 'GET' });
                    response2.then(res => {
                        if (res.status === 1) {
                            dispatch({
                                type: actionTypes.DOCUMENT_UPDATE_NOTES_LIST,
                                payload: res.response && res.response.length > 0 ? res.response : []
                            });
                        } else {
                            dispatch({
                                type: actionTypes.DOCUMENT_UPDATE_NOTES_LIST,
                                payload: []
                            });
                        }
                    });
                    let response3 = fetchData({ url: '/user/document-recent-activities', method: 'POST', data: { 'id': data['fileId'], 'userId': data['userId'] } });
                    response3.then(res => {
                        if (res.status === 1) {
                            dispatch({ type: actionTypes.DOCUMENT_RECENT_ACTIVITIES, payload: res.response && res.response.length > 0 ? res.response : [] })
                        } else if (res.status === 0) {
                            if (typeof res.response === 'object') {
                                dispatch({
                                    type: actionTypes.BOTTOM_T_W_AL,
                                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                                });
                            } else {
                                dispatch({
                                    type: actionTypes.BOTTOM_T_W_AL,
                                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                                });
                            }
                        }
                    });
                }
                dispatch({ type: actionTypes.DOCUMENT_REVIEW_DATA, payload: {}, });

            } else if (res.status === 0) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
                dispatch({ type: actionTypes.REVIEW_MODAL_POPOP, payload: {} });
                dispatch({ type: actionTypes.DOCUMENT_REVIEW_DATA, payload: {}, })
            }
        });
    }
}

export const restoreFile = (file) => {
    let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
    return (dispatch) => {
        let response = fetchData({ url: '/user/restore-file', method: 'POST', data: { file, userId } });
        dispatch({ type: actionTypes.SET_EDITOR_CONTENT, payload: '' });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: actionTypes.SET_EDITOR_CONTENT, payload: res.content ? res.content : '' });
                dispatch({ type: actionTypes.UPDATE_RESTORE_CONTENT, payload: res.content, fileId: JSON.parse(file)._id });
                dispatch({ type: actionTypes.UPDATE_DOCUMENT_CONTENT, payload: { 'fileContentNew': res.content } });
                dispatch({ type: actionTypes.RESTORE_FILE_MODAL_VIEW, payload: {} });
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}
export const bulkrestoreFile = (file) => {
    let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
    return (dispatch) => {
        let response = fetchData({ url: '/user/bulkrestore-file', method: 'POST', data: { file, userId } });
        dispatch({ type: actionTypes.SET_EDITOR_CONTENT, payload: '' });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: actionTypes.GET_CLICKED_MULTIPLE_FILE, payload: [] })
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const documentInfoSidebar = (value) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.DOCUMENT_INFO_SIDEBAR, payload: value ? value : false })
    }
}

export const documentRecentActivities = (file) => {
    let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
    return (dispatch) => {
        let response = fetchData({ url: '/user/document-recent-activities', method: 'POST', data: { 'id': file.uId, userId } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.DOCUMENT_RECENT_ACTIVITIES, payload: res.response && res.response.length > 0 ? res.response : [] })
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const restoreDocumentToCompany = () => {
    let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        let response = fetchData({ url: '/user/restore-documents', method: 'POST', data: { userId } });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({ type: actionTypes.RESTORE_MODAL_VIEW, payload: {} });
            if (res.status === 1) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const filterVariables = (value, variables) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.FILTER_DOCUMENT_VARIABLES, payload: { variables, 'value': value ? value : '' } });
    }
}

export const setClientVariableLists = (variables) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLIENT_VARIABLES_LIST, payload: variables });
    }
}

export const SET_DOC_NAME_VAL = (value) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_DOC_DBL_VALUE, payload: value ? value : '' });
    }
}

export const SET_DBL_CLK_FALSE = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.ST_DBL_CLK_FALSE, payload: false });
    }
}

export const set_document_name = (value) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SHOW_DBL_CLICK, payload: { documentName: value ? value : '', show_dbl_click: true } });
    }
}

export const show_mod_pop_btm = (value) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: value, alignment: "centre" }
        });
    }
};

export const show_pdf_pop_btn = (show_hide, value) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: show_hide, text: value, alignment: "bottom" }
        });
    }
};

export const ClearPdForm = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_PDF_FORM_DATA,
            payload: {}
        });
    }
}

export const clearEditorFile = () => {
    return {
        type: actionTypes.SELECTED_FILE,
        payload: {}
    }
}

export const SAVED_SET_NAME_AND_CLEAR = val => {
    return {
        type: actionTypes.POP_FOR_SAVED_DOCS_NAME,
        payload: val && String(val).length > 0 && String(val) !== String(undefined) && String(val) !== String(null) ? val : ''
    }
};

export const SHOW_CLOSE_POP_FOR_SAVED_DOCS = val => {
    return {
        type: actionTypes.SHOW_POP_FR_SAVED_DOCS,
        payload: val && String(val) === String(true) ? true : false
    }
};

export const Clear_Sved_Docs = () => {
    return {
        type: actionTypes.CLEAR_SAVED_DOCS,
        payload: []
    }
};

export const Search_in_saved_docs = val => {
    return {
        type: actionTypes.SEARCH_VAL_SAVED_DOCS,
        payload: val && String(val).length > 0 && String(val) !== String(undefined) && String(val) !== String(null) ? val : ''
    }
}

export const Get_saved_Docs_list = (skip, limit, pageNumber, search) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        dispatch({ type: actionTypes.SET_ATTR_FOR_SAVED_DOCS, page_no: pageNumber, serachVal: (search && search !== '') ? search : "", skip: skip, itemPerPage: Number(limit) });
        let response = fetchData({ url: '/site/saved_docs/list', method: 'POST', data: { 'limit': String(limit), 'skip': String(skip), 'search': search, 'userId': sessionStorage.getItem('userId') } });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res && res && +res.status === 1) {
                dispatch({ type: actionTypes.LIST_SAVED_DOCS, payload: [...res.response], count: res.total ? res.total : 0 });
            } else {
                dispatch({ type: actionTypes.LIST_SAVED_DOCS, payload: [], count: 0 });
            }
        }).catch(err => {
            dispatch({ type: actionTypes.LIST_SAVED_DOCS, payload: [], count: 0 });
        })
    }
};

export const Edited_view_saved_data = file => {
    return {
        type: actionTypes.EDIT_VIEW_SAVED_DOCS,
        payload: file && file._id ? file : {}
    }
};

export const Get_content_by_id = file => {
    if (file && file._id) {
        return (dispatch) => {
            dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
            dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: '' });
            let response = fetchData({ url: '/site/saved_docs/get_file_for_editor', method: 'POST', data: { _id: file._id, 'userId': file.userId } });
            response.then(res => {
                dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
                if (res && res && +res.status === 1) {
                    let fileContent = res.response;
                    dispatch({ type: actionTypes.SAVED_DOCS_SELECTED_FILE, payload: file });
                    dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: (fileContent && fileContent.content) ? fileContent.content : '' });
                    file.variables = fileContent && fileContent.variables && fileContent.variables.length > 0 ? fileContent.variables : [];
                    sessionStorage.setItem('selected_saved_File', JSON.stringify(file));
                }
            }).catch(err => {
                dispatch({ type: actionTypes.SAVED_DOCS_SELECTED_FILE, payload: file });
                dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: '' });
            })
        }
    } else {
        return (dispatch) => {
            dispatch({ type: actionTypes.SAVED_DOCS_SELECTED_FILE, payload: file });
            dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: '' });
        }
    }
};

export const DOWNLOAD_OPEN_PDF_WORD_SAVED_DOC = (item, filetype) => {
    let formData = {
        _id: item._id ? item._id : '',
        content: item.content ? item.content : '',
        userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '',
    }, url = '/site/saved_docs/download_as_pdf';
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        if (String(filetype) === 'open' || String(filetype) === 'filepdf') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
            });
        }
        if (String(filetype) === 'word') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating Word file', alignment: "bottom" }
            });
            url = '/site/saved_docs/download_as_word';
        }
        let response = fetchData({ url: url, method: 'POST', data: formData });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: false, text: '', alignment: "centre" }
            });
            if (res.status === 1) {
                if (String(filetype) === 'filepdf') {
                    var oReq = new XMLHttpRequest();
                    var URLToPDF = res.response;
                    let url = window.location.origin + new URL(URLToPDF).pathname;
                    var filename = (item.name && item.name !== '') ? item.name : item.filename;
                    oReq.open("GET", url, true);
                    oReq.responseType = "blob";
                    oReq.onload = function () {
                        var file = new Blob([oReq.response], {
                            type: 'application/pdf'
                        });
                        saveAs(file, filename + ".pdf");
                    };
                    oReq.send();
                } else {
                    const tabOpen = window.open("about:blank", "newtab");
                    tabOpen.location = res.response;
                }
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
};

export const create_saved_docs_download = (fileId, content, name, filetype) => {
    let formData = { fileId, content, userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '', }, url = '/site/users/create_save_docs/download_as_pdf';
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        if (String(filetype) === 'open' || String(filetype) === 'filepdf') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
            });
        }
        if (String(filetype) === 'word') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating Word file', alignment: "bottom" }
            });
            url = '/site/users/create_save_docs/download_as_word';
        }
        let response = fetchData({ url: url, method: 'POST', data: formData });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: false, text: '', alignment: "centre" }
            });
            if (res.status === 1) {
                if (String(filetype) === 'filepdf') {
                    var oReq = new XMLHttpRequest();
                    var URLToPDF = res.response;
                    let url = window.location.origin + new URL(URLToPDF).pathname;
                    var filename = name ? name : '';
                    oReq.open("GET", url, true);
                    oReq.responseType = "blob";
                    oReq.onload = function () {
                        var file = new Blob([oReq.response], {
                            type: 'application/pdf'
                        });
                        saveAs(file, filename + ".pdf");
                    };
                    oReq.send();
                } else {
                    const tabOpen = window.open("about:blank", "newtab");
                    tabOpen.location = res.response;
                }
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const SAVED_DOCS_DELETED = (fileId, props) => {
    return (dispatch) => {
        let response = fetchData({ url: '/site/saved_docs/del_by_id', method: 'POST', data: { _id: fileId, userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '' } });
        response.then(res => {
            if (res && +res.status === 1) {
                dispatch({ type: actionTypes.DELETE_MODAL_VIEW, payload: {} });
                dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: '' });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Document has been deleted successfully', alignment: "centre" }
                });
                sessionStorage.removeItem("userClicked_saved_FileId");
                sessionStorage.removeItem("selected_saved_File");
                if (props && props.history) {
                    props.history.push('/saved-documents')
                } else {
                    let response = fetchData({ url: '/site/saved_docs/list', method: 'POST', data: { 'limit': '10', 'skip': '0', 'search': '', 'userId': sessionStorage.getItem('userId') } });
                    dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
                    response.then(res => {
                        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
                        if (res && +res.status === 1) {
                            dispatch({ type: actionTypes.LIST_SAVED_DOCS, payload: [...res.response], count: res.total ? res.total : 0 });
                        } else {
                            dispatch({ type: actionTypes.LIST_SAVED_DOCS, payload: [], count: 0 });
                        }
                    });
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
};

export const GET_EDITED_DATA_FOR_SAVED_DOCS = file => {
    if (file && file._id) {
        return (dispatch) => {
            let response = fetchData({ url: '/site/saved_docs/get_by_id', method: 'POST', data: { _id: file._id, userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '' } });
            response.then(res => {
                if (res && res.status === 1) {
                    dispatch({
                        type: actionTypes.SET_DOCS_EDITED_DATA_BY_ID,
                        payloa: res.response
                    })
                } else {
                    dispatch({
                        type: actionTypes.SET_DOCS_EDITED_DATA_BY_ID,
                        payload: {}
                    })
                }
            })
        }
    } else {
        return {
            type: actionTypes.GET_ORIGINAL_FILE_DATA,
            payload: {}
        }
    }
};

export const Clear_saved_docs_content = file => {
    return {
        type: actionTypes.CLEAR_SAVED_DOCS_ADD_DATA,
        payload: file && file._id ? file : {}
    }
};

export const clear_saved_docs_data = val => {
    return {
        type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT,
        payload: val && String(val).length > 0 && String(val) !== String(null) && String(val) !== String(undefined) ? val : ''
    }
};

export const Get_clicked_file_content_by_id = fileId => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/downloadfile', method: 'POST', data: { fileId, 'userId': sessionStorage.getItem('userId') } });
        response.then(res => {
            if (res && +res.status === 1) {
                let fileContent = res.response;
                dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: (fileContent && fileContent.content) ? fileContent.content : '' });
            } else {
                dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: '' });
            }
        })
    };
};

export const get_ORiginal_files_data = fileId => {
    return (dispatch) => {
        let response = fetchData({ url: '/site/userfiles/get/by_ID', method: 'POST', data: { uId: fileId, 'userId': sessionStorage.getItem('userId') } });
        response.then(res => {
            if (res && +res.status === 1) {
                dispatch({ type: actionTypes.GET_ORIGINAL_FILE_DATA, payload: res.response });
            } else {
                dispatch({ type: actionTypes.GET_ORIGINAL_FILE_DATA, payload: {} });
            }
        })
    };
};

export const SET_saved_docs_content = content => {
    return {
        type: actionTypes.SET_EDITOR_CONTENT_FOR_SAVDD_DOCS,
        payload: content && String(content).length > 0 && String(content) !== String(null) && String(content) !== String(undefined) ? content : ''
    }
};

export const SAVE_NEW_DOC = (name, file_id, content, props) => {
    return (dispatch) => {
        let response = fetchData({ url: '/site/insert/saved_docs', method: 'POST', data: { name, file_id, content, userId: sessionStorage.getItem('userId') } });
        response.then(res => {
            if (res && +res.status === 1) {
                sessionStorage.removeItem('saved_new_docs_file_id');
                dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: '' });
                dispatch({ type: actionTypes.POP_FOR_SAVED_DOCS_NAME, payload: '' });
                dispatch({ type: actionTypes.SHOW_POP_FR_SAVED_DOCS, payload: false });
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
                props.history.push('/saved-documents')
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        })
    }
};

export const Update_COntent_in_saved_docs = (content, file_id, props) => {
    return (dispatch) => {
        let response = fetchData({ url: '/site/saved_docs/content/change', method: 'POST', data: { _id: file_id, content, userId: sessionStorage.getItem('userId') } });
        response.then(res => {
            if (res && +res.status === 1) {
                sessionStorage.removeItem('userClicked_saved_FileId');
                sessionStorage.removeItem('selected_saved_File');
                dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: '' });
                dispatch({ type: actionTypes.CLEAR_SAVED_DOCS_ADD_DATA, payload: {} });
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
                props.history.push('/saved-documents')
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        })
    }
};

export const SHOW_CLOSE_POP_FOR_EDITEDSAVED_DOCS = val => {
    return {
        type: actionTypes.EDIT_SAVED_DOCS_NAME_POP,
        payload: val && String(val) === String(true) ? true : false
    }
};

export const GET_EDITED_DOCS_RENAME_DATA = _id => {
    return (dispatch) => {
        let response = fetchData({ url: '/site/saved_docs/get_by_id', method: 'POST', data: { _id, userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '' } });
        response.then(res => {
            if (res && +res.status === 1) {
                dispatch({
                    type: actionTypes.POP_FOR_SAVED_DOCS_NAME,
                    payload: res.response && res.response.name ? res.response.name : ''
                })
            } else {
                dispatch({
                    type: actionTypes.POP_FOR_SAVED_DOCS_NAME,
                    payload: ''
                })
            }
        }).catch(err => {
            dispatch({
                type: actionTypes.POP_FOR_SAVED_DOCS_NAME,
                payload: ''
            })
        })
    }
};

export const Name_change_update = (name, _id, props) => {
    return (dispatch) => {
        let response = fetchData({ url: '/site/saved_docs/name/change', method: 'POST', data: { name, _id, userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '' } });
        response.then(res => {
            if (res && +res.status === 1) {
                sessionStorage.removeItem('userClicked_saved_FileId');
                sessionStorage.removeItem('selected_saved_File');
                dispatch({ type: actionTypes.EDIT_SAVED_DOCS_NAME_POP, payload: false });
                dispatch({ type: actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT, payload: '' });
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
                let response = fetchData({ url: '/site/saved_docs/list', method: 'POST', data: { 'limit': '10', 'skip': '0', 'search': '', 'userId': sessionStorage.getItem('userId') } });
                dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
                response.then(res => {
                    dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
                    if (res && +res.status === 1) {
                        dispatch({ type: actionTypes.LIST_SAVED_DOCS, payload: [...res.response], count: res.total ? res.total : 0 });
                    } else {
                        dispatch({ type: actionTypes.LIST_SAVED_DOCS, payload: [], count: 0 });
                    }
                });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        })
    }
};

export const SetDocument_set_up_pop_up = (value = {}) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ADD_FILE_NAME,
            payload: value
        });
    }
};

export const updateRenewalModal = () => {
    return {
        type: actionTypes.SHOW_UPDATE_RENEWAL_MODAL,
    }
}
export const getSampleFiles = () => {
    return dispatch => {
        let response = fetchData({ url: '/sample/filesList', method: 'GET' });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.SAMPLE_FILES_LIST,
                    payload: res.response
                });
            } else {
                dispatch({
                    type: actionTypes.SAMPLE_FILES_LIST,
                    payload: []
                });
            }

        }).catch(error => console.log(error))
    }
}

export const docAccessModalToggle = () => {
    return (dispatch) => dispatch({
        type: actionTypes.DOCUMENT_ACCESS_MODAL
    })
}

export const reviewModalToggle = (item, type) => {
    return {
        type: actionTypes.REVIEW_MODAL_POPOP,
        payload: item ? item : {},
        method: type ? type : ''
    }
}

export const document_reviewData = (value) => {
    return {
        type: actionTypes.DOCUMENT_REVIEW_DATA,
        payload: value ? value : {},

    }
}

export const getDocumentUpdateNotesList = (fileId) => async (dispatch) => {
    let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '';
    if (userId && checkValue(userId) && fileId && checkValue(fileId)) {
        let result = await fetchData({ url: `/user/document/update_notes/list?fileId=${fileId}&userId=${userId}`, method: 'GET' });
        if (result && result.status === 1) {
            dispatch({
                type: actionTypes.DOCUMENT_UPDATE_NOTES_LIST,
                payload: result.response && result.response.length > 0 ? result.response : []
            });
        } else {
            dispatch({
                type: actionTypes.DOCUMENT_UPDATE_NOTES_LIST,
                payload: []
            });
        }
    }

}

export const getClickedFile = (fileId) => {
    let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '';
    return (dispatch) => {
        if (fileId && checkValue(fileId)) {
            let response = fetchData({ url: `/user/selected/file?fileId=${fileId}&userId=${userId}`, methos: 'GET' });
            response.then(res => {
                if (res.status === 1) {
                    dispatch({
                        type: actionTypes.GET_CLICKED_FILE,
                        payload: res.response && res.response.length > 0 ? res.response[0] : []
                    });
                } else {
                    dispatch({
                        type: actionTypes.GET_CLICKED_FILE,
                        payload: {}
                    });
                }
            })
        } else {
            dispatch({
                type: actionTypes.GET_CLICKED_FILE,
                payload: {}
            });
        }
    }
}
export const guideModalToggle = () => {
    return {
        type: actionTypes.GUIDE_MODAL_POPOP
    }
}
export const getGuideData = () => {
    // sessionStorage.setItem('userClickedFileUId', file.uId);

    const file = sessionStorage.getItem('userClickedFileUId');
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/getGuide', method: "post", data: { fileId: file } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.GET_GUIDE_DATA, payload: res.response })
        }
    }
}

export const open_report_modal = (value) => {
    return dispatch => dispatch({ type: actionTypes.REPORT_MODAL_POPUP, payload: value })
}
export const document_report_data = (value) => {
    return { type: actionTypes.DOCUMENT_REPORT_DATA, payload: value ? value : {}, }
}

export const report_issue = (data) => {
    let userId = sessionStorage.getItem("userId")
    let fileId = sessionStorage.getItem("userClickedFileId")
    const formatData = { userId, fileId, issue: data.issue, comments: data.comments }
    return (dispatch) => {
        let response = fetchData({ url: '/user/report/issue', method: "post", data: formatData });
        response.then((res) => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.REPORT_MODAL_POPUP, payload: false })
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.result, alignment: "centre" } });
            }
            else {
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: "Somrthing Went Wrong", alignment: "centre" } });
            }
        }).catch(() => {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: "Somrthing Went Wrong", alignment: "centre" } });
        })
    }
}
export const editFormat = (file, format) => {
    let formData = {
        "fileId": file.uId,
        "orientationType": format,
        "userId": file && file.userId ? file.userId : sessionStorage.getItem('userId'),
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/file/edit/orientation', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
            } if (res.status === 2) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
};
export const skipDiscription = (key = '') => {
    return (dispatch) => {
        if (key === "discription") {
            dispatch({ type: actionTypes.SKIP_DISCRIPTION })
        }
        else if (key === 'notes') {
            dispatch({ type: actionTypes.SKIP_NOTES })
        } else {
            dispatch({ type: actionTypes.SKIP_DISCRIPTION, payload: true });
            dispatch({ type: actionTypes.SKIP_NOTES, payload: true });
        }
    }
}
export const addCopyCount = (data) => {
    return (dispatch) => {
        const res = fetchData({ url: '/user/share-like/copy', method: 'POST', data: data });
    }
}

export const changeOpenedStatus = (fileId, userId) => {
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/opened/status', method: 'POST', data: { fileId, userId } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.GUIDE_MODAL_POPOP })
        }
    }
}
export const getPolicyAddConfirmation = (fileId, userId,category) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.ADD_POLICY_CONFIRMATION_MODAL,
            payload: { userId: userId ? userId : '', fileId: fileId ? fileId : '',category }
        })
    }
}
export const getPolicyRemove = (fileId, uId,category) => {
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/employee/policy/delete/documents', method: 'POST', data: { fileId, uId } })
        if(res && res.status === 1){
            let FolderId = sessionStorage.getItem('clickedFolderId')
            let FolderUid = sessionStorage.getItem('clickedFolderUid')
            if(FolderId && FolderId != undefined && FolderId != ''&& FolderUid && FolderUid != undefined && FolderUid != ''){
                dispatch(listDocumentById(FolderId,'','',FolderUid))
            }
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const getAllRefDocs = () => async (dispatch) => {
    const userId = sessionStorage.getItem('userId'),
        fileId = sessionStorage.getItem('userClickedFileId');
    if (fileId && checkValue(fileId)) {
        const res = await fetchData({ url: '/user/get/ref/documents', method: 'GET', params: { userId, fileId } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.REFERENCE_DOCUMENTS, payload: res.response, sidebarIds: res.sidebarReferenceIds })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const viewRefDoc = (id, history) => {
    const userId = sessionStorage.getItem('userId')
    return async (dispatch) => {
        dispatch({ type: actionTypes.CONFORMATION_MODAL, payload: false })
        const res = await fetchData({ url: '/user/reference/view/document', method: 'GET', params: { userId, fileId: id } });
        if (res.status === 1) {
            dispatch({ type: actionTypes.REFERENCE_DOCUMET_VIEW, payload: res.response })
            history && history.push(`/reference/${id}`)
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const openConforModal = (key, description) => ({ type: actionTypes.CONFORMATION_MODAL, payload: key, description: description })

export const detailsModal = () => ({ type: actionTypes.DETAILS_MODAL })

export const referenceDocModal = (editor) => ({ type: actionTypes.REFERENCE_DOCUMENT_MODAL, payload: editor })

export const findRefDoc = () => {
    return async (dispatch) => {
        const res = await fetchData({ url: `/user/get/ref/docs`, method: 'get', params: { userId: sessionStorage.userId } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.REFERENCE_OPTION, payload: res.response })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const selectedOption = (data) => ({ type: actionTypes.SELECTED_REF_DOC, payload: data })

export const saveReferenceDoc = (ids) => {
    const userId = sessionStorage.getItem('userId'),
        fileId = sessionStorage.getItem('userClickedFileId');
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/save/sidebar/Reference', method: 'POST', data: { userId, fileId, ids: ids } })
        if (res) {
            dispatch(getAllRefDocs())
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const ReferenceaddModal = (item) => ({ type: actionTypes.REFERENCE_DOCUMENT_MODAL, editOption: item })

export const editReference = (oldRefId, newRefId) => {
    const fileId = sessionStorage.getItem('userClickedFileId'),
        userId = sessionStorage.userId ? sessionStorage.userId : '';
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/edit/Reference', method: 'POST', data: { userId, fileId, oldRefId, newRefId } })
        if (res.status === 1) {
            dispatch(selectedFile({ _id: fileId, userId }))
            dispatch(getAllRefDocs())
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const DeleteRefModal = (id) => ({ type: actionTypes.REF_DELETE_MODAL, payload: id })

export const deleteRef = (refId) => {
    const fileId = sessionStorage.getItem('userClickedFileId'),
        userId = sessionStorage.userId ? sessionStorage.userId : '';
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/delete/Reference', method: 'POST', data: { userId, fileId, refId } })
        if (res.status === 1) {
            dispatch(selectedFile({ _id: fileId, userId }))
            dispatch(getAllRefDocs())
            dispatch(DeleteRefModal())
            const refdocArr = sessionStorage.referenceDocIds ? JSON.parse(sessionStorage.referenceDocIds).filter((i) => i !== refId) : [];
            sessionStorage.setItem('referenceDocIds', JSON.stringify(refdocArr))
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const backupModalFunc = () => ({ type: actionTypes.BACKUP_MODAL })
export const getBackUps = () => {
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/backup/files', method: 'GET', params: { userId: sessionStorage.userId, fileId: sessionStorage.userClickedFileId } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.BACKUP_FILE_DATA, payload: res.response })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}
export const restoreBackupFile = (backupId) => {
    const fileId = sessionStorage.getItem('userClickedFileId'),
        userId = sessionStorage.userId ? sessionStorage.userId : '';
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/restore/backupFile', method: 'POST', data: { backupId } })
        if (res.status === 1) {
            dispatch(selectedFile({ _id: fileId, userId }))
            dispatch(getAllRefDocs())
            dispatch(backupModalFunc())
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}
export const deleteBackupFile = (backupId) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        const res1 = await fetchData({ url: '/user/delete/backupFile', method: 'POST', data: { backupId } })
        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
        if (res1.status === 1) {
            dispatch({
                type: actionTypes.DELETE_MODAL_VIEW,
                payload: {}
            });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'SuccessFully Deleted.', alignment: "centre" }
            });
            dispatch(getBackUps())
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Not Deleted.', alignment: "centre" }
            });
        }
    }
}

export const viewBackupFile = (content) => ({ type: actionTypes.VIEW_BACKUP_MODAL, payload: content ? content : '' });

export const showDocModal = (url) => ({ type: actionTypes.VIEW_DOCX_MODAL, payload: url });
export const togglePageView = (data) => ({ type: actionTypes.TOGGLE_PAGE_VIEW, payload: data });

export const setFolderIconColor = (colour, id) => async (dispatch) => {
    let userId = sessionStorage.getItem('userId');
    let result = await fetchData({ url: '/user/foldericon/colour/update', method: 'POST', data: { colour, id, userId } });
    if (result && result.status === 1) {
        dispatch({
            type: actionTypes.UPDATE_FOLDER_ICON_COLOR,
            payload: { colour, id }
        });
        dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: result.response, alignment: "centre" } })
    } else if (result && result.status === 0) {
        dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: result.response, alignment: "centre" } })
    } else {
        dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'Somthing went wrong', alignment: "centre" } })
    }
}

export const getMultipleClickedFile = (fileId) => {
    return {
        type: actionTypes.GET_CLICKED_MULTIPLE_FILE,
        payload: fileId
    }
}

export const bulkReviewModalToggle = (item, type) => {
    return {
        type: actionTypes.BULK_REVIEW_MODAL_POPOP,
        payload: item ? item : [],
        method: type ? type : ''
    }
}

export const bulkMarkAsReviewed = (formData, location) => {
    let data = {};
    data['userId'] = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ""
    data['fileId'] = JSON.stringify(formData && formData.review_data && formData.review_data.fileId && formData.review_data.fileId !== null && formData.review_data.fileId !== undefined ? formData.review_data.fileId : '');
    data['review_date'] = formData && formData.review_data && formData.review_data.review_date && formData.review_data.review_date !== undefined && formData.review_data.review_date !== null ? formData.review_data.review_date : null;
    return (dispatch) => {
        let response = fetchData({ url: '/user/documents/bulkreview', method: 'POST', data: data });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: actionTypes.BULK_REVIEW_MODAL_POPOP, payload: {} })
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
                dispatch({ type: actionTypes.UPDATE_MULTIPLE_DOCUMENT_LIST, payload: formData.review_data.fileId });
                dispatch({ type: actionTypes.GET_CLICKED_MULTIPLE_FILE, payload: [] })
                dispatch({ type: actionTypes.ADD_EXPORT_ALLLIST, payload: [] })
            }
        });
    }
}

export const getDcoumentRef = () => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/get_documentref/list', method: 'POST', data: {} });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.GET_DOC_REF_LIST, payload: res.response });
            }
        });
    }
}

export const previousFileContent = (item) => {
    return {
        type: actionTypes.PREVIOUS_FILE_CONTENT,
        payload: item ? item : [],
    }
}

export const bracketModalToggle = (item, type) => {
    return {
        type: actionTypes.BRACKET_MODAL_POPOP,
        payload: { item: item ? item : {}, type: type ? type : '' }
    }
}
export const updateModalToggle = (item, type) => {
    return {
        type: actionTypes.UPDATE_MODAL_POPOP,
        payload: { item: item ? item : {}, type: type ? type : '' }
    }
}
export const open_iframe_modal = (src) => {
    return {
        type: actionTypes.IFRAME_MODAL_POPOP,
        payload: { src: src ? src : '' }
    }
}
export const updateBulkFiles = (files, actiontype, content) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        let userName = sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : "";
        let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
        let response = fetchData({ url: '/company/update-bulk-files', method: 'POST', data: { files, actiontype, userName, userId, content: content && content !== undefined ? JSON.stringify(content) : '' } });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: 'POPUP_BREADCRUMBS', folderName: '', folderId: '' });
                dispatch({ type: actionTypes.UPDATE_FILES_LIST, payload: [] });
                dispatch({ type: actionTypes.GET_CLICKED_MULTIPLE_FILE, payload: [] })
                dispatch({ type: actionTypes.UPDATE_DOCUMENT_STATUS, 'status': (actiontype && actiontype === 'overwritten') ? 'Overwritten' : (actiontype && actiontype === 'published') ? 'Published' : (actiontype && actiontype === 'unpublished') ? 'Unpublished' : 'Deleted', 'payload': JSON.parse(files) });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: "Document has been  Deleted Successfully", alignment: "centre" }
                });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}
export const documentDeletedList = (skip, limit, pageNumber, collection, field = 'createdAt', order, search = '') => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/documents/deleted-list', method: 'POST', data: { 'limit': limit, 'skip': skip, 'collection_type': collection, field, order, search } });
        dispatch({ type: actionTypes.BIN_TABLE_ATTRIBUTES, page_no: pageNumber, skip, collection });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: actionTypes.BIN_DOCUMENTS_LIST, payload: res.response, count: res.total });
            } else if (res.status === 2) {
                dispatch({ type: actionTypes.CLEAR_BIN_DOCUMENTS_LIST, payload: [], count: 0 });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const permanentDeleteDocuments = (id, collection_type, props) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/documents/delete/permanently', method: 'POST', data: { delData: id, collection_type, userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "" } });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: actionTypes.PDELETE_MODAL_VIEW, payload: {} });
                dispatch({ type: actionTypes.BIN_UPDATE_DLIST, payload: (id) ? id : "" });
                if (collection_type === 'userfiles') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: "Document has been deleted successfully", alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: "Folder has been deleted", alignment: "centre" }
                    });
                }
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const restoreDocuments = (data, props) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/documents/restore', method: 'POST', data: { 'id': data.id, collection_type: data.collection, userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "", } });
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({ type: actionTypes.PRESTORE_MODAL_VIEW, payload: {} });
                dispatch({ type: actionTypes.BIN_UPDATE_DLIST, payload: (data.id) ? data.id : "" });
                if (data.collection === 'userfiles') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: "Document has been restored successfully", alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: "Folder has been restored", alignment: "centre" }
                    });
                }
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const show_mod_pop_btm_for_pub_ow = (value, type) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: value, alignment: type }
        });
    }
};

export const getTouchedFile = (userId, fileId) => {
    const obj = {}
    obj['userId'] = userId ? userId : '';
    obj['fileId'] = fileId ? fileId : '';
    return async (dispatch) => {
        const res = await fetchData({ url: `/user/get/touched/userfile`, method: 'POST', data: obj })
        if (res.status === 1) {
            dispatch({ type: actionTypes.GET_TOUCHED_FILE, payload: res.response });
        }
    }
}

//Q&A
export const open_Question_modal = (value) => {
    return dispatch => dispatch({ type: actionTypes.QUESTION_MODAL_POPUP, payload: value })
};
export const open_Add_Question_model = (value) => {
    return async (dispatch) => {
    dispatch({ type: actionTypes.EDIT_QUESTION, payload: {} })
    dispatch({ type: actionTypes.ADD_QUESTION_MODAL_POPUP, payload: value })
}
}
export const addeditQuestion = (question, choice, type, id) => {
    return async (dispatch) => {
        const res = await fetchData({ url: `/user/selected/file/question`, method: 'POST', data: { userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null, fileId: sessionStorage.getItem('userClickedFileId') ? sessionStorage.getItem('userClickedFileId') : null, question, choice, type, id } })
        if (res.status === 1) {
            if (type === 'addquestion' || type === 'editquestion' || type === 'editquestiondraft' || type === 'addquestiondraft') {
                dispatch(getQuestion())
                dispatch({ type: actionTypes.ADD_QUESTION_MODAL_POPUP, payload: false })
                dispatch({ type: actionTypes.QUESTION_MODAL_POPUP, payload: true })
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Question has been Added', alignment: "centre" }
                });
            } else if (type === 'edit') {
                dispatch({ type: actionTypes.EDIT_QUESTION, payload: res.data })
                dispatch({ type: actionTypes.ADD_QUESTION_MODAL_POPUP, payload: true })
            } else if (type === 'delete') {
                dispatch(getQuestion())
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Question Deleted Successfully', alignment: "centre" }
                });
            }
        }
    }
}
export const getQuestion = () => {
    return async (dispatch) => {
        const res = await fetchData({ url: `/user/selected/file/getquestion`, method: 'POST', data: { userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null, fileId: sessionStorage.getItem('userClickedFileId') ? sessionStorage.getItem('userClickedFileId') : null } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.GET_QUESTION_DATA, payload: res.data })
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Question is Empty', alignment: "centre" }
            });
        }
    }
}

export const getTestreq = () => {
    return async (dispatch) => {
        const res = await fetchData({ url: `/user/selected/file/gettestrequired`, method: 'POST', data: { userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null, fileId: sessionStorage.getItem('userClickedFileId') ? sessionStorage.getItem('userClickedFileId') : null } })
        if (res.status === 1) {
            dispatch({ type: actionTypes.GET_TEST_REQ_DATA, payload: res.data })
        } 
    }
}
export const testRequired = (value) => {
    return async (dispatch) => {
        const res = await fetchData({ url: `/user/selected/file/setquestion`, method: 'POST', data: { userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null, fileId: sessionStorage.getItem('userClickedFileId') ? sessionStorage.getItem('userClickedFileId') : null,testRequired:value } })
        if (res.status === 1) {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: res.response, alignment: "centre" }
            });
            return 1
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: res.response, alignment: "centre" }
            });
            return 0
        }
    }
}
export const checkEmpPolicy = (fileId) => {
    return async (dispatch) => {
        const res = await fetchData({ url: `/user/selectedfile/check/employeepolicy`, method: 'POST', data: { userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null, fileId: fileId} })
        if (res.status === 1) {
            dispatch({ type: actionTypes.GET_EMP_POLICY_DATA, payload: res.response })
        } else {
            dispatch({ type: actionTypes.GET_EMP_POLICY_DATA, payload: 0 })
        }
    }
}
