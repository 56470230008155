import * as actionTypes from '../actions/types.js';

export default (state = {
    recentUpdates: [], recentFolders: [], rucount: 0, ruactivePage: 1, ruitemPerPage: 10, latestviewdocs: [], uptodatedocs: [],
    needreviewdocs: [], totalCount: 0, activepage: 1, itemperpage: 25, myfilesRecent: [],recentUpdatesnew:[],ruactivePagenew:1,rucountnew: 0
}, action) => {
    switch (action.type) {

        case actionTypes.GET_DASHBOARD:
            return {
                ...state,
                dashboardCount: action.payload,
            };
        case actionTypes.GET_RECENT_UPDATES:
            return {
                ...state,
                recentUpdates: [...action.payload],
                rucount: action.count
            };
        case actionTypes.GET_RECENT_FOLDERS:
            return {
                ...state,
                recentFolders: [...action.payload],
            };
        case actionTypes.RECENTUPDATES_TABLE_ATTRIBUTES:
            return {
                ...state,
                ruactivePage: action.page_no,
            };
        case actionTypes.LATES_VIEWED_DOCUMENTS:
            return {
                ...state,
                latestviewdocs: action.payload && action.payload.documentData && action.payload.documentData.length > 0 ? action.payload.documentData : [],
            };
        case actionTypes.LATES_VIEWED_MYFILES:
            return {
                ...state,
                myfilesRecent: action.payload && action.payload.myfilesdata && action.payload.myfilesdata.length > 0 ? action.payload.myfilesdata : []
            };
        case actionTypes.UPTODATE_DOCUMENT_LIST:
            return {
                ...state,
                uptodatedocs: action.payload && action.payload.length > 0 ? action.payload : [],
                totalCount: action.total ? action.total : 0
            };
        case actionTypes.NEED_REVIEW_DOCUMENT_LIST:
            return {
                ...state,
                needreviewdocs: action.payload && action.payload.length > 0 ? action.payload : [],
                totalCount: action.total ? action.total : 0
            };
        case actionTypes.ACTIVE_PAGE:
            return {
                ...state,
                activepage: action.page_no,
            };
        case actionTypes.GET_RECENT_UPDATES_NEW:
            return {
                ...state,
                recentUpdatesnew: [...action.payload],
                rucountnew: action.count
            };
        case actionTypes.RECENTUPDATES_TABLE_ATTRIBUTES_NEW:
            return {
                ...state,
                ruactivePagenew: action.page_no,
            };    
        default:
            return state;
    }
};
