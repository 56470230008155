import * as actionTypes from '../actions/types.js';

export default (state = { productDetails: {}, randomDocuments: [], documentLists: [], count: 0, activePage: 1, itemPerPage: 10,}, action) => {

    switch (action.type) {

        case actionTypes.PRODUCT_DETAILS_VIEW:
            return {
                ...state,
                productDetails: {...action.payload}
            };
        case actionTypes.RANDOM_DOCUMENTS:
        	return {
        		...state,
        		randomDocuments: [...action.payload]
        	}
        case actionTypes.PACKAGE_DOCUMENT_LISTS:
            return {
                ...state,
                documentLists: [...action.payload],
                count: action.count
            }
        case actionTypes.DOCUMENT_ATTRIBUTES:
            return {
                ...state,
                activePage: action.page_no,
            }
        default:
            return state;
    }
};
