import * as actionTypes from '../actions/types.js';

export default (state = { pending: true }, action) => {

    switch (action.type) {
        case actionTypes.PAGE_LOADER_PENDING:
            return {
                ...state,
                pending: false
            };

        case actionTypes.PAGE_LOADER_SUCCESS:
            return {
                ...state,
                pending: true,
            };

        case actionTypes.SEARCH_PAGE_LOADER_PENDING:
            return {
                ...state,
                search_pending: false
            };

        case actionTypes.SEARCH_PAGE_LOADER_SUCCESS:
            return {
                ...state,
                search_pending: true,
            };
        default:
		return state;
    }
};
