import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { BottomModalView, Bottom_Text_With_Alignment } from '../../../actions/uiAction';
import './index.css';
import { connect } from 'react-redux'
class BottomModal extends Component {

    close_bottom_pop = () => {
        this.props.Bottom_Text_With_Alignment(false, '', 'centre');
    }

    render() {
        if (String(this.props.text) !== String('New password updated successfully')) {
            if (String(this.props.text) !== String('Reset password link was expired')) {
                if (this.props.pop_show_hide && String(this.props.alignment) === String('centre') && String(this.props.text) !== String('Image is bigger than 300px * 300px please reupload')) {
                    setTimeout(() => {
                        this.close_bottom_pop()
                    }, 2000);
                }
            }
        }
        return (
            <div>
                {
                    String(this.props.pop_show_hide) === String(true) && String(this.props.alignment) === String('centre') ?
                        <Modal isOpen={this.props.pop_show_hide} centered={true} toggle={(e) => this.close_bottom_pop()} backdrop="static" className="bottom-model">
                            <ModalBody>
                                <p className="delete_chg">{this.props.text}</p>
                                <Button color="default" onClick={(e) => this.close_bottom_pop()}>Dismiss</Button>{' '}
                            </ModalBody>
                        </Modal> :
                        String(this.props.pop_show_hide) === String(true) && String(this.props.alignment) !== String('centre') ?
                            <Modal isOpen={this.props.pop_show_hide} centered={true} toggle={(e) => this.close_bottom_pop()} backdrop="static" className="bottom-model generate-left">
                                <ModalBody>
                                    {/* <p className="delete_chg">Generating PDF file</p><span></span> */}
                                    <p className="delete_chg">{this.props.text}</p><span></span>
                                </ModalBody>
                            </Modal>
                            : null
                }
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        showbottomModal: state.UIReducer.showbottomModal,
        pop_show_hide: state.UIReducer.pop_show_hide,
        text: state.UIReducer.text,
        alignment: state.UIReducer.alignment
    }
}

const mapDispatchToProps = {
    BottomModalView, Bottom_Text_With_Alignment
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomModal)
