import React, { useEffect } from 'react';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { policyReadNotifyModal } from '../../../actions/employeePolicyAction';

const PolicyUnreadNotify = (props) => {
    let dispatch = useDispatch(),
        showUnreadPolicyNotify = useSelector(state => state.employeePolicyData.showUnreadPolicyNotify);

    return (
        <div>
            <Modal isOpen={showUnreadPolicyNotify} toggle={() => dispatch(policyReadNotifyModal())} className='policy-notify'>
                <ModalBody>You have an unread policies. You must read the Policies/documents.</ModalBody>
                <ModalFooter >
                    <div className='footer-btn'>
                        <Button className='red-border' onClick={() => dispatch(policyReadNotifyModal())}>Cancel</Button>
                        <Button className='green-border' onClick={() => props.history.push('/policy-documents')}>Go to policies</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default PolicyUnreadNotify;